import React, {
  PropsWithChildren,
  useEffect,
  useState,
  useContext,
} from "react";
import { getApiUrl } from "../api-clients";
import { Institution, InstitutionApi } from "../api-clients/api";
import { AuthContext } from "../contexts/AuthContext";
import { InstitutionContext } from "../contexts/InstitutionContext";

export const InstitutionProvider = ({
  children,
}: PropsWithChildren<unknown>) => {
  const { isAuthenticated } = useContext(AuthContext);
  const [institutionChanged, setInstitutionChanged] = useState<boolean>(false);
  const [institution, setInstitution] = useState<Institution | null>(null);
  const [institutions, setInstitutions] = useState<Institution[] | null>(null);
  const [institutionApi] = useState<InstitutionApi>(
    new InstitutionApi(getApiUrl())
  );

  useEffect(() => {
    if (institutionChanged) {
      setInstitutionChanged(false);
      setInstitution(null);
      setInstitutions(null);
    }
    if (isAuthenticated) {
      institutionApi
        .all()
        .then((res) => {
          setInstitutions(res);
          if (res != null && res.length > 0 && res[0].institutionId != null) {
            const institutionId = localStorage.getItem("institutionId");
            if (
              institutionId != null &&
              res.filter((r) => r.institutionId === institutionId).length > 0
            ) {
              setInstitution(
                res.filter((r) => r.institutionId === institutionId)[0]
              );
            } else {
              setInstitution(res[0]);
              localStorage.setItem("institutionId", res[0].institutionId);
            }
          } else {
            localStorage.removeItem("institutionId");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [institutionApi, isAuthenticated, institutionChanged]);

  const institutionUpdated = () => {
    setInstitutionChanged(true);
  };

  return (
    <InstitutionContext.Provider
      value={{
        institutions,
        institution,
        institutionUpdated,
        setInstitution,
      }}
    >
      {children}
    </InstitutionContext.Provider>
  );
};
