import React, { useState, useEffect, useContext } from "react";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TablePagination,
  Typography,
} from "@material-ui/core";
import {
  StudentApi,
  StudentFilterDto,
  StudentViewPaginatedDto,
} from "../../api-clients/api";
import { getApiUrl } from "../../api-clients";
import Loading from "../Shared/Loading";
import NoResult from "../Shared/NoResult";
import { dateToString, numberToMad } from "../../helper/index";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { SchoolContext } from "../../contexts/SchoolContext";
import { useHistory } from "react-router-dom";
import { Dropdown, IDropdownOption, SearchBox, Toggle } from "@fluentui/react";
import { red, blue } from "@material-ui/core/colors";
import ClassroomsDropDown from "../Shared/ClassroomsDropdown";
import GetAppIcon from "@material-ui/icons/GetApp";
var FileSaver = require("file-saver");

initializeIcons();

interface IProps {
  classrooms?: (string | undefined)[] | undefined;
}
export default function CardStudents({ classrooms }: IProps) {
  const { accademicYear } = useContext(SchoolContext);
  const accademicYearDate: string = `${accademicYear?.startDate?.getFullYear()}/${accademicYear?.endDate?.getFullYear()}`;

  const history = useHistory();
  const [studentApi] = useState<StudentApi>(new StudentApi(getApiUrl()));
  const [filter, setFilter] = useState<StudentFilterDto>({
    pageSize: 7,
    classrooms: classrooms,
    sortRole: 1,
    accademicYearId: accademicYear?.accademicYearId
  } as StudentFilterDto);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [result, setResult] = useState<StudentViewPaginatedDto | null>(null);

  useEffect(() => {
    setIsLoading(true);
    studentApi
      .filter(filter)
      .then((res) => {
        setResult(res);
        setIsLoading(false);
      })
      .catch((err) => setIsLoading(false));
  }, [studentApi, filter]);

  const onClassroomSelectionChange = (
    event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption
  ): void => {
    if (item)
      setFilter({
        ...filter,
        classrooms: item.selected
          ? [...(filter.classrooms ?? []), item.key as string]
          : (filter.classrooms ?? []).filter((key) => key !== item.key),
      } as StudentFilterDto);
  };

  return (
    <Card>
      <CardHeader title="Élèves" />
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}  md={12} lg={4}>
            <SearchBox
              placeholder="Nom ou prénom..."
              onClear={() =>
                setFilter({
                  ...filter,
                  filter: "",
                } as StudentFilterDto)
              }
              onSearch={(newValue: string) =>
                setFilter({
                  ...filter,
                  filter: newValue,
                } as StudentFilterDto)
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <ClassroomsDropDown
              placeholder="Classe"
              selectedKeys={filter.classrooms ?? []}
              onChange={onClassroomSelectionChange}
              disabled={classrooms != null && classrooms.length > 0}
              multiSelect
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <Toggle
              inlineLabel
              label={accademicYearDate}
              defaultChecked
              onText=""
              offText=""
              onChange={(
                ev: React.MouseEvent<HTMLElement>,
                checked?: boolean
              ) => {
                setFilter({
                  ...filter,
                  accademicYearId: checked
                    ? accademicYear?.accademicYearId
                    : null,
                } as StudentFilterDto);
              }}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Dropdown
              options={[
                {
                  key: 1,
                  text: "Trier par nom",
                },
                {
                  key: 2,
                  text: "Trier par prénom",
                },
                {
                  key: 3,
                  text: "Trier par solde",
                },
                {
                  key: 4,
                  text: "Trier par impayé",
                },
              ]}
              onChange={(
                event: React.FormEvent<HTMLDivElement>,
                option?: IDropdownOption,
                index?: number
              ) => {
                if (option) {
                  setFilter({
                    ...filter,
                    sortRole: +option.key,
                  } as StudentFilterDto);
                }
              }}
              selectedKey={filter.sortRole}
              style={{ width: "100%" }}
            />
          </Grid>
        </Grid>
        {!isLoading ? (
          result != null && result.data != null && result.data.length > 0 ? (
            <>
              <List>
                {result.data.map((s) => (
                  <div key={s.studentId}>
                    <ListItem
                      button
                      alignItems="flex-start"
                      onClick={() => history.push("/student/" + s.studentId)}
                      style={{ paddingTop: "0px", paddingBottom: "0px" }}
                    >
                      {s.firstName != null && s.lastName != null && (
                        <ListItemAvatar>
                          <Avatar>
                            {s.firstName[0]}
                            {s.lastName[0]}
                          </Avatar>
                        </ListItemAvatar>
                      )}
                      <ListItemText
                        primary={`${s.lastName} ${s.firstName}`}
                        secondary={
                          <React.Fragment>
                            <Typography
                              component="span"
                              variant="body2"
                              color="textPrimary"
                            >
                              {s.educationName}, {s.classroomAcronym}
                            </Typography>
                            {" — "}
                            {s.accademicYearStartDate?.getFullYear()}/
                            {s.accademicYearEndDate?.getFullYear()}
                            {s.studentPathStartDate &&
                              (s.accademicYearId ===
                              accademicYear?.accademicYearId
                                ? ", inscrit le "
                                : ", derniere inscription le ") +
                                dateToString(s.studentPathStartDate)}
                            <br></br>
                            {s.totalReceivedPayments -
                              (s.todayScolarshipCost + s.todayServicesCost) <
                            0 ? (
                              <Typography
                                variant="caption"
                                style={{ color: red[500] }}
                              >
                                Impayé{" "}
                                {numberToMad(
                                  s.totalReceivedPayments -
                                    (s.todayScolarshipCost +
                                      s.todayServicesCost)
                                )}
                              </Typography>
                            ) : (
                              <Typography
                                variant="caption"
                                style={{ color: blue[500] }}
                              >
                                Solde{" "}
                                {numberToMad(
                                  s.totalReceivedPayments -
                                    (s.todayScolarshipCost +
                                      s.todayServicesCost)
                                )}
                              </Typography>
                            )}
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <Divider />
                  </div>
                ))}
              </List>
              <TablePagination
                component="div"
                count={result.totalElements ?? 0}
                page={filter.pageIndex ?? 0}
                rowsPerPage={filter.pageSize ?? 0}
                rowsPerPageOptions={[7]} 
                onPageChange={(event, newPage) => {
                  setFilter({
                    ...filter,
                    pageIndex: newPage,
                  } as StudentFilterDto);
                }}
                onRowsPerPageChange={(event) => {
                  setFilter({
                    ...filter,
                    pageIndex: 0,
                    pageSize: +event.target.value,
                  } as StudentFilterDto);
                }}

              />
            </>
          ) : (
            <NoResult />
          )
        ) : (
          <Loading />
        )}
      </CardContent>
      {result != null && result.data != null && result.data.length > 0 && (
        <CardActions>
          <Button
            color="primary"
            size="small"
            startIcon={<GetAppIcon />}
            onClick={() => {
              studentApi
                .csv({
                  ...filter,
                  pageIndex: 0,
                  pageSize: 2147483640,
                } as StudentFilterDto)
                .then((res) => {
                  FileSaver.saveAs(res.data, `Élèves ${accademicYearDate}.csv`);
                });
            }}
          >
            Télécharger
          </Button>
        </CardActions>
      )}
    </Card>
  );
}
