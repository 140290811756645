import React from "react";
import { IContextualMenuProps, Stack } from "@fluentui/react";
import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { red } from "@material-ui/core/colors";

interface IProps {
  onConfirmClick?: () => void;
  onCancelClick?: () => void;
  onDeleteClick?: () => void;
  confirmIcon: string;
  cancelIcon: string;
  deleteIcon: string;
  confirmText: string;
  cancelText: string;
  deleteButtonId?: string;
  cancelMenuProps?: IContextualMenuProps;
  deleteText: string;
  disabled: boolean;
  confirmButtonVisible: boolean;
  cancelButtonVisible: boolean;
  deleteButtonVisible: boolean;
}
export default function FormButtons({
  onConfirmClick,
  onCancelClick,
  onDeleteClick,
  confirmIcon,
  cancelIcon,
  deleteIcon,
  confirmText,
  cancelText,
  deleteButtonId,
  cancelMenuProps,
  deleteText,
  disabled,
  confirmButtonVisible,
  cancelButtonVisible,
  deleteButtonVisible,
}: IProps) {
  return (
    <Stack horizontal tokens={{ childrenGap: 16 }} horizontalAlign="end">
      {confirmButtonVisible && (
        <PrimaryButton
          text={confirmText}
          onClick={onConfirmClick}
          iconProps={{ iconName: confirmIcon }}
          disabled={disabled}
        />
      )}
      {cancelButtonVisible && (
        <DefaultButton
          text={cancelText}
          menuProps={cancelMenuProps}
          onClick={onCancelClick}
          iconProps={{ iconName: cancelIcon }}
          disabled={disabled}
        />
      )}
      {deleteButtonVisible && (
        <PrimaryButton
        id={deleteButtonId}
          text={deleteText}
          onClick={onDeleteClick}
          iconProps={{ iconName: deleteIcon }}
          disabled={disabled}
          style={{ backgroundColor: red[500], borderColor: red[500] }}
        />
      )}
    </Stack>
  );
}

FormButtons.defaultProps = {
  confirmText: "Enregistrer",
  cancelText: "Annuler",
  deleteText: "Supprimer",
  confirmIcon: "Save",
  cancelIcon: "Cancel",
  deleteIcon: "Delete",
  confirmButtonVisible: true,
  cancelButtonVisible: true,
  deleteButtonVisible: false,
  disabled: false,
};
