import React, { useContext, useEffect, useState } from "react";
import { Container, Grid, Avatar } from "@material-ui/core";
import { DashboardSchoolDto, SchoolApi } from "../../api-clients/api";
import { getApiUrl } from "../../api-clients";
import { SchoolContext } from "../../contexts/SchoolContext";
import { green, red, purple, blue } from "@material-ui/core/colors";
import Loading from "../Shared/Loading";
import CardStatistic, { ILabelFieldValue } from "../Shared/CardStatistic";
import AccessibilityIcon from "@material-ui/icons/Accessibility";
import PaymentIcon from "@material-ui/icons/Payment";
import ExpensesPieChart from "../Expenses/ExpensesPieChart";
import IncomeOutcomeLineChart from "../Statistics/IncomeOutcomeLineChart";
import Educations from "../Educations/Educations";
import { InstitutionContext } from "../../contexts/InstitutionContext";
import { Text } from "@fluentui/react";

export default function Dashboard() {
  const [schoolApi] = useState<SchoolApi>(new SchoolApi(getApiUrl()));
  const [schoolDashboard, setSchoolDashboard] =
    useState<DashboardSchoolDto | null>(null);
  const [isDashboardLoading, setIsDashboardLoading] = useState<boolean>(true);
  const { accademicYear, studentUpdated, expenseUpdated } =
    useContext(SchoolContext);
  const { institution } = useContext(InstitutionContext);

  const accademicYearDate: string = `${accademicYear?.startDate?.getFullYear()}/${accademicYear?.endDate?.getFullYear()}`;

  useEffect(() => {
    if (accademicYear != null && accademicYear.accademicYearId != null)
      schoolApi
        .dashboard(accademicYear.accademicYearId)
        .then((res) => {
          setSchoolDashboard(res);
          setIsDashboardLoading(false);
        })
        .catch((err) => setIsDashboardLoading(false));
  }, [schoolApi, accademicYear, studentUpdated, expenseUpdated]);

  return (
    <Container maxWidth="lg">
      <Text variant="xxLarge">
        {institution?.name} <Text variant="large">Dashboard</Text>
      </Text>
      {!isDashboardLoading ? (
        <Grid container spacing={1}>
          <Grid item sm={9} xs={12}>
            <IncomeOutcomeLineChart />
          </Grid>
          <Grid item sm={3} xs={12}>
            <ExpensesPieChart />
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <CardStatistic
              title="Inscriptions"
              values={[
                {
                  label: "Total",
                  value: schoolDashboard?.numberStudent ?? 0,
                } as ILabelFieldValue,
                {
                  label: "Actives",
                  value: schoolDashboard?.numberActiveStudent ?? 0,
                } as ILabelFieldValue,
                {
                  label: "Transport",
                  value: schoolDashboard?.numberStudentTransport ?? 0,
                } as ILabelFieldValue,

                {
                  label: "Garderie",
                  value: schoolDashboard?.numberStudentNursery ?? 0,
                } as ILabelFieldValue,
              ]}
              avatar={
                <Avatar style={{ backgroundColor: blue[200] }}>
                  <AccessibilityIcon />
                </Avatar>
              }
              subheader={accademicYearDate}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <CardStatistic
              title="Flux des paiements"
              values={[
                {
                  label: "Attendus",
                  value: schoolDashboard?.totalStudentCost ?? 0,
                  isCurrency: true,
                } as ILabelFieldValue,
                {
                  label: "Total payés",
                  value: schoolDashboard?.totalPayment ?? 0,
                  isCurrency: true,
                } as ILabelFieldValue,
                {
                  label:
                    (schoolDashboard?.totalPayment ?? 0) -
                      (schoolDashboard?.totalStudentCost ?? 0) <
                    0
                      ? "Impayés"
                      : "Solde paiements",
                  value:
                    (schoolDashboard?.totalPayment ?? 0) -
                    (schoolDashboard?.totalStudentCost ?? 0),
                  isCurrency: true,
                  valueColor:
                    (schoolDashboard?.totalPayment ?? 0) -
                      (schoolDashboard?.totalStudentCost ?? 0) <
                    0
                      ? red[500]
                      : green[500],
                } as ILabelFieldValue,
              ]}
              avatar={
                <Avatar style={{ backgroundColor: purple[200] }}>
                  <PaymentIcon />
                </Avatar>
              }
              subheader={accademicYearDate}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <CardStatistic
              title="Dépenses"
              values={[
                {
                  label: "Total dépenses",
                  value: schoolDashboard?.totalExpense ?? 0,
                  isCurrency: true,
                } as ILabelFieldValue,
              ]}
              avatar={
                <Avatar style={{ backgroundColor: red[200] }}>
                  <PaymentIcon />
                </Avatar>
              }
              subheader={accademicYearDate}
            />
          </Grid>
          <Grid item md={9} sm={7} xs={12}></Grid>

          <Grid item md={3} sm={5} xs={12}></Grid>
          <Grid item lg={12} md={12} xs={12}>
            <Educations />
          </Grid>
        </Grid>
      ) : (
        <Loading />
      )}
    </Container>
  );
}
