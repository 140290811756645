import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";

const StyledMenuItem = MenuItem;

interface IProps {
  anchorEl: any;
  handleClose: () => void;
  newStudentClick: () => void;
  newPaymentClick: () => void;
  newExpenseClick: () => void;
}
export default function MenuActions({
  anchorEl,
  handleClose,
  newStudentClick,
  newPaymentClick,
  newExpenseClick,
}: IProps) {
  return (
    <Menu
      id="customized-menu"
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      style={{ border: "1px solid #d3d4d5" }}
    >
      <StyledMenuItem onClick={() => newStudentClick()}>
        <ListItemIcon>
          <PersonAddIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Nouveau élève" />
      </StyledMenuItem>
      <StyledMenuItem onClick={() => newPaymentClick()}>
        <ListItemIcon>
          <AttachMoneyIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Nouveau paiement" />
      </StyledMenuItem>
      <StyledMenuItem onClick={() => newExpenseClick()}>
        <ListItemIcon>
          <MoneyOffIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Nouvelle dépense" />
      </StyledMenuItem>
    </Menu>
  );
}
