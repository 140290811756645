import React, { useContext, useState } from "react";
import {
  Grid,
  Typography,
} from "@material-ui/core";
import {
  StudentPathApi,
  StudentPathDto,
  StudentPathUpdateDto,
} from "../../api-clients/api";
import { IDropdownOption, Slider } from "@fluentui/react";
import FluentUiDatePicker from "../Shared/FluentUiDatePicker";
import ClassroomsDropDown from "../Shared/ClassroomsDropdown";
import { SchoolContext } from "../../contexts/SchoolContext";
import { getApiUrl } from "../../api-clients";
import { AppContext } from "../../contexts/AppContext";
import FormButtons from "../Shared/FormButtons";

interface IProps {
  studentPath: StudentPathDto;
  handleClose: () => void;
}
export default function UpdateStudentPath({
  studentPath,
  handleClose,
}: IProps) {
  const { openSnackbar } = useContext(AppContext);
  const [saving, setSaving] = useState<boolean>(false);
  const [studentPathApi] = useState<StudentPathApi>(
    new StudentPathApi(getApiUrl())
  );
  const { accademicYear, setStudentUpdated } = useContext(SchoolContext);
  const [form, setForm] = useState<StudentPathUpdateDto>({
    studentPathId: studentPath.studentPathId,
    accademicYearId: studentPath.accademicYearId,
    inscriptionFees: studentPath.inscriptionFees,
    monthlyFees: studentPath.monthlyFees,
    startDate: studentPath.startDate,
    endDate: studentPath.endDate,
    institutionId: studentPath.institutionId,
    classroomId: studentPath.classroomId,
    studentId: studentPath.studentId,
  } as StudentPathUpdateDto);

  const SaveStudent = () => {
    setSaving(true);
    studentPathApi
      .update(form)
      .then((res) => {
        openSnackbar("Enregistré", "success");
        setStudentUpdated(true);
        handleClose();
      })
      .catch((err) => {
        setSaving(false);
        openSnackbar("Une erreur s'est reproduite", "error");
      });
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          Année {accademicYear?.startDate?.getFullYear()}/
          {accademicYear?.endDate?.getFullYear()}
        </Typography>
      </Grid>
      <Grid item sm={12}>
        <FluentUiDatePicker
          required
          label="Date de début"
          placeholder="Date de début"
          value={form.startDate}
          minDate={accademicYear?.startDate}
          maxDate={accademicYear?.endDate}
          onSelectDate={(date: Date | null | undefined): void => {
            setForm({
              ...form,
              startDate: date,
            } as StudentPathUpdateDto);
          }}
        />
      </Grid>
      <Grid item sm={12}>
        <FluentUiDatePicker
          required
          label="Date de fin"
          placeholder="Date de fin"
          value={form.endDate}
          minDate={accademicYear?.startDate}
          maxDate={accademicYear?.endDate}
          onSelectDate={(date: Date | null | undefined): void => {
            setForm({
              ...form,
              endDate: date,
            } as StudentPathUpdateDto);
          }}
        />
      </Grid>
      <Grid item sm={12}>
        <ClassroomsDropDown
          required
          label="Classe"
          placeholder="Selectionner la classe"
          selectedKey={form.classroomId}
          onChange={(
            event: React.FormEvent<HTMLDivElement>,
            item?: IDropdownOption
          ): void => {
            if (item)
              setForm({
                ...form,
                classroomId: item.key,
              } as StudentPathUpdateDto);
          }}
        />
      </Grid>
      <Grid item sm={12}>
        <Slider
          label="Frais d'inscription (dirham)"
          max={accademicYear?.inscriptionFees ?? 2000}
          value={form.inscriptionFees ?? 0}
          defaultValue={form.inscriptionFees ?? 0}
          step={10}
          showValue
          onChange={(value: number) =>
            setForm({
              ...form,
              inscriptionFees: value,
            } as StudentPathUpdateDto)
          }
        />
      </Grid>
      <Grid item sm={12}>
        <Slider
          label="Frais de scolarité (dirham)"
          max={accademicYear?.monthlyFees ?? 2000}
          value={form.monthlyFees ?? 0}
          defaultValue={form.monthlyFees ?? 0}
          step={10}
          showValue
          onChange={(value: number) =>
            setForm({
              ...form,
              monthlyFees: value,
            } as StudentPathUpdateDto)
          }
        />
      </Grid>
      <Grid item sm={12}>
        <FormButtons
          disabled={saving}
          onConfirmClick={SaveStudent}
          onCancelClick={handleClose}
        />
      </Grid>
    </Grid>
  );
}