import React from "react";
import { ExpenseDto } from "../../api-clients/api";
import CRUDExpense from "../CRUD/CRUDExpense";

interface IProps {
  expense: ExpenseDto | null;
}
export default function EditExpense(props: IProps) {

  return (
    <CRUDExpense
      sm={12}
      md={12}
      showDelete={true}
      {...props}
    />
  );
}
