import React, { useContext } from "react";
import { Typography } from "@material-ui/core";
import { AuthContext } from "../../contexts/AuthContext";

export default function UsernameGreeting() {
  const { user } = useContext(AuthContext);

  return (
    <Typography
      component="h1"
      variant="h6"
      noWrap
      style={{ color: "white" }}
    >
      Bonjour {user.firstName ?? user.username} !
    </Typography>
  );
}
