import React, { useState, useContext, useEffect } from "react";
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { amber, blue, purple } from "@material-ui/core/colors";
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";
import { dateToString, numberToMad } from "../../helper";
import Loading from "../Shared/Loading";
import Collapse from "@material-ui/core/Collapse";
import LabelFieldValue from "../Shared/LabelFieldValue";
import {
  DashboardInscriptionDto,
  InscriptionApi,
  InscriptionDto,
  ServiceDto,
  ServiceType,
} from "../../api-clients/api";
import EditIcon from "@material-ui/icons/Edit";
import { ContextualMenu, Panel } from "@fluentui/react";
import CRUDService from "../CRUD/CRUDService";
import { SchoolContext } from "../../contexts/SchoolContext";
import PeopleIcon from "@material-ui/icons/People";
import { getApiUrl } from "../../api-clients";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import clsx from "clsx";
import { useId } from '@fluentui/react-hooks';
import RestaurantIcon from '@material-ui/icons/Restaurant';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

interface IProps {
  inscription: DashboardInscriptionDto | null | undefined;
  service: ServiceDto;
  isLoading: boolean;
  studentPathId: string;
}
export default function CardService({
  inscription,
  isLoading,
  service,
  studentPathId,
}: IProps) {
  const classes = useStyles();
  const uniqueId = useId('key');
  const [showMenuContext, setShowMenuContext] = useState<boolean>(false);
  const menuItemRef = React.useRef(null);
  const { accademicYear } = useContext(SchoolContext);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [loadingHistory, setLoadingHistory] = useState<boolean>(true);
  const [history, setHistory] = useState<InscriptionDto[] | null>(null);
  const [inscriptionApi] = useState<InscriptionApi>(
    new InscriptionApi(getApiUrl())
  );
  const [defaultFees] = useState<number>((service.enum === 1 ? accademicYear?.transportFees : (service.enum === 2 ? accademicYear?.childCareFees : (service.enum === 3 ? accademicYear?.canteenFees : 0)))??0);
  const [inscriptionToEdit, setInscriptionToEdit] =
    useState<InscriptionDto | null>(null);

  useEffect(() => {
    setInscriptionToEdit(null);
    if (service != null && service.serviceId != null) {
      inscriptionApi
        .service(studentPathId, service.serviceId)
        .then((res) => {
          setHistory(
            res.filter((r) => r.endDate != null && r.endDate < new Date())
          );
          setLoadingHistory(false);
        })
        .catch((err) => setLoadingHistory(false));
    }
  }, [
    inscription,
    inscriptionApi,
    service,
    studentPathId,
    setLoadingHistory,
    setInscriptionToEdit,
  ]);

  const expired: boolean =
    inscription != null &&
    inscription.endDate != null &&
    inscription.endDate <= new Date();

  const content = (i: InscriptionDto, showEditButton: boolean) => {
    return (
      <Grid container>
        <Grid item xs={6}>
          <LabelFieldValue
            label="Active du"
            value={dateToString(i.startDate)}
          />
        </Grid>
        <Grid item xs={6}>
          <LabelFieldValue label="Au" value={dateToString(i.endDate)} />
        </Grid>
        <Grid item xs={6}>
          <LabelFieldValue
            label="Frais mensuel"
            value={numberToMad(i.monthlyFees)}
          />
        </Grid>
        {showEditButton && (
          <Grid item xs={6}>
            <CardActions>
              <IconButton
                aria-label="Modifier"
                color="primary"
                onClick={() =>
                  setInscriptionToEdit({
                    inscriptionId: i.inscriptionId,
                    serviceId: i.serviceId,
                    startDate: i.startDate,
                    endDate: i.endDate,
                    monthlyFees: i.monthlyFees,
                    studentPathId: studentPathId,
                  } as InscriptionDto)
                }
              >
                <EditIcon />
              </IconButton>
            </CardActions>
          </Grid>
        )}
      </Grid>
    );
  };

  const expandButton = (
    <IconButton
      className={clsx(classes.expand, {
        [classes.expandOpen]: expanded,
      })}
      onClick={() => setExpanded(!expanded)}
      aria-expanded={expanded}
      aria-label="show more"
    >
      <ExpandMoreIcon />
    </IconButton>
  );

  return (
    <>
      <Card>
        <CardHeader
          title={service.name}
          avatar={
            <>
              {service.enum === ServiceType._1 && (
                <Avatar style={{ backgroundColor: amber[300] }}>
                  <AirportShuttleIcon />
                </Avatar>
              )}
              {service.enum === ServiceType._2 && (
                <Avatar style={{ backgroundColor: purple[200] }}>
                  <PeopleIcon />
                </Avatar>
              )}
              {service.enum === ServiceType._3 && (
                <Avatar style={{ backgroundColor: blue[200] }}>
                  <RestaurantIcon />
                </Avatar>
              )}
            </>
          }
          action={
            <IconButton
              aria-label="Menu"
              ref={menuItemRef}
              onClick={() => setShowMenuContext(true)}
            >
              <MoreVertIcon />
            </IconButton>
          }
        />
        <ContextualMenu
          items={[
            {
              disabled: inscription != null && !expired,
              key: `subscribe-${uniqueId}`,
              text: `Nouvelle inscription ${service.name?.toLowerCase()}`,
              iconProps: { iconName: "Subscribe" },
              onClick: () =>
                setInscriptionToEdit({
                  serviceId: service.serviceId,
                  startDate: accademicYear != null && accademicYear.startDate != null && accademicYear.startDate >= new Date() ? accademicYear.startDate : new Date(),
                  endDate: accademicYear?.endDate,
                  monthlyFees: defaultFees,
                  studentPathId: studentPathId,
                } as InscriptionDto),
            },
            {
              disabled: inscription == null || expired,
              key: `modify-${uniqueId}`,
              text: `Modifier inscription ${service.name?.toLowerCase()}`,
              iconProps: { iconName: "PageEdit" },
              onClick: () =>
                setInscriptionToEdit({
                  inscriptionId: inscription?.inscriptionId,
                  serviceId: service?.serviceId,
                  startDate: inscription?.startDate,
                  endDate: inscription?.endDate,
                  monthlyFees: inscription?.monthlyFees,
                  studentPathId: studentPathId,
                } as InscriptionDto),
            },
          ]}
          hidden={!showMenuContext}
          target={menuItemRef}
          onItemClick={() => setShowMenuContext(false)}
          onDismiss={() => setShowMenuContext(false)}
        />
        {!isLoading ? (
          inscription ? (
            <>
              <CardContent>
                {expired && (
                  <Typography variant="body1" color="secondary" gutterBottom>
                    Expiré
                  </Typography>
                )}
                {content(inscription, false)}
              </CardContent>
              <CardActions disableSpacing>
                {history != null && history.length > 0 && expandButton}
              </CardActions>
            </>
          ) : (
            <>
              <CardContent>
                <Typography variant="caption" display="block">
                  Non inscrit
                </Typography>
              </CardContent>
              {service != null && accademicYear != null && (
                <CardActions disableSpacing>
                  {history != null && history.length > 0 && expandButton}
                </CardActions>
              )}
            </>
          )
        ) : (
          <CardContent>
            <Loading />
          </CardContent>
        )}

        {!loadingHistory ? (
          history != null &&
          history.length > 0 && (
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent>
                <Divider />
                <Typography variant="h6" gutterBottom>
                  Historique
                </Typography>
                {history.map((h) => content(h, true))}
              </CardContent>
            </Collapse>
          )
        ) : (
          <Loading />
        )}
      </Card>

      <Panel
        isBlocking={true}
        headerText={service.name}
        isOpen={inscriptionToEdit != null}
        onDismiss={() => setInscriptionToEdit(null)}
        closeButtonAriaLabel="Fermer"
      >
        {inscriptionToEdit != null && (
          <CRUDService
            inscription={inscriptionToEdit}
            onCancelClick={() => setInscriptionToEdit(null)}
          />
        )}
      </Panel>
    </>
  );
}
