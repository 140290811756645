
import React from "react";
import { DoubleChartDto } from "../../api-clients/api";
import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  Cell,
} from "recharts";
import { CustomTooltip, AppPieChartColors, IAppPieChartProps } from "./common";
import { Typography } from "@material-ui/core";

AppPieChart.defaultProps = {
  showLegend: false,
  showTooltip: true,
  showTooltipValue: true,
  currency:"",
  width: 350,
  height: 350
};

export default function AppPieChart({
  data,
  showLegend,
  showTooltip,
  showTooltipValue,
  onSliceClick,
  width,
  height
}: IAppPieChartProps) {
  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    name,
    index,
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  return (
    <div>
      {data != null && data.top != null && data.top.length > 0 ? (
        <PieChart width={width} height={height}>
          {showLegend && <Legend />}
          {showTooltip && (
            <Tooltip content={<CustomTooltip showValue={showTooltipValue} />} />
          )}

          <Pie
            // cx={150}
            dataKey="value"
            isAnimationActive={false}
            data={[
              ...data.top,
              { name: "Autres", value: data.othersValue } as DoubleChartDto,
            ]}
            // outerRadius={(width / 2.5)}
            legendType="rect"
            labelLine={false}
            label={renderCustomizedLabel}
            onClick={(e: any) => {
              if (e.payload != null && e.payload.id > 0 && onSliceClick != null)
                onSliceClick(e.payload.id);
            }}
          >
            {[
              ...data.top,
              { name: "Autres", value: data.othersValue } as DoubleChartDto,
            ].map((entry, index) => (
              <Cell
                key={`imCell-${index}`}
                fill={AppPieChartColors[index % AppPieChartColors.length]}
              />
            ))}
          </Pie>
        </PieChart>
      ) : (
        <Typography variant="body2" >
          No data
        </Typography>
      )}
    </div>
  );
}
