import React from "react";
import {
  Container,
  Grid,
} from "@material-ui/core";
import CardStudents from "./CardStudents";
import NewStudent from "./NewStudent";

export default function Students() {
  return (
    <Container maxWidth="lg">
      <Grid container spacing={1}>
        <Grid item sm={6} xs={12}>
          <CardStudents />
        </Grid>
        <Grid item sm={6} xs={12}>
          <NewStudent />
        </Grid>
      </Grid>
    </Container>
  );
}
