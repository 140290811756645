import React, { useContext } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { AuthContext } from "./contexts/AuthContext";
import Login from "./components/Login/Login";
import ElementaryClassroom from "./assets/images/ElementaryClassroom.jpg";
import Loading from "./components/Shared/Loading";
import { InstitutionContext } from "./contexts/InstitutionContext";
import { SchoolProvider } from "./providers/SchoolProvider";
import AppContent from "./AppContent";

function App() {
  const { institution } = useContext(InstitutionContext);
  const { isAuthenticated, user } = useContext(AuthContext);

  return (
    <div
      className="App"
      style={{
        height: "100vh",
        backgroundImage: `url(${ElementaryClassroom})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div
        style={{
          height: "100vh",
          // backgroundColor: "#EEEEEE"
          backgroundColor: "rgba(238,238,238,0.7)",
          // backgroundColor: "rgba(255,255,255,0.4)",
        }}
      >
        <Router>
          {!isAuthenticated ? (
            <Switch>
              <Route path="/login">
                <Login />
              </Route>
              <Redirect
                to={{
                  pathname: "/login",
                }}
              />
            </Switch>
          ) : user != null && institution != null ? (
            <SchoolProvider>
              <AppContent />
            </SchoolProvider>
          ) : (
            <Loading />
          )}
        </Router>
      </div>
    </div>
  );
}

export default App;
