import React, { useState } from "react";
import { Dropdown, IDropdownOption } from "@fluentui/react";

interface IProps {
  onChange: (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    index?: number
  ) => void;
  multiSelect: boolean;
  selectedKeys: string[] | number[] | null;
  selectedKey?: string | number | string[] | number[] | null;
  label?: string;
  placeholder?: string;
  required: boolean;
}
export default function OrderByDropdown(props: IProps) {
  const [options] = useState<any[]>([
    {
      key: 1,
      text: "Trier par le plus récent",
    },
    {
      key: 2,
      text: "Trier par le plus ancien",
    },
    {
      key: 3,
      text: "Trier par date de création",
    },
  ]);

  return <Dropdown {...props} options={options} style={{ width: "100%" }} />;
}

OrderByDropdown.defaultProps = {
  multiSelect: false,
  selectedKeys: null,
  selectedKey: null,
  label: null,
  placeholder: null,
  required: false,
};
