import React, { useContext, useState } from "react";
import {
  CircularProgress,
  Grid,
  GridSize,
  makeStyles,
} from "@material-ui/core";
import {
  PaymentApi,
  PaymentDto,
  PaymentTermType,
  StudentPaymentDto,
} from "../../api-clients/api";
import { IDropdownOption, IPersonaProps, TextField } from "@fluentui/react";
import FluentUiDatePicker from "../Shared/FluentUiDatePicker";
import { getApiUrl } from "../../api-clients";
import { AppContext } from "../../contexts/AppContext";
import { SchoolContext } from "../../contexts/SchoolContext";
import PaymentTermDropdown from "../Shared/PaymentTermDropdown";
import StudentPathPicker from "../Shared/StudentPathPicker";
import StudentPaymentForm from "../Forms/StudentPaymentForm";
import { FieldArray, withFormik } from "formik";
import { useHistory } from "react-router-dom";
import FormButtons from "../Shared/FormButtons";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  wrapper: {
    position: "relative",
    marginTop: "10px",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

interface IProps {
  payment?: PaymentDto | null;
  md: GridSize;
  sm: GridSize;
  accademicYearId: string;
  institutionId: string;
}

const NotreForm = (props: any) => {
  const { values, handleSubmit, setFieldValue, setSubmitting, md, sm } = props;
  const history = useHistory();
  const { setPaymentUpdated } = useContext(SchoolContext);
  const classes = useStyles();
  const { openSnackbar } = useContext(AppContext);
  const [saving, setSaving] = useState<boolean>(false);
  const [paymentApi] = useState<PaymentApi>(new PaymentApi(getApiUrl()));

  const canSave: boolean = true;

  const savePayment = () => {
    setSaving(true);
    setSubmitting(true);
    if (values.paymentId != null) {
      console.log("updating");
      paymentApi
        .paymentPost({ ...values } as PaymentDto)
        .then((res) => {
          openSnackbar("Payement bien enregistré", "success");
          setSubmitting(false);
          setPaymentUpdated(true);
          history.push("/payment/" + res);
        })
        .catch((err) => {
          setSaving(false);
          setSubmitting(false);
          openSnackbar("Une erreur s'est reproduite", "error");
        });
    } else {
      paymentApi
        .paymentPut({ ...values } as PaymentDto)
        .then((res) => {
          openSnackbar("Payement bien enregistré", "success");
          setSubmitting(false);
          setPaymentUpdated(true);
          history.push("/payment/" + res);
        })
        .catch((err) => {
          setSaving(false);
          setSubmitting(false);
          openSnackbar("Une erreur s'est reproduite", "error");
        });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className={classes.root}>
          <FieldArray
            name="studentPayments"
            render={(arrayHelpers) => (
              <Grid container spacing={1}>
                <Grid item md={md} sm={sm}>
                  <FluentUiDatePicker
                    required
                    label="Date"
                    placeholder="Date"
                    value={values.date}
                    onSelectDate={(date: Date | null | undefined): void => {
                      setFieldValue("date", date);
                    }}
                  />
                </Grid>
                <Grid item md={md} sm={sm}>
                  <TextField
                    required
                    autoComplete="off"
                    label="Payé par"
                    placeholder="Payé par Mme/Mr"
                    value={values.paidBy}
                    onChange={(
                      event: React.FormEvent<
                        HTMLInputElement | HTMLTextAreaElement
                      >,
                      newValue?: string
                    ) => setFieldValue("paidBy", newValue)}
                  />
                </Grid>
                <Grid item md={md} sm={sm}>
                  <PaymentTermDropdown
                    required
                    label="Mode de payement"
                    placeholder="Selectionner un mode de payement"
                    selectedKey={values.paymentTerm}
                    onChange={(
                      event: React.FormEvent<HTMLDivElement>,
                      item?: IDropdownOption
                    ): void => {
                      if (item) setFieldValue("paymentTerm", item.key);
                    }}
                  />
                </Grid>
                <Grid item md={md} sm={sm}>
                  <TextField
                    autoComplete="off"
                    label="Commentaire"
                    placeholder="Commentaire"
                    value={values.comment}
                    onChange={(
                      event: React.FormEvent<
                        HTMLInputElement | HTMLTextAreaElement
                      >,
                      newValue?: string
                    ) => setFieldValue("comment", newValue)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StudentPathPicker
                    selectedStudents={(values.studentPayments ?? []).map(
                      (sp: StudentPaymentDto) => {
                        return {
                          id: sp.studentPathId,
                          text: `${sp.studentFirstName} ${sp.studentLastName}`,
                          secondaryText: sp.classroomName,
                        } as IPersonaProps;
                      }
                    )}
                    onStudentSelected={(selectedStudent: any) => {
                      arrayHelpers.insert(0, {
                        studentPathId: selectedStudent.id,
                        studentFirstName: selectedStudent.text,
                        studentLastName: "",
                        classroomName: selectedStudent.secondaryText,
                      } as StudentPaymentDto);
                    }}
                    onRemoveStudent={(studentPathId) => {
                      if (
                        values.studentPayments != null &&
                        values.studentPayments.length > 0
                      ) {
                        arrayHelpers.remove(
                          values.studentPayments
                            .map((s: StudentPaymentDto) => s.studentPathId)
                            .indexOf(studentPathId)
                        );
                      }
                    }}
                  />
                </Grid>
                {values.studentPayments != null &&
                  values.studentPayments.length > 0 &&
                  values.studentPayments.map(
                    (s: StudentPaymentDto, index: number) => (
                      <Grid
                        item
                        xs={12}
                        key={`StudentPaymentForm-${index}-${s.studentPathId}`}
                      >
                        <StudentPaymentForm
                          uniqueId={`studentPaymentForm${index}`}
                          onRemoveClick={() => {
                            arrayHelpers.remove(index);
                          }}
                          title={`${s.studentFirstName} ${s.studentLastName}`}
                          studentPayment={s}
                          setStudentPayment={(ssp) => {
                            arrayHelpers.replace(index, ssp);
                          }}
                        />
                      </Grid>
                    )
                  )}
              </Grid>
            )}
          />
        </div>
        <div className={classes.wrapper}>
          <FormButtons
            onConfirmClick={savePayment}
            disabled={saving || !canSave || values.studentPayments.length === 0}
            cancelButtonVisible={false}
            confirmIcon={values.paymentId != null ? "Save" : "Add"}
            confirmText={values.paymentId != null ? "Enregistrer" : "Ajouter"}
          />
          {saving && (
            <CircularProgress
              size={24}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: -12,
                marginLeft: -12,
              }}
            />
          )}
        </div>
      </form>
    </>
  );
};

const CRUDPayment = withFormik({
  mapPropsToValues: ({
    payment,
    md,
    sm,
    accademicYearId,
    institutionId,
  }: IProps) => {
    return payment != null
      ? payment
      : ({
          accademicYearId: accademicYearId,
          institutionId: institutionId,
          paymentTerm: PaymentTermType._2,
          date: new Date(),
          studentPayments: [] as StudentPaymentDto[],
          comment: "",
          paidBy: "Parent",
        } as PaymentDto);
  },
  // validationSchema: () => {
  //   return Yup.object().shape({
  //     accademicYearId: Yup.string().required(),

  //     studentPayments: Yup.array()
  //       .of(
  //         Yup.object().shape({
  //           // name: Yup.string().min(4, "too short").required("Required"),
  //           // salary: Yup.string().min(3, "cmon").required("Required"),
  //         })
  //       )
  //       .required("Must have friends")
  //       .min(3, "Minimum of 3 friends"),
  //   });
  // },

  handleSubmit: (values, { props, setSubmitting }) => {
    console.log(values);
  },
})(NotreForm);

export default CRUDPayment;
