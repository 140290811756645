import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { logout } from "../../../api-clients";

const StyledMenuItem = (MenuItem);

interface IProps {
  anchorEl: any;
  handleClose: () => void;
}
export default function MenuUser({ anchorEl, handleClose }: IProps) {

  return (
    <Menu
      id="customized-menu"
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      style={{ border: "1px solid #d3d4d5" }}
    >
      <StyledMenuItem
        onClick={() => {
          logout();
        }}
      >
        <ListItemIcon>
          <ExitToAppIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Se déconnecter" />
      </StyledMenuItem>
    </Menu>
  );
}
