import React, { useContext, useState } from "react";
import {
  Button,
  Container,
  CssBaseline,
  makeStyles,
  TextField,
  Box,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import Copyright from "../Shared/Copyright";
import { LoginDto } from "../../api-clients/api";
import { AuthContext } from "../../contexts/AuthContext";
import Logo from "../../assets/images/logo.png";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "rgba(255,255,255,0.7)",
    padding: "15px",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login() {
  const classes = useStyles();
  const [credentials, setCredentials] = useState<LoginDto>({} as LoginDto);
  const { login, isAuthenticating } = useContext(AuthContext);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Grid container alignItems="center" justify="flex-start">
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <img src={Logo} alt="Princibo.ma" style={{ width: "190px" }} />
            {/* <br></br>
            <img
              src={Princibo}
              alt="Princibo.ma"
              style={{ height: "20px", marginTop: "15px" }}
            /> */}
          </Grid>
        </Grid>
        <div className={classes.form}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            disabled={isAuthenticating}
            onChange={(event) =>
              setCredentials({
                ...credentials,
                userName: event.target.value,
              } as LoginDto)
            }
            onKeyUp={(event) => {
              if (event.keyCode === 13) login(credentials);
            }}
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Mot de passe"
            type="password"
            id="password"
            autoComplete="current-password"
            disabled={isAuthenticating}
            onChange={(event) =>
              setCredentials({
                ...credentials,
                password: event.target.value,
              } as LoginDto)
            }
            onKeyUp={(event) => {
              if (event.keyCode === 13) login(credentials);
            }}
          />
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Rester connecté"
          /> */}
          <div>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isAuthenticating}
              className={classes.submit}
              onClick={() => login(credentials)}
            >
              Login
            </Button>
            {isAuthenticating && (
              <CircularProgress
                size={24}
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: -12,
                  marginLeft: -12,
                }}
              />
            )}
          </div>
          {/* <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Mot de passe oublié ?
              </Link>
            </Grid>
          </Grid> */}
        </div>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
