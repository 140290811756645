import React from "react";
import { DoubleChartDto } from "../../api-clients/api";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  Brush,
} from "recharts";
import { CustomTooltip } from "./common";
import { numberCompacted } from "../../helper";
import { Typography } from "@material-ui/core";

interface IProps {
  data: DoubleChartDto[] | null;
  width: number;
  showBrush: boolean;
  showSecondValue: boolean;
  valueColor: string | undefined;
  value2Color: string | undefined;
  name: string;
  name2: string;
  height: number;
}
export default function AppLineChart({
  data,
  showSecondValue,
  valueColor,
  value2Color,
  name,
  name2,
  width,
  height,
  showBrush,
}: IProps) {
  return (
    <div>
      {data != null && data.length > 0 ? (
        <LineChart width={width} height={height} data={data}>
          <XAxis dataKey="name" />
          <YAxis tickFormatter={(v) => numberCompacted(+v)} />
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <Legend />
          <Tooltip content={<CustomTooltip showValue={true} />} />
          <Line
            type="monotone"
            stroke={valueColor}
            name={name}
            dataKey="value"
            strokeWidth={2}
            onClick={(v) => console.log(v)} 
          />
          {showSecondValue && (
            <Line
              type="monotone"
              stroke={value2Color}
              name={name2}
              dataKey="value2"
              strokeWidth={2}
            />
          )}
          {showBrush && <Brush />}
        </LineChart>
      ) : (
        <Typography variant="body2">No data</Typography>
      )}
    </div>
  );
}

AppLineChart.defaultProps = {
  showSecondValue: false,
  valueColor: null,
  value2Color: null,
  name2: "",
  width: 600,
  height: 270,
  showBrush: false,
};
