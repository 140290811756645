import React, { useState, useContext } from "react";
import { CircularProgress, Grid, GridSize, Divider } from "@material-ui/core";
import StudentIdPicker from "../Shared/StudentIdPicker";
import { IPersonaProps, Text } from "@fluentui/react";
import StudentPathForm from "../CRUD/Forms/StudentPathForm";
import { NewStudentPathDto, StudentPathApi } from "../../api-clients/api";
import { SchoolContext } from "../../contexts/SchoolContext";
import FormButtons from "../Shared/FormButtons";
import { getApiUrl } from "../../api-clients";
import { AppContext } from "../../contexts/AppContext";
import { useHistory } from "react-router";

interface IProps {
  md: GridSize;
  xs: GridSize;
  sm: GridSize;
}
export default function StudentNewPath({ md, xs, sm }: IProps) {
  const history = useHistory();
  const { accademicYear, accademicYearLabel } = useContext(SchoolContext);
  const { openSnackbar } = useContext(AppContext);
  const [studentPathApi] = useState<StudentPathApi>(
    new StudentPathApi(getApiUrl())
  );
  const [students, setStudents] = useState<IPersonaProps[]>([]);
  const [saving, setSaving] = useState<boolean>(false);
  const [newStudentPath, setNewStudentPath] = useState<NewStudentPathDto>({
    startDate: accademicYear?.startDate,
    endDate: accademicYear?.endDate,
    inscriptionFees: accademicYear?.inscriptionFees,
    monthlyFees: accademicYear?.monthlyFees,
    accademicYearId: accademicYear?.accademicYearId,
  } as NewStudentPathDto);

  const canSave: boolean =
    students.length === 1 &&
    (newStudentPath.classroomId?.length ?? 0) > 0 &&
    (newStudentPath.accademicYearId?.length ?? 0) > 0 &&
    students[0].tertiaryText !== accademicYear?.accademicYearId;

  const SaveStudent = () => {
    setSaving(true);
    studentPathApi
      .newStudentPath({
        ...newStudentPath,
        studentId: students[0].id ?? "",
      } as NewStudentPathDto)
      .then((res) => {
        openSnackbar("Inscription enregistré", "success");
        setSaving(true);
        history.push("/student/" + students[0].id ?? "");
      })
      .catch((err) => {
        setSaving(false);
        openSnackbar("Une erreur s'est reproduite", "error");
      });
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <StudentIdPicker
          title="Élève"
          selectedStudents={students}
          onListChanged={(list: IPersonaProps[]) => setStudents(list)}
          onStudentSelected={(selectedStudent: any) => {
            if (students.length === 0)
              setStudents([...students, selectedStudent]);
          }}
          onRemoveStudent={(studentId: string) => {
            setStudents([]);
          }}
          maxSelect={1}
          allAccademicYears
        />
        {students.length === 1 &&
          students[0]?.tertiaryText === accademicYear?.accademicYearId && (
            <Text variant="small" style={{ color: "rgb(164, 38, 44)", marginTop: "5px", marginLeft: "4px" }}>
              Élève déjà inscrit à l'année scolaire {accademicYearLabel}
            </Text>
          )}
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <StudentPathForm
          student={newStudentPath}
          setStudent={setNewStudentPath}
          disabled={saving}
          sm={sm}
          xs={xs}
          md={md}
        />
      </Grid>
      <Grid item xs={12}>
        <FormButtons
          onConfirmClick={SaveStudent}
          disabled={saving || !canSave}
          cancelButtonVisible={false}
          confirmIcon="Add"
          confirmText="Renouvler inscription"
        />
        {saving && (
          <CircularProgress
            size={24}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: -12,
              marginLeft: -12,
            }}
          />
        )}
      </Grid>
    </Grid>
  );
}
