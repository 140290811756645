import React from "react";
import {
  Container,
  Grid,
} from "@material-ui/core";
import CardParents from "./CardParents";
import NewParent from "./NewParent";

export default function Parents() {
  return (
    <Container maxWidth="lg">
      <Grid container spacing={1}>
        <Grid item sm={6} xs={12}>
          <CardParents />
        </Grid>
        <Grid item sm={6} xs={12}>
          <NewParent />
        </Grid>
      </Grid>
    </Container>
  );
}
