import React, { useContext } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { InstitutionContext } from "../../../contexts/InstitutionContext";
import SchoolIcon from "@material-ui/icons/School";

const StyledMenuItem = (MenuItem);

interface IProps {
  anchorEl: any;
  handleClose: () => void;
}
export default function MenuInstitutions({ anchorEl, handleClose }: IProps) {
  const { institutions, setInstitution } = useContext(InstitutionContext);
  return (
    <div>
      {institutions != null && institutions.length > 1 && (
        <Menu
          id="customized-menu"
          elevation={0}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          style={{ border: "1px solid #d3d4d5" }}
        >
          {institutions != null &&
            institutions.map((i, index) => (
              <StyledMenuItem
                key={"institution-menu-" + index}
                onClick={() => {
                  setInstitution(i);
                  handleClose();
                  localStorage.setItem("institutionId", i.institutionId ?? "");
                  window.location.reload();
                }}
              >
                <ListItemIcon>
                  <SchoolIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={i.name} />
              </StyledMenuItem>
            ))}
        </Menu>
      )}
    </div>
  );
}
