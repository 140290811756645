import React, { useContext } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import { SchoolContext } from "../../../contexts/SchoolContext";

const StyledMenuItem = MenuItem;

interface IProps {
  anchorEl: any;
  handleClose: () => void;
}
export default function MenuAccademicYears({ anchorEl, handleClose }: IProps) {
  const { accademicYears, setAccademicYear } =
    useContext(SchoolContext);
  return (
    <div>
      {accademicYears != null && accademicYears.length > 1 && (
        <Menu
          id="accademicYear-menu"
          elevation={0}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          style={{ border: "1px solid #d3d4d5" }}
        >
          {accademicYears.map((i, index) => (
            <StyledMenuItem
              key={"accademicYear-menu-" + index}
              onClick={() => {
                setAccademicYear(i);
                handleClose();
                localStorage.setItem(
                  "accademicYearId",
                  i.accademicYearId ?? ""
                );
                window.location.reload();
              }}
            >
              {/* <ListItemIcon>
                <SchoolIcon fontSize="small" />
              </ListItemIcon> */}
              <ListItemText
                primary={`${i?.startDate?.getFullYear()}/
              ${i?.endDate?.getFullYear()}`}
              />
            </StyledMenuItem>
          ))}
        </Menu>
      )}
    </div>
  );
}
