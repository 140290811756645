import React, { useContext } from "react";
import {
  ExpenseDto,
  PeriodReportingDto,
  StudentPaymentDto,
} from "../../api-clients/api";
import { InstitutionContext } from "../../contexts/InstitutionContext";
import { SchoolContext } from "../../contexts/SchoolContext";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { isMobile, isTablet } from "react-device-detect";
import { dateToString, numberToMadPdf } from "../../helper";
import { pdfFooter, pdfHeader } from "../PDF/PdfLayout";
var pdfMake = require("pdfmake/build/pdfmake.js");
var pdfFonts = require("pdfmake/build/vfs_fonts.js");
pdfMake.vfs = pdfFonts.pdfMake.vfs;

interface IProps {
  periodReporting: PeriodReportingDto;
}
export default function PeriodReportingPdf({ periodReporting }: IProps) {
  const { institution } = useContext(InstitutionContext);
  const { accademicYear } = useContext(SchoolContext);
  const secondaryColor = "#5d5d5d";
  const highlitedColor = "#2E3192";
  const space = { text: " \n\n" };
  const detail = { fontSize: 8, color: secondaryColor };
  const tableHeader = { bold: true };
  const header = { fontSize: 18, bold: true, color: highlitedColor };
  const subHeader = { fontSize: 10 };
  const title = { fontSize: 15 };
  const table = { margin: [0, 10, 0, 0] };

  const getPaymentHeadersWidths = (): any[] => {
    var headers: any[] = ["auto", "*", "*"];
    if (accademicYear != null) {
      if (accademicYear.hasTransport) headers.push("*");
      if (accademicYear.hasChildCare) headers.push("*");
      if (accademicYear.hasCanteen) headers.push("*");
    }

    return headers;
  };

  const getPaymentHeaders = (): any[] => {
    var headers: any[] = [
      { text: "Élève", ...tableHeader },
      { text: "Inscription", ...tableHeader },
      { text: "Scolarité", ...tableHeader },
    ];
    if (accademicYear != null) {
      if (accademicYear.hasTransport)
        headers.push({ text: "Transport", ...tableHeader });
      if (accademicYear.hasChildCare)
        headers.push({ text: "Garderie", ...tableHeader });
      if (accademicYear.hasCanteen)
        headers.push({ text: "Cantine", ...tableHeader });
    }

    return headers;
  };

  const onlyUnique = (value: any, index: number, self: any[]) => {
    return self.indexOf(value) === index;
  };

  const hasTransport: boolean =
    periodReporting.payments.filter((sf) => sf.transportAmount > 0).length > 0;
  const hasChildcare: boolean =
    periodReporting.payments.filter((sf) => sf.childCareAmount > 0).length > 0;
  const hasCanteen: boolean =
    periodReporting.payments.filter((sf) => sf.canteenAmount > 0).length > 0;

  const getPaymentsTermDetails = (s: StudentPaymentDto[]): any[] => {
    var content: any[] = [];
    var educations = s
      .map((s) => s.educationEnum)
      .filter(onlyUnique)
      .sort();

    if (s.length > 0) {
      educations.forEach((e) => {
        const sp: StudentPaymentDto[] = s.filter(
          (ef) => ef.educationEnum === e
        );

        content.push([
          {
            text: sp[0].educationName,
            color: highlitedColor,
          },
          {
            text: numberToMadPdf(
              sp
                .filter((spf) => spf.paymentTermId === 2)
                .reduce((a, b) => a + b.totalAmount, 0)
            ),
            alignment: "right",
          },
          {
            text: numberToMadPdf(
              sp
                .filter(
                  (spf) => spf.paymentTermId === 3 || spf.paymentTermId === 4
                )
                .reduce((a, b) => a + b.totalAmount, 0)
            ),
            alignment: "right",
          },
          {
            text: numberToMadPdf(
              sp
                .filter((spf) => spf.paymentTermId === 5)
                .reduce((a, b) => a + b.totalAmount, 0)
            ),
            alignment: "right",
          },
          {
            text: numberToMadPdf(
              sp
                .filter(
                  (spf) => spf.paymentTermId === 1 || spf.paymentTermId === 6
                )
                .reduce((a, b) => a + b.totalAmount, 0)
            ),
            alignment: "right",
          },
          {
            text: numberToMadPdf(sp.reduce((a, b) => a + b.totalAmount, 0)),
            alignment: "right",
          },
        ]);
      });

      content.push([
        {
          text: "Total",
          color: highlitedColor,
          alignment: "right",
        },
        {
          text: numberToMadPdf(
            s
              .filter((spf) => spf.paymentTermId === 2)
              .reduce((a, b) => a + b.totalAmount, 0)
          ),
          alignment: "right",
        },
        {
          text: numberToMadPdf(
            s
              .filter(
                (spf) => spf.paymentTermId === 3 || spf.paymentTermId === 4
              )
              .reduce((a, b) => a + b.totalAmount, 0)
          ),
          alignment: "right",
        },
        {
          text: numberToMadPdf(
            s
              .filter((spf) => spf.paymentTermId === 5)
              .reduce((a, b) => a + b.totalAmount, 0)
          ),
          alignment: "right",
        },
        {
          text: numberToMadPdf(
            s
              .filter(
                (spf) => spf.paymentTermId === 1 || spf.paymentTermId === 6
              )
              .reduce((a, b) => a + b.totalAmount, 0)
          ),
          alignment: "right",
        },
        {
          text: numberToMadPdf(s.reduce((a, b) => a + b.totalAmount, 0)),
          alignment: "right",
        },
      ]);
    }

    return content;
  };

  const getPaymentsServicesHeaders = (s: StudentPaymentDto[]): any[] => {
    var content: any[] = [];
    if (hasTransport) content.push("Transport");

    if (hasChildcare) content.push("Garderie");

    if (hasCanteen) content.push("Cantine");

    return ["Inscription", "Scolarité", ...content];
  };

  const getPaymentsServicesContent = (s: StudentPaymentDto[]): any[] => {
    var content: any[] = [];
    var educations = s
      .map((s) => s.educationEnum)
      .filter(onlyUnique)
      .sort();

    if (s.length > 0) {
      educations.forEach((e) => {
        const sp: StudentPaymentDto[] = s.filter(
          (ef) => ef.educationEnum === e
        );
        var row: any[] = [];

        row.push({
          text: sp[0].educationName,
          color: highlitedColor,
        });

        row.push({
          text: numberToMadPdf(sp.reduce((a, b) => a + b.inscriptionAmount, 0)),
        });

        row.push({
          text: numberToMadPdf(sp.reduce((a, b) => a + b.schoolAmount, 0)),
        });

        if (hasTransport)
          row.push({
            text: numberToMadPdf(sp.reduce((a, b) => a + b.transportAmount, 0)),
          });

        if (hasChildcare)
          row.push({
            text: numberToMadPdf(sp.reduce((a, b) => a + b.childCareAmount, 0)),
          });

        if (hasCanteen)
          row.push({
            text: numberToMadPdf(sp.reduce((a, b) => a + b.canteenAmount, 0)),
          });

        row.push({
          text: numberToMadPdf(sp.reduce((a, b) => a + b.totalAmount, 0)),
          alignment: "right",
        });

        content.push(row);
      });

      var lastRow: any[] = [];

      lastRow.push({
        text: "Total",
        color: highlitedColor,
        alignment: "right",
      });
      lastRow.push({
        text: numberToMadPdf(s.reduce((a, b) => a + b.inscriptionAmount, 0)),
        alignment: "right",
      });
      lastRow.push({
        text: numberToMadPdf(s.reduce((a, b) => a + b.schoolAmount, 0)),
        alignment: "right",
      });
      if (hasTransport)
        lastRow.push({
          text: numberToMadPdf(s.reduce((a, b) => a + b.transportAmount, 0)),
          alignment: "right",
        });
      if (hasChildcare)
        lastRow.push({
          text: numberToMadPdf(s.reduce((a, b) => a + b.childCareAmount, 0)),
          alignment: "right",
        });
      if (hasCanteen)
        lastRow.push({
          text: numberToMadPdf(s.reduce((a, b) => a + b.canteenAmount, 0)),
          alignment: "right",
        });

      lastRow.push({
        text: numberToMadPdf(s.reduce((a, b) => a + b.totalAmount, 0)),
        alignment: "right",
      });
      content.push(lastRow);
    }

    return content;
  };

  const getExpensesContent = (e: ExpenseDto[]): any[] => {
    var content: any[] = [];
    var furnishers = e
      .map((e) => e.furnisher?.name)
      .filter(onlyUnique)
      .sort();

    if (e.length > 0) {
      furnishers.forEach((f) => {
        const ex: ExpenseDto[] = e.filter((ef) => ef.furnisher?.name === f);
        content.push([
          {
            text: f,
            color: highlitedColor,
          },
          {
            text: numberToMadPdf(
              ex
                .filter((ef) => ef.paymentTermId === 2)
                .reduce((a, b) => a + b.amount, 0)
            ),
            alignment: "right",
          },
          {
            text: numberToMadPdf(
              ex
                .filter(
                  (ef) => ef.paymentTermId === 3 || ef.paymentTermId === 4
                )
                .reduce((a, b) => a + b.amount, 0)
            ),
            alignment: "right",
          },
          {
            text: numberToMadPdf(
              ex
                .filter((ef) => ef.paymentTermId === 5)
                .reduce((a, b) => a + b.amount, 0)
            ),
            alignment: "right",
          },
          {
            text: numberToMadPdf(
              ex
                .filter(
                  (ef) => ef.paymentTermId === 1 || ef.paymentTermId === 6
                )
                .reduce((a, b) => a + b.amount, 0)
            ),
            alignment: "right",
          },
          {
            text: numberToMadPdf(ex.reduce((a, b) => a + b.amount, 0)),
            alignment: "right",
          },
        ]);
      });

      content.push([
        {
          text: "Total",
          color: highlitedColor,
          alignment: "right",
        },
        {
          text: numberToMadPdf(
            e
              .filter((ef) => ef.paymentTermId === 2)
              .reduce((a, b) => a + b.amount, 0)
          ),
          alignment: "right",
        },
        {
          text: numberToMadPdf(
            e
              .filter((ef) => ef.paymentTermId === 3 || ef.paymentTermId === 4)
              .reduce((a, b) => a + b.amount, 0)
          ),
          alignment: "right",
        },
        {
          text: numberToMadPdf(
            e
              .filter((ef) => ef.paymentTermId === 5)
              .reduce((a, b) => a + b.amount, 0)
          ),
          alignment: "right",
        },
        {
          text: numberToMadPdf(
            e
              .filter((ef) => ef.paymentTermId === 1 || ef.paymentTermId === 6)
              .reduce((a, b) => a + b.amount, 0)
          ),
          alignment: "right",
        },
        {
          text: numberToMadPdf(e.reduce((a, b) => a + b.amount, 0)),
          alignment: "right",
        },
      ]);
    }
    return content;
  };

  const content = [
    pdfHeader(institution?.logo ?? "", [200, 140], "Rapport comptable"),
    { ...space },
    {
      text: `Période ${dateToString(periodReporting.fromDate)} - ${dateToString(
        periodReporting.toDate
      )}`,
      ...header,
      alignment: "center",
    },
    { ...space },
    {
      text: `Produits ${numberToMadPdf(
        periodReporting.payments.reduce((a, b) => a + b.totalAmount, 0)
      )}`,
      ...title,
    },
    {
      ...table,
      table: {
        headerRows: 1,
        widths: ["*", "auto", "auto", "auto", "auto", "*"],
        body: [
          [
            "Origine",
            "Espèces",
            "Banque",
            "Chèque",
            "Autre",
            { text: "Total", alignment: "right" },
          ],
          ...getPaymentsTermDetails(periodReporting.payments),
        ],
      },
      layout: "lightHorizontalLines",
    },
    {
      ...table,
      table: {
        headerRows: 1,
        widths: [
          "*",
          ...getPaymentsServicesHeaders(periodReporting.payments).map(
            (s) => "auto"
          ),
          "*",
        ],
        body: [
          [
            "Origine",
            ...getPaymentsServicesHeaders(periodReporting.payments),
            { text: "Total", alignment: "right" },
          ],
          ...getPaymentsServicesContent(periodReporting.payments),
        ],
      },
      layout: "lightHorizontalLines",
    },
    { ...space },
    {
      text: `Charges ${numberToMadPdf(
        periodReporting.expenses.reduce((a, b) => a + b.amount, 0)
      )}`,
      ...title,
    },
    {
      ...table,
      table: {
        headerRows: 1,
        widths: ["*", "auto", "auto", "auto", "auto", "*"],
        body: [
          [
            "Origine",
            "Espèces",
            "Banque",
            "Chèque",
            "Autre",
            { text: "Total", alignment: "right" },
          ],
          ...getExpensesContent(periodReporting.expenses),
        ],
      },
      layout: "lightHorizontalLines",
    },
    { ...space },
    pdfFooter(institution),
  ];

  var docDefinition = {
    pageSize: "A4",
    pageOrientation: "Portrait",
    defaultStyle: {
      fontSize: 10,
    },
    content: content,
  };

  return (
    <div>
      {isMobile || isTablet ? (
        <DefaultButton
          text="PDF"
          onClick={() => pdfMake.createPdf(docDefinition).open()}
          iconProps={{ iconName: "PDF" }}
        />
      ) : (
        <DefaultButton
          split
          text="PDF"
          menuProps={{
            items: [
              {
                key: "download",
                text: "Télécharger",
                iconProps: { iconName: "DownloadDocument" },
                onClick: () =>
                  pdfMake
                    .createPdf(docDefinition)
                    .download(`Period reporitng.pdf`),
              },
              {
                key: "print",
                text: "Imprimer",
                iconProps: { iconName: "Print" },
                onClick: () => pdfMake.createPdf(docDefinition).print(),
              },
            ],
          }}
          onClick={() => pdfMake.createPdf(docDefinition).open()}
          iconProps={{ iconName: "PDF" }}
        />
      )}
    </div>
  );
}
