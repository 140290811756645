import React, { useContext, useState } from "react";
import {
  CircularProgress,
  Grid,
  GridSize,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { ExpenseApi, ExpenseDto } from "../../api-clients/api";
import { IDropdownOption, TextField } from "@fluentui/react";
import FluentUiDatePicker from "../Shared/FluentUiDatePicker";
import { getApiUrl } from "../../api-clients";
import { AppContext } from "../../contexts/AppContext";
import ExpenseFurnishersDropdown from "../Shared/ExpenseFurnishersDropdown";
import { SchoolContext } from "../../contexts/SchoolContext";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";
import PaymentTermDropdown from "../Shared/PaymentTermDropdown";
import FormButtons from "../Shared/FormButtons";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  wrapper: {
    position: "relative",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

interface IProps {
  expense?: ExpenseDto | null;
  md: GridSize;
  sm: GridSize;
  showDelete: boolean;
}

CRUDExpense.defaultProps = {
  showDelete: false,
};

export default function CRUDExpense({ expense, md, sm, showDelete }: IProps) {
  const classes = useStyles();
  const defaultExpenseValue: ExpenseDto = {
    date: new Date(),
    amount: 0,
    comment: "",
    paymentTermId: 2,
  } as ExpenseDto;
  const { setExpenseUpdated } = useContext(SchoolContext);
  const { openSnackbar } = useContext(AppContext);
  const [checkDelete, setCheckDelete] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [expenseApi] = useState<ExpenseApi>(new ExpenseApi(getApiUrl()));
  const [newExpense, setNewExpense] = useState<ExpenseDto>(
    expense ? expense : defaultExpenseValue
  );

  const canSave: boolean =
    (newExpense.furnisherId?.length ?? 0) > 0 &&
    (newExpense.amount ?? 0) > 0 &&
    newExpense.date != null;

  const deleteExpense = () => {
    if (expense && expense.expenseId) {
      setDeleting(true);
      expenseApi
        .expense(expense.expenseId)
        .then((res) => {
          openSnackbar("Dépense supprimée", "success");
          setDeleting(false);
          setNewExpense(defaultExpenseValue);
          setExpenseUpdated(true);
        })
        .catch((err) => {
          setDeleting(false);
          openSnackbar("Une erreur s'est reproduite", "error");
        });
    }
  };

  const saveExpense = () => {
    setSaving(true);
    expenseApi
      .createOrUpdate(newExpense)
      .then((res) => {
        openSnackbar("Dépense bien enregistrée", "success");
        setSaving(false);
        setNewExpense(defaultExpenseValue);
        setExpenseUpdated(true);
      })
      .catch((err) => {
        setSaving(false);
        openSnackbar("Une erreur s'est reproduite", "error");
      });
  };

  return (
    <div>
      <div className={classes.root}>
        <Grid container spacing={1}>
          <Grid item md={md} sm={sm}>
            <FluentUiDatePicker
              required
              label="Date"
              placeholder="Date"
              value={newExpense.date}
              onSelectDate={(date: Date | null | undefined): void => {
                setNewExpense({
                  ...newExpense,
                  date: date,
                } as ExpenseDto);
              }}
            />
          </Grid>
          <Grid item md={md} sm={sm}>
            <ExpenseFurnishersDropdown
              required
              label="Origine dépense"
              placeholder="Selectionner une raison"
              selectedKey={newExpense.furnisherId}
              onChange={(
                event: React.FormEvent<HTMLDivElement>,
                item?: IDropdownOption
              ): void => {
                if (item)
                  setNewExpense({
                    ...newExpense,
                    furnisherId: item.key,
                  } as ExpenseDto);
              }}
            />
          </Grid>
          <Grid item md={md} sm={sm}>
            <TextField
              autoComplete="off"
              required
              label="Montant (dirham)"
              placeholder="Montant (dirham)"
              value={newExpense.amount?.toString()}
              type="number"
              lang="fr-FR"
              min="0"
              onChange={(
                event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                newValue?: string
              ) => {
                if (!isNaN(Number(newValue))) {
                  let num: number = Number(newValue);
                  if (num < 0) num = num * -1;
                  setNewExpense({
                    ...newExpense,
                    amount: Number(num.toFixed(2)),
                  } as ExpenseDto);
                }
              }}
            />
          </Grid>
          <Grid item md={md} sm={sm}>
            <PaymentTermDropdown
              required
              label="Mode de payement"
              placeholder="Selectionner un mode de payement"
              selectedKey={newExpense.paymentTermId}
              onChange={(
                event: React.FormEvent<HTMLDivElement>,
                item?: IDropdownOption
              ): void => {
                if (item != null)
                  setNewExpense({
                    ...newExpense,
                    paymentTermId: item.key,
                  } as ExpenseDto);
              }}
            />
          </Grid>
          <Grid item md={md} sm={sm}>
            <TextField
              autoComplete="off"
              label="Commentaire"
              placeholder="Commentaire"
              value={newExpense.comment}
              onChange={(
                event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                newValue?: string
              ) =>
                setNewExpense({
                  ...newExpense,
                  comment: newValue,
                } as ExpenseDto)
              }
            />
          </Grid>
        </Grid>
      </div>
      <Grid container spacing={1} justify="flex-start">
        {checkDelete ? (
          <>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                Confirmer la suppression ?
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                aria-label="Supprimer"
                disabled={deleting || saving}
                onClick={() => setCheckDelete(false)}
                color="inherit"
              >
                <ClearIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                aria-label="Supprimer"
                disabled={deleting || saving}
                onClick={deleteExpense}
                color="secondary"
              >
                <CheckIcon />
              </IconButton>
              {deleting && (
                <CircularProgress
                  size={24}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: -12,
                    marginLeft: -12,
                  }}
                />
              )}
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12}>
              <div className={classes.wrapper}>
                <FormButtons
                  onConfirmClick={saveExpense}
                  onDeleteClick={() => setCheckDelete(true)}
                  disabled={deleting || saving || !canSave}
                  cancelButtonVisible={false}
                  deleteButtonVisible={
                    expense != null && expense.expenseId != null
                  }
                  confirmIcon={
                    expense != null && expense.expenseId != null
                      ? "Save"
                      : "Add"
                  }
                  confirmText={
                    expense != null && expense.expenseId != null
                      ? "Enregistrer"
                      : "Ajouter"
                  }
                />

                {saving && (
                  <CircularProgress
                    size={24}
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: -12,
                      marginLeft: -12,
                    }}
                  />
                )}
              </div>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
}
