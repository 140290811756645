import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { getApiUrl } from "../api-clients";
import {
  SchoolApi,
  AccademicYearDto,
  EducationDto,
  FurnisherDto,
  ExpenseApi,
  ServiceDto,
  ServiceApi,
  ServiceType,
} from "../api-clients/api";
import { AuthContext } from "../contexts/AuthContext";
import { InstitutionContext } from "../contexts/InstitutionContext";
import { SchoolContext } from "../contexts/SchoolContext";

export const SchoolProvider = ({ children }: PropsWithChildren<unknown>) => {
  const { isAuthenticated } = useContext(AuthContext);
  const { institution, institutions } = useContext(InstitutionContext);
  const [expenseUpdated, setExpenseUpdated] = useState<boolean>(false);
  const [paymentUpdated, setPaymentUpdated] = useState<boolean>(false);
  const [studentUpdated, setStudentUpdated] = useState<boolean>(false);
  const [parentUpdated, setParentUpdated] = useState<boolean>(false);
  const [accademicYearLabel, setAccademicYearLabel] = useState<string>("");
  const [transportService, setTransportService] =
    useState<ServiceDto | null>(null);
  const [childCareService, setChildCareService] =
    useState<ServiceDto | null>(null);
  const [canteenService, setCanteenService] = useState<ServiceDto | null>(null);
  const [educations, setEducations] = useState<EducationDto[]>([]);
  const [expenseFurnishers, setExpenseFurnishers] = useState<FurnisherDto[]>(
    []
  );
  const [accademicYear, setAccademicYear] =
    useState<AccademicYearDto | null>(null);
  const [accademicYears, setAccademicYears] =
    useState<AccademicYearDto[] | null>(null);
  const [schoolApi] = useState<SchoolApi>(new SchoolApi(getApiUrl()));
  const [expenseApi] = useState<ExpenseApi>(new ExpenseApi(getApiUrl()));
  const [serviceApi] = useState<ServiceApi>(new ServiceApi(getApiUrl()));

  useEffect(() => {
    if (accademicYear != null)
      setAccademicYearLabel(
        `${accademicYear.startDate?.getFullYear()}/${accademicYear.endDate?.getFullYear()}`
      );
    else setAccademicYearLabel("");
  }, [accademicYear]);

  useEffect(() => {
    if (isAuthenticated && institution != null) {
      schoolApi.educations().then((res) => setEducations(res));

      schoolApi
        .accademicYears()
        .then((res) => {
          setAccademicYears(res);
          if (res && res.length > 0 && res[0].accademicYearId) {
            const accademicYearId = localStorage.getItem("accademicYearId");

            if (
              accademicYearId != null &&
              res.filter((r) => r.accademicYearId === accademicYearId).length >
                0
            ) {
              setAccademicYear(
                res.filter((r) => r.accademicYearId === accademicYearId)[0]
              );
            } else {
              setAccademicYear(res[0]);
              localStorage.setItem("accademicYearId", res[0].accademicYearId);
            }
          } else {
            localStorage.removeItem("accademicYearId");
          }
        })
        .catch((err) => {
          console.log(err);
        });

      expenseApi.allFurnisher(true).then((res) => setExpenseFurnishers(res));

      serviceApi.service().then((res) => {
        if (res != null) {
          const transport: ServiceDto[] = res.filter(
            (s) => s.enum === ServiceType._1
          );
          const childCare: ServiceDto[] = res.filter(
            (s) => s.enum === ServiceType._2
          );
          const canteenCare: ServiceDto[] = res.filter(
            (s) => s.enum === ServiceType._3
          );
          if (transport != null && transport.length === 1) {
            setTransportService(transport[0]);
          }
          if (childCare != null && childCare.length === 1) {
            setChildCareService(childCare[0]);
          }
          if (canteenCare != null && canteenCare.length === 1) {
            setCanteenService(canteenCare[0]);
          }
        }
      });
    }
  }, [
    schoolApi,
    expenseApi,
    isAuthenticated,
    serviceApi,
    institution,
    institutions,
  ]);

  return (
    <SchoolContext.Provider
      value={{
        accademicYear,
        accademicYearLabel,
        setAccademicYear,
        accademicYears,
        transportService,
        childCareService,
        canteenService,
        setAccademicYears,
        educations,
        expenseFurnishers,
        expenseUpdated,
        setExpenseUpdated,
        paymentUpdated,
        setPaymentUpdated,
        studentUpdated,
        setStudentUpdated,
        parentUpdated,
        setParentUpdated,
      }}
    >
      {children}
    </SchoolContext.Provider>
  );
};
