import { createContext } from "react";

export interface AuthState<T> {
    isAuthenticated: T;
    isAuthenticating: T;
    user: any | undefined;
    login: Function;
}

export const AuthContext = createContext<AuthState<true | false>>({
    isAuthenticated: false,
    isAuthenticating: true,
    user: undefined,
    login: () => {}
});