import React, { useState, useEffect, useContext } from "react";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TablePagination,
  Typography,
} from "@material-ui/core";
import {
  PaymentFilterDto,
  PaymentApi,
  PaymentDtoAmountPaginatedDto,
  SortOrderType,
} from "../../api-clients/api";
import { getApiUrl } from "../../api-clients";
import Loading from "../Shared/Loading";
import NoResult from "../Shared/NoResult";
import { dateToString, numberToMad } from "../../helper/index";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { SchoolContext } from "../../contexts/SchoolContext";
import { useHistory } from "react-router-dom";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import { ActionButton, IDropdownOption, SearchBox } from "@fluentui/react";
import { InstitutionContext } from "../../contexts/InstitutionContext";
import OrderByDropdown from "../Shared/OrderByDropdown";
import GetAppIcon from "@material-ui/icons/GetApp";
import AllPaymentPdf from "./AllPaymentPdf";
var FileSaver = require("file-saver");

initializeIcons();

export default function CardPayments() {
  const { institution } = useContext(InstitutionContext);
  const { accademicYear, paymentUpdated, setPaymentUpdated } =
    useContext(SchoolContext);
  const history = useHistory();
  const [paymentApi] = useState<PaymentApi>(new PaymentApi(getApiUrl()));
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [result, setResult] =
    useState<PaymentDtoAmountPaginatedDto | null>(null);
  const [filter, setFilter] = useState<PaymentFilterDto>({
    institutionId: institution?.institutionId,
    pageSize: 7,
    accademicYearId: accademicYear?.accademicYearId,
    sortField: "Date",
    sortOrder: SortOrderType._1,
  } as PaymentFilterDto);

  useEffect(() => {
    setPaymentUpdated(false);
    setIsLoading(true);
    paymentApi
      .filter(filter)
      .then((res) => {
        setResult(res);
        setIsLoading(false);
      })
      .catch((err) => setIsLoading(false));
  }, [paymentApi, filter, paymentUpdated, setPaymentUpdated]);

  return (
    <Card>
      <CardHeader title="Paiements" color="#D44C42" />
      <CardContent>
        <Grid container spacing={1}>
          <Grid item sm={12} xs={12}>
            <SearchBox
              placeholder="Recherche"
              iconProps={{ iconName: "Search" }}
              onClear={() =>
                setFilter({
                  ...filter,
                  filter: "",
                } as PaymentFilterDto)
              }
              onSearch={(newValue: string) =>
                setFilter({
                  ...filter,
                  filter: newValue,
                } as PaymentFilterDto)
              }
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <OrderByDropdown
              onChange={(
                event: React.FormEvent<HTMLDivElement>,
                option?: IDropdownOption,
                index?: number
              ) => {
                if (option) {
                  if (option.key === 1) {
                    setFilter({
                      ...filter,
                      sortField: "Date",
                      sortOrder: SortOrderType._1,
                    } as PaymentFilterDto);
                  } else if (option.key === 2) {
                    setFilter({
                      ...filter,
                      sortField: "Date",
                      sortOrder: SortOrderType._0,
                    } as PaymentFilterDto);
                  } else if (option.key === 3) {
                    setFilter({
                      ...filter,
                      sortField: "CreationDate",
                      sortOrder: SortOrderType._1,
                    } as PaymentFilterDto);
                  }
                }
              }}
              selectedKey={
                filter.sortField === "CreationDate"
                  ? 3
                  : filter.sortField === "Date" &&
                    filter.sortOrder === SortOrderType._0
                  ? 2
                  : 1
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} style={{ textAlign: "right" }}>
            {!isLoading && result != null && (
              <Typography variant="subtitle1" color="primary">
                Total {numberToMad(result.totalAmount)}
              </Typography>
            )}
          </Grid>
        </Grid>
        {!isLoading ? (
          result != null && result.data != null && result.data.length > 0 ? (
            <>
              <List>
                {result.data.map((s) => (
                  <div key={s.paymentId}>
                    <ListItem
                      button
                      alignItems="flex-start"
                      onClick={() => history.push("payment/" + s.paymentId)}
                      style={{ paddingTop: "0px", paddingBottom: "0px" }}
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <AttachMoneyIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={numberToMad(s.totalAmount)}
                        secondary={
                          <>
                            <Typography
                              component="span"
                              variant="body2"
                              color="textPrimary"
                            >
                              {dateToString(s.date)}
                              {" — Payé par : "}
                              {s.paidBy}
                            </Typography>
                            <br></br>
                            {(s.studentPayments?.length ?? 0) > 1
                              ? "Élèves : "
                              : "Élève : "}
                            {s.studentPayments &&
                              s.studentPayments.map(
                                (sp, index) =>
                                  `${sp.studentFirstName} ${
                                    sp.studentLastName
                                  }${
                                    index ===
                                    (s.studentPayments?.length ?? 0) - 1
                                      ? ""
                                      : "; "
                                  }`
                              )}
                            <br></br>
                            {s.comment}
                          </>
                        }
                      />
                    </ListItem>
                    <Divider />
                  </div>
                ))}
              </List>
              <TablePagination
                component="div"
                count={result.totalElements ?? 0}
                page={filter.pageIndex ?? 0}
                rowsPerPage={filter.pageSize ?? 0}
                rowsPerPageOptions={[7]}
                onPageChange={(event, newPage) => {
                  setFilter({
                    ...filter,
                    pageIndex: newPage,
                  } as PaymentFilterDto);
                }}
                onRowsPerPageChange={(event) => {
                  setFilter({
                    ...filter,
                    pageIndex: 0,
                    pageSize: +event.target.value,
                  } as PaymentFilterDto);
                }}
              />
            </>
          ) : (
            <NoResult />
          )
        ) : (
          <Loading />
        )}
      </CardContent>
      {result != null && result.data != null && result.data.length > 0 && (
        <CardActions>
          <Button
            color="primary"
            size="small"
            startIcon={<GetAppIcon />}
            onClick={() => {
              paymentApi
                .csv({
                  ...filter,
                  pageIndex: 0,
                  pageSize: 2147483640,
                } as PaymentFilterDto)
                .then((res) => {
                  FileSaver.saveAs(res.data, "Paiements.csv");
                });
            }}
          >
            Télécharger
          </Button>
          <AllPaymentPdf />
        </CardActions>
      )}
    </Card>
  );
}
