import React, { useContext } from "react";
import { SchoolContext } from "./contexts/SchoolContext";
import {
  Route,
  Switch,
} from "react-router-dom";
import Layout from "./containers/Layout/Layout";
import MobileLayout from "./containers/Layout/MobileLayout";
import Loading from "./components/Shared/Loading";
import { isMobile } from "react-device-detect";

export default function AppContent() {
  const { accademicYears, accademicYear } = useContext(SchoolContext);

  return (
    <div>
      {accademicYears != null &&
      accademicYears.length > 0 &&
      accademicYear != null &&
      accademicYear.accademicYearId != null &&
      accademicYear.accademicYearId.length > 0 ? (
        <Switch>
          <Route path="/">
            {/* <Redirect from="*" to="/dashboard" /> */}
            {isMobile ? <MobileLayout /> : <Layout />}
          </Route>
        </Switch>
      ) : (
        <Loading />
      )}
    </div>
  );
}
