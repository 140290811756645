import {
  IBasePickerSuggestionsProps,
  initializeIcons,
  IPersonaProps,
  NormalPeoplePicker,
} from "@fluentui/react";
import React, { useContext, useState } from "react";
import { getApiUrl } from "../../api-clients";
import { StudentApi, StudentFilterDto } from "../../api-clients/api";
import { SchoolContext } from "../../contexts/SchoolContext";
import DeleteButtonCallout from "./DeleteButtonCallout";

const { v4: uuidv4 } = require("uuid");

initializeIcons();

interface IProps {
  onStudentSelected: Function;
  selectedStudents: IPersonaProps[];
  onRemoveStudent: (studentPathId: string) => void;
}

export default function StudentPathPicker({
  onStudentSelected,
  selectedStudents,
  onRemoveStudent,
}: IProps) {
  const [uniqueId] = useState<string>(uuidv4());
  const { accademicYear } = useContext(SchoolContext);
  const [studentToRemove, setStudentToRemove] = useState<IPersonaProps | null>(
    null
  );
  const [studentApi] = useState<StudentApi>(new StudentApi(getApiUrl()));
  const [filter] = useState<StudentFilterDto>({
    accademicYearId: accademicYear?.accademicYearId,
    pageSize: 20,
  } as StudentFilterDto);

  const suggestionProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: "Élèves suggérés",
    mostRecentlyUsedHeaderText: "Élèves suggérés",
    noResultsFoundText: "Aucun résultat",
    loadingText: "Chargement",
    showRemoveButtons: false,
    suggestionsAvailableAlertText:
      "Suggestions de sélection d'étudiants disponibles",
    suggestionsContainerAriaLabel: "Élèves suggérés",
  };

  const onFilterChanged = (
    input: string,
    selectedItems?: IPersonaProps[] | undefined
  ): IPersonaProps[] | PromiseLike<IPersonaProps[]> => {
    return studentApi
      .filter({
        ...filter,
        filter: input,
        ignoreStudentPathIds: selectedItems?.map((s) => s.id),
      } as StudentFilterDto)
      .then((res) => {
        if (res && res.data && res.data.length > 0) {
          return res.data.map((s) => {
            return {
              id: s.studentPathId,
              text: `${s.firstName} ${s.lastName}`,
              secondaryText: s.classroomName,
            } as IPersonaProps;
          });
        } else return [];
      });
  };

  const onItemSelected = (
    selectedItem?: IPersonaProps
  ): IPersonaProps | PromiseLike<IPersonaProps> | null => {
    if (selectedItem) onStudentSelected(selectedItem);
    return null;
  };

  return (
    <div>
      <div>
        Élèves
        <NormalPeoplePicker
          className={uniqueId}
          onResolveSuggestions={onFilterChanged}
          resolveDelay={300}
          pickerSuggestionsProps={suggestionProps}
          selectedItems={selectedStudents}
          onItemSelected={onItemSelected}
          disabled={studentToRemove != null}
          onChange={(items) => {
            // if (items) {
            //   let selectedIds = selectedStudents.map((ss) => ss.id);
            //   let changedIds = items.map((ss) => ss.id);
            //   let removedId = selectedIds.filter(
            //     (ss) => !changedIds.includes(ss)
            //   )[0];
            //   let toRemove = selectedStudents.filter(
            //     (ss) => ss.id === removedId
            //   )[0];
            //   setStudentToRemove(toRemove);
            // }
          }}
        />
      </div>
      <DeleteButtonCallout
        uniqueId={uniqueId}
        isCalloutVisible={studentToRemove != null}
        setIsCalloutVisible={(isVisible) => {
          if (!isVisible) setStudentToRemove(null);
        }}
        confirmAction={() => {
          onRemoveStudent(studentToRemove?.id ?? "");
          setStudentToRemove(null);
        }}
        title="Retirer de la liste"
        content="Si vous retirez cet element de la liste vous supprimez les montants de paiement liés à cet élève."
        titleRemoveButton="Retirer"
        />
    </div>
  );
}
