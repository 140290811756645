import { createContext } from "react";
import {
  AccademicYearDto,
  EducationDto,
  FurnisherDto,
  ServiceDto,
} from "../api-clients/api";

export interface SchoolState {
  accademicYear: AccademicYearDto | null;
  setAccademicYear: Function;
  accademicYears: AccademicYearDto[] | null;
  accademicYearLabel: string;
  setAccademicYears: Function;
  transportService: ServiceDto | null;
  childCareService: ServiceDto | null;
  canteenService: ServiceDto | null;
  educations: EducationDto[];
  expenseFurnishers: FurnisherDto[];
  expenseUpdated: boolean;
  setExpenseUpdated: Function;
  paymentUpdated: boolean;
  setPaymentUpdated: Function;
  studentUpdated: boolean;
  setStudentUpdated: Function;
  parentUpdated: boolean;
  setParentUpdated: Function;
}

export const SchoolContext = createContext<SchoolState>({
  accademicYear: null,
  setAccademicYear: () => {},
  accademicYears: null,
  accademicYearLabel: "",
  transportService: null,
  childCareService: null,
  canteenService: null,
  setAccademicYears: () => {},
  educations: [],
  expenseFurnishers: [],
  expenseUpdated: false,
  setExpenseUpdated: () => {},
  paymentUpdated: false,
  setPaymentUpdated: () => {},
  studentUpdated: false,
  setStudentUpdated: () => {},
  parentUpdated: false,
  setParentUpdated: () => {}
});
