import { createMuiTheme } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

const appTheme = createMuiTheme({
  // palette: {
  //   primary: {
  //     main: process.env.REACT_APP_PRIMARY_COLOR_MAIN as string,
  //     light: process.env.REACT_APP_PRIMARY_COLOR_LIGHT as string,
  //     dark: process.env.REACT_APP_PRIMARY_COLOR_DARK as string,
  //   },
  //   secondary: {
  //     main: "#FFFFFF",
  //     light: "#FFFFFF",
  //     dark: "#FFFFFF",
  //   },
  // },
  overrides: {
    MuiContainer: {
      root: {
        paddingTop: "32px",
        paddingBottom: "32px",
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: "rgba(255,255,255,0.9)",
      },
      rounded: {
        borderRadius: "0px",
      }
    },
    MuiAvatar: {
      colorDefault: {
        backgroundColor: grey[600],
      },
    },
    // MuiListItem: {
    //   root: {
    //     "&$selected": {
    //       backgroundColor: "white",
    //     },
    //   },

    // button: {
    //   "&:hover": {
    //     backgroundColor: process.env.REACT_APP_PRIMARY_COLOR_LIGHT,
    //     color: "white"
    //   },
    // },
    // },
  },
});

export default appTheme;
