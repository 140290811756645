import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import { PaymentApi, PublicPaymentResultDto } from "../../api-clients/api";
import { getApiUrl } from "../../api-clients";
import Loading from "../Shared/Loading";
import NoResult from "../Shared/NoResult";
import LabelFieldValue from "../Shared/LabelFieldValue";
import {
  numberToMad,
  dateToString,
  paymentTermTypeToString,
} from "../../helper/index";
import PaymentPdf from "./PaymentPdf";

export default function PublicPayment() {
  const { paymentId }: any = useParams();
  const [paymentApi] = useState<PaymentApi>(new PaymentApi(getApiUrl()));
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [result, setResult] = useState<PublicPaymentResultDto | null>(null);

  useEffect(() => {
    paymentApi
      .publicById(paymentId)
      .then((res) => {
        setResult(res);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [paymentApi, paymentId]);

  return (
    <Container>
      {!isLoading ? (
        result &&
        result.payment &&
        result.institution &&
        result.accademicYear ? (
          <>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item md={4} sm={6} xs={12}>
                    <img
                      src={`data:image/jpeg;base64,${result.institution?.logo}`}
                      alt="logo"
                      style={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item md={8} sm={6} xs={12} style={{ textAlign: "end" }}>
                    {result.institution &&
                      result.institution.printReceiptNumber && (
                        <Typography
                          variant="subtitle1"
                          color="primary"
                          gutterBottom
                        >
                          {`Reçu ${result.payment.paymentNumber}`}
                        </Typography>
                      )}
                    <Typography variant="subtitle2" gutterBottom>
                      {`Encodé le ${dateToString(result.payment.creationDate)}`}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
              <CardContent>
                <Grid container direction="row" alignItems="center" spacing={3}>
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <LabelFieldValue
                      label="Total payé"
                      value={numberToMad(result.payment.totalAmount)}
                      variant="xxLargePlus"
                    />
                    <LabelFieldValue
                      label="Detail"
                      value={`Payé le ${dateToString(
                        result.payment.date
                      )}, en ${paymentTermTypeToString(
                        result.payment.paymentTerm
                      )} par ${result.payment.paidBy}`}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <PaymentPdf
                      paymentId={paymentId}
                      accademicYear={result.accademicYear}
                      payment={result.payment}
                      institution={result.institution}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </>
        ) : (
          <NoResult />
        )
      ) : (
        <Loading />
      )}
    </Container>
  );
}
