import React from "react";
import Dashboard from "./components/Dashboard/Dashboard";
import Students from "./components/Students/Students";
import Student from "./components/Students/Student";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";
import Expenses from "./components/Expenses/Expenses";
import Payments from "./components/Payments/Payments";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import CardPayment from "./components/Payments/CardPayment";
import SchoolIcon from "@material-ui/icons/School";
import Classroom from "./components/Educations/Classroom";
import Parents from "./components/Parents/Parents";
import Parent from "./components/Parents/Parent";
import Education from "./components/Educations/Education";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SettingsIcon from "@material-ui/icons/Settings";
import { faUserGraduate } from "@fortawesome/free-solid-svg-icons";
import Settings from "./components/Settings/Settings";
import familyIcon from "./assets/images/icons/family-white.png";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import Statistics from "./components/Statistics/Statistics";

interface RoutingReference {
  path: string;
  name: string;
  icon: any;
  hideInMenu?: boolean;
  component?: any;
  children?: RoutingChild[];
}

interface RoutingChild {
  path: string;
  hideInMenu?: boolean;
  name: string;
  initials: string;
  component: any;
}

const appRoutes: RoutingReference[] = [
  {
    name: "Dashboard",
    path: "/dashboard",
    icon: <DashboardIcon />,
    component: <Dashboard />,
  },
  // {
  //   name: "Enseignements",
  //   path: "/educations",
  //   icon: <SchoolIcon />,
  //   component: <Educations />,
  // },
  {
    name: "Enseignement",
    path: "/education/:educationId",
    icon: <SchoolIcon />,
    component: <Education />,
    hideInMenu: true,
  },
  {
    name: "Niveau",
    path: "/classroom/:classroomId",
    icon: <SchoolIcon />,
    component: <Classroom />,
    hideInMenu: true,
  },
  {
    name: "Élèves",
    path: "/students",
    icon: <FontAwesomeIcon icon={faUserGraduate} size="lg" />,
    component: <Students />,
  },
  {
    name: "Parents",
    path: "/parents",
    icon: (
      <img
        src={familyIcon}
        style={{ height: "24px", color: "white" }}
        alt="family icon"
      />
    ),
    component: <Parents />,
  },
  {
    name: "Parent",
    path: "/parent/:parentId",
    icon: (
      <img
        src={familyIcon}
        style={{ height: "24px", color: "white" }}
        alt="family icon"
      />
    ),
    component: <Parent />,
    hideInMenu: true,
  },
  {
    name: "Élève",
    path: "/student/:studentId",
    icon: <PeopleIcon />,
    component: <Student />,
    hideInMenu: true,
  },
  {
    name: "Paiements",
    path: "/payments",
    icon: <AttachMoneyIcon />,
    component: <Payments />,
  },
  {
    name: "Paiement",
    path: "/payment/:paymentId",
    component: <CardPayment />,
    icon: <AttachMoneyIcon />,
    hideInMenu: true,
  },
  {
    name: "Dépenses",
    path: "/expenses",
    icon: <MoneyOffIcon />,
    component: <Expenses />,
  },
  {
    name: "Statistique",
    path: "/statistics",
    icon: <EqualizerIcon />,
    component: <Statistics />,
  },
  {
    name: "Configuration",
    path: "/settings",
    icon: <SettingsIcon />,
    component: <Settings />,
  },
];

export default appRoutes;
