import React from "react";
import {
  Callout,
  getTheme,
  FontWeights,
  mergeStyleSets,
  Text,
  initializeIcons,
  Stack,
} from "@fluentui/react";
import { Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

// Initialize icons in case this example uses them
initializeIcons();

const theme = getTheme();
const styles = mergeStyleSets({
  buttonArea: {
    minWidth: 130,
    height: 32,
  },
  callout: {
    maxWidth: 300,
  },
  header: {
    padding: "18px 24px 12px",
  },
  title: [
    theme.fonts.xLarge,
    {
      margin: 0,
      fontWeight: FontWeights.semilight,
    },
  ],
  inner: {
    height: "100%",
    padding: "0 24px 20px",
  },
  actions: {
    position: "relative",
    marginTop: 20,
    width: "100%",
    whiteSpace: "nowrap",
  },
  subtext: [
    theme.fonts.small,
    {
      margin: 0,
      fontWeight: FontWeights.semilight,
    },
  ],
  link: [
    theme.fonts.medium,
    {
      color: theme.palette.neutralPrimary,
    },
  ],
});

interface IProps {
  uniqueId: string;
  title: string;
  content: string;
  titleRemoveButton: string;
  isCalloutVisible: boolean;
  confirmAction: () => void;
  setIsCalloutVisible: (isVisible: boolean) => void;
}
export default function DeleteButtonCallout({
  uniqueId,
  title,
  content,
  titleRemoveButton,
  isCalloutVisible,
  setIsCalloutVisible,
  confirmAction,
}: IProps) {
  return (
    <div>
      {isCalloutVisible && (
        <Callout
          className={styles.callout}
          role="alertdialog"
          gapSpace={0}
          target={"#" + uniqueId}
          onDismiss={() => setIsCalloutVisible(false)}
          setInitialFocus
        >
          <div className={styles.header}>
            <Text className={styles.title}>{title}</Text>
          </div>
          <div className={styles.inner}>
            <Text className={styles.subtext}>
              {content}
            </Text>
            <div className={styles.actions}>
              <Stack horizontal>
                <Button
                  variant="contained"
                  color="default"
                  startIcon={<CloseIcon />}
                  size="small"
                  onClick={() => setIsCalloutVisible(false)}
                  disableElevation
                >
                  Annuler
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<HighlightOffIcon />}
                  onClick={() => confirmAction()}
                  size="small"
                  disableElevation
                >
                  {titleRemoveButton}
                </Button>
              </Stack>
            </div>
          </div>
        </Callout>
      )}
    </div>
  );
}
