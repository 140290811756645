import React, { useContext, useState } from "react";
import { Dropdown, IDropdownOption } from "@fluentui/react";
import { SchoolContext } from "../../contexts/SchoolContext";

interface IProps {
  onChange: (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    index?: number
  ) => void;
  multiSelect: boolean;
  selectedKeys: string[] | number[] | null;
  selectedKey?: string | number | string[] | number[] | null;
  label?: string;
  placeholder?: string;
  required: boolean;
}
export default function YearsDropdown(props: IProps) {
  const { accademicYears } = useContext(SchoolContext);
  const [options] = useState<any[]>(
    accademicYears
      ? [
          {
            key:
              (accademicYears[
                accademicYears.length - 1
              ].startDate?.getFullYear() ?? 0) + 1,
            text:
              (accademicYears[
                accademicYears.length - 1
              ].startDate?.getFullYear() ?? 0) + 1,
          },
          ...accademicYears.map((e) => {
            return {
              key: e.startDate?.getFullYear() ?? 0,
              text: e.startDate?.getFullYear() ?? 0,
            };
          }),
          {
            key:
              (accademicYears[
                accademicYears.length - 1
              ].startDate?.getFullYear() ?? 0) - 1,
            text:
              (accademicYears[
                accademicYears.length - 1
              ].startDate?.getFullYear() ?? 0) - 1,
          },
        ]
      : []
  );

  return (
    <>
      {(accademicYears?.length ?? 0) > 0 && (
        <Dropdown {...props} options={options} style={{ width: "100%" }} />
      )}
    </>
  );
}

YearsDropdown.defaultProps = {
  multiSelect: false,
  selectedKeys: null,
  selectedKey: null,
  label: null,
  placeholder: null,
  required: false,
};
