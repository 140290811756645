import React, { useState } from "react";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { StudentPathDto } from "../../api-clients/api";
import Loading from "../Shared/Loading";
import NoResult from "../Shared/NoResult";
import LabelFieldValue from "../Shared/LabelFieldValue";
import { dateToString, numberToMad } from "../../helper";
import { green } from "@material-ui/core/colors";
import SchoolIcon from "@material-ui/icons/School";
import { ContextualMenu, Panel } from "@fluentui/react";
import UpdateStudentPath from "../CRUD/UpdateStudentPath";
import MoreVertIcon from "@material-ui/icons/MoreVert";

interface IProps {
  historyPath: StudentPathDto[] | null;
  historyPathLoading: boolean;
}
export default function CardStudentPath({
  historyPath,
  historyPathLoading,
}: IProps) {
  const menuItemRef = React.useRef(null);
  const [showMenuContext, setShowMenuContext] = useState<boolean>(false);
  const [updateStudentPath, setUpdateStudentPath] = useState<boolean>(false);

  return (
    <>
      <Card>
        <CardHeader
          title="Parcours scolaire"
          avatar={
            <Avatar style={{ backgroundColor: green[200] }}>
              <SchoolIcon />
            </Avatar>
          }
          action={
            <IconButton
              aria-label="Menu"
              ref={menuItemRef}
              onClick={() => setShowMenuContext(true)}
            >
              <MoreVertIcon />
            </IconButton>
          }
        />
        <ContextualMenu
          items={[
            {
              key: "editStudentPath",
              text: "Modifier parcours scolaire",
              iconProps: { iconName: "PageEdit" },
              onClick: () => setUpdateStudentPath(true),
            },
          ]}
          hidden={!showMenuContext}
          target={menuItemRef}
          onItemClick={() => setShowMenuContext(false)}
          onDismiss={() => setShowMenuContext(false)}
        />
        <CardContent>
          {!historyPathLoading ? (
            historyPath && historyPath.length > 0 ? (
              historyPath.map((hp) => (
                <div key={hp.studentPathId}>
                  <Grid container>
                    {hp.endDate != null && hp.endDate <= new Date() && (
                      <Grid item xs={12}>
                        <Typography
                          variant="body1"
                          color="secondary"
                          gutterBottom
                        >
                          Expiré
                        </Typography>
                      </Grid>
                    )}
                    <Grid item xs={6}>
                      <LabelFieldValue
                        label="Année scolaire"
                        value={
                          hp.startDate?.getFullYear() +
                          "/" +
                          hp.endDate?.getFullYear()
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <LabelFieldValue
                        label="Classe"
                        value={hp.classroomName}
                        href={`/classroom/${hp.classroomId}`}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <LabelFieldValue
                        label="Date de début"
                        value={dateToString(hp.startDate)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <LabelFieldValue
                        label="Date de fin"
                        value={dateToString(hp.endDate)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <LabelFieldValue
                        label="Frais inscription"
                        value={numberToMad(hp.inscriptionFees)}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <LabelFieldValue
                        label="Frais scolarité"
                        value={numberToMad(hp.monthlyFees)}
                      />
                    </Grid>
                  </Grid>
                </div>
              ))
            ) : (
              <NoResult />
            )
          ) : (
            <Loading />
          )}
        </CardContent>
      </Card>

      <Panel
        isBlocking={true}
        headerText="Modifier parcours scolaire"
        isOpen={updateStudentPath}
        onDismiss={() => setUpdateStudentPath(false)}
        closeButtonAriaLabel="Fermer"
      >
        {historyPath != null && historyPath.length > 0 && (
          <UpdateStudentPath
            studentPath={historyPath[0]}
            handleClose={() => setUpdateStudentPath(false)}
          />
        )}
      </Panel>
    </>
  );
}
