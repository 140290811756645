import React from "react";
import { Container, Grid } from "@material-ui/core";
import CardExpenses from "./CardExpenses";
import NewExpense from "./NewExpense"

export default function Expenses() {

  return (
    <Container maxWidth="lg">
      <Grid container spacing={1}>
        <Grid item sm={6} xs={12}>
          <CardExpenses />
        </Grid>
        <Grid item sm={6} xs={12}>
          <NewExpense />
        </Grid>
      </Grid>
    </Container>
  );
}
