import React, { useState, useEffect, useContext } from "react";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TablePagination,
  Typography,
} from "@material-ui/core";
import {
  FilterDto,
  SortOrderType,
  StudentPaymentApi,
  StudentPaymentDtoAmountPaginatedDto,
} from "../../api-clients/api";
import { getApiUrl } from "../../api-clients";
import Loading from "../Shared/Loading";
import NoResult from "../Shared/NoResult";
import { dateToString, numberToMad } from "../../helper/index";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { SchoolContext } from "../../contexts/SchoolContext";
import { useHistory } from "react-router-dom";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import { green } from "@material-ui/core/colors";
import { InstitutionContext } from "../../contexts/InstitutionContext";
import PaymentIcon from "@material-ui/icons/Payment";

initializeIcons();

interface IProps {
  studentPathId: string;
}
export default function CardStudentPayments({ studentPathId }: IProps) {
  const { institution } = useContext(InstitutionContext);
  const { accademicYear, expenseUpdated, setExpenseUpdated } = useContext(
    SchoolContext
  );
  const history = useHistory();
  const [studentPaymentApi] = useState<StudentPaymentApi>(
    new StudentPaymentApi(getApiUrl())
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [
    result,
    setResult,
  ] = useState<StudentPaymentDtoAmountPaginatedDto | null>(null);
  const [filter, setFilter] = useState<FilterDto>({
    institutionId: institution?.institutionId,
    pageSize: 7,
    accademicYearId: accademicYear?.accademicYearId,
    sortField: "Date",
    sortOrder: SortOrderType._1,
  } as FilterDto);

  useEffect(() => {
    setExpenseUpdated(false);
    studentPaymentApi
      .filterByStudentPath(studentPathId, filter)
      .then((res) => {
        setResult(res);
        setIsLoading(false);
      })
      .catch((err) => setIsLoading(false));
  }, [
    studentPathId,
    studentPaymentApi,
    filter,
    expenseUpdated,
    setExpenseUpdated,
  ]);

  return (
    <Card>
      <CardHeader
        title="Paiements"
        avatar={
          <Avatar aria-label="Paiement" style={{ backgroundColor: green[200] }}>
            <PaymentIcon />
          </Avatar>
        }
      />
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12} style={{ textAlign: "right" }}>
            {!isLoading && result != null && (
              <Typography variant="subtitle1">
                Total {numberToMad(result.totalAmount)}
              </Typography>
            )}
          </Grid>
        </Grid>
        {!isLoading ? (
          result != null && result.data != null && result.data.length > 0 ? (
            <>
              <List>
                {result.data.map((s) => (
                  <div key={s.paymentId}>
                    <ListItem
                      button
                      alignItems="flex-start"
                      onClick={() => history.push("/payment/" + s.paymentId)}
                      style={{ paddingTop: "0px", paddingBottom: "0px" }}
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <AttachMoneyIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={numberToMad(s.totalAmount)}
                        secondary={
                          <>
                            <Typography
                              component="span"
                              variant="body2"
                              color="textPrimary"
                            >
                              {dateToString(s.date)}
                              {" — Payé par : "}
                              {s.paidBy}
                            </Typography>
                            <br></br>
                            {s.inscriptionAmount != null &&
                              s.inscriptionAmount > 0 &&
                              ` - Inscription : ${numberToMad(
                                s.inscriptionAmount
                              )}`}
                            {s.schoolAmount != null &&
                              s.schoolAmount > 0 &&
                              ` - Scolarité : ${numberToMad(s.schoolAmount)}`}
                            {s.transportAmount != null &&
                              s.transportAmount > 0 &&
                              ` - Transport : ${numberToMad(
                                s.transportAmount
                              )}`}
                            {s.childCareAmount != null &&
                              s.childCareAmount > 0 &&
                              ` - Garderie : ${numberToMad(s.childCareAmount)}`}
                          </>
                        }
                      />
                    </ListItem>
                    <Divider />
                  </div>
                ))}
              </List>
              <TablePagination
                component="div"
                count={result.totalElements ?? 0}
                page={filter.pageIndex ?? 0}
                rowsPerPage={filter.pageSize ?? 0}
                rowsPerPageOptions={[7]}
                onPageChange={(event, newPage) => {
                  setFilter({
                    ...filter,
                    pageIndex: newPage,
                  } as FilterDto);
                }}
                onRowsPerPageChange={(event) => {
                  setFilter({
                    ...filter,
                    pageIndex: 0,
                    pageSize: +event.target.value,
                  } as FilterDto);
                }}
              />
            </>
          ) : (
            <NoResult />
          )
        ) : (
          <Loading />
        )}
      </CardContent>
      {/* <CardActions>
        <Button color="primary" size="small" startIcon={<GetAppIcon />}>
          Download
        </Button>
      </CardActions> */}
    </Card>
  );
}
