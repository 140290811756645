import React from "react";
import {
  BarChart,
  Bar,
  Brush,
  Cell,
  ReferenceLine,
  XAxis,
  YAxis,
  Tooltip,
  RechartsFunction,
} from "recharts";
import { CustomTooltip } from "./common";
import { numberCompacted } from "../../helper";
import { Typography } from "@material-ui/core";
import { BarchartProps } from "../../api-clients";

interface IProps {
  data: BarchartProps[] | null;
  onBarClick?: RechartsFunction;
  width: number;
  currency?: string;
}

export default function AppBrushBarChart({
  data,
  onBarClick,
  width,
  currency,
}: IProps) {
  return (
    <div>
      {data != null && data.length > 0 ? (
        <BarChart
          width={width}
          height={270}
          data={data}
          margin={{ top: 5, right: 30, bottom: 5 }}
          onClick={(e: any) => console.log(e)}
        >
          <XAxis dataKey="name" />
          <YAxis tickFormatter={(v) => numberCompacted(+v)} />
          <Tooltip
            content={<CustomTooltip showValue={true} currency={currency} />}
          />
          <ReferenceLine y={0} stroke="#000" />
          <Brush dataKey="name" height={30} stroke="#8884d8" />
          <Bar dataKey="value" onClick={onBarClick}>
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Bar>
        </BarChart>
      ) : (
        <Typography variant="body2">No data</Typography>
      )}
    </div>
  );
}

AppBrushBarChart.defaultProps = {
  onBarClick: null,
};
