import React, { useContext } from "react";
import { Grid, GridSize, Typography } from "@material-ui/core";
import FluentUiDatePicker from "../../Shared/FluentUiDatePicker";
import { SchoolContext } from "../../../contexts/SchoolContext";
import ClassroomsDropDown from "../../Shared/ClassroomsDropdown";
import { Checkbox, IDropdownOption, Slider, TextField } from "@fluentui/react";

interface IProps {
  student: any;
  setStudent: (s: any) => void;
  disabled: boolean;
  md: GridSize;
  xs: GridSize;
  sm: GridSize;
}
export default function StudentPathForm({
  student,
  setStudent,
  disabled,
  md,
  xs,
  sm,
}: IProps) {
  const { accademicYear } = useContext(SchoolContext);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          Inscription année {accademicYear?.startDate?.getFullYear()}/
          {accademicYear?.endDate?.getFullYear()}
        </Typography>
      </Grid>
      <Grid item sm={sm} xs={xs} md={md}>
        <FluentUiDatePicker
          required
          label="Date de début"
          placeholder="Date de début"
          value={student.startDate}
          minDate={accademicYear?.startDate}
          maxDate={accademicYear?.endDate}
          onSelectDate={(date: Date | null | undefined): void => {
            setStudent({
              ...student,
              startDate: date,
            });
          }}
        />
      </Grid>
      <Grid item sm={sm} xs={xs} md={md}>
        <ClassroomsDropDown
          required
          label="Classe"
          placeholder="Selectionner la classe"
          selectedKey={student.classroomId}
          onChange={(
            event: React.FormEvent<HTMLDivElement>,
            item?: IDropdownOption
          ): void => {
            if (item)
              setStudent({
                ...student,
                classroomId: item.key,
              });
          }}
        />
      </Grid>
      <Grid item sm={12} xs={12}>
        <Slider
          label="Frais d'inscription (dirham)"
          max={accademicYear?.inscriptionFees ?? 2000}
          value={student.inscriptionFees ?? 0}
          defaultValue={student.inscriptionFees ?? 0}
          step={10}
          showValue
          onChange={(value: number) =>
            setStudent({
              ...student,
              inscriptionFees: value,
            })
          }
        />
      </Grid>
      <Grid item sm={12} xs={12}>
        <Slider
          label="Frais de scolarité (dirham)"
          max={accademicYear?.monthlyFees ?? 2000}
          value={student.monthlyFees ?? 0}
          defaultValue={student.monthlyFees ?? 0}
          step={10}
          showValue
          onChange={(value: number) =>
            setStudent({
              ...student,
              monthlyFees: value,
            })
          }
        />
      </Grid>
      {accademicYear?.hasTransport &&
          <Grid item sm={6} md={4} xs={12}>
            <Checkbox
              label="Transport"
              checked={student.hasTransport} onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) =>
                setStudent({ ...student, hasTransport: checked })}
            />
            <TextField
              required
              disabled={!student.hasTransport}
              label="Frais transport (dirham)"
              placeholder="Frais transport mensuel (dirham)"
              value={student.transportFees ? student.transportFees?.toString() : accademicYear.transportFees?.toString()}
              type="number"
              lang="fr-FR"
              min="0"
              onChange={(
                event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                newValue?: string
              ) => {
                if (!isNaN(Number(newValue))) {
                  let num: number = Number(newValue);
                  if (num < 0) num = num * -1;
                  setStudent({ ...student, transportFees: Number(num.toFixed(2)) })
                }
              }}
            />
          </Grid>
        }

        {accademicYear?.hasChildCare &&
          <Grid item sm={6} md={4} xs={12}>
            <Checkbox
              label="Garderie"
              checked={student.hasChildCare} onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) =>
                setStudent({ ...student, hasChildCare: checked })}
            />
            <TextField
              required
              disabled={!student.hasChildCare}
              label="Frais garderie (dirham)"
              placeholder="Frais garderie mensuel (dirham)"
              value={student.childCareFees ? student.childCareFees?.toString() : accademicYear.childCareFees?.toString()}
              type="number"
              lang="fr-FR"
              min="0"
              onChange={(
                event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                newValue?: string
              ) => {
                if (!isNaN(Number(newValue))) {
                  let num: number = Number(newValue);
                  if (num < 0) num = num * -1;
                  setStudent({ ...student, childCareFees: Number(num.toFixed(2)) })
                }
              }}
            />
          </Grid>
        }

        {accademicYear?.hasCanteen &&
          <Grid item sm={6} md={4} xs={12}>
            <Checkbox
              label="Cantine"
              checked={student.hasCanteen} onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) =>
                setStudent({ ...student, hasCanteen: checked })}
            />
            <TextField
              required
              disabled={!student.hasCanteen}
              label="Frais garderie (dirham)"
              placeholder="Frais garderie mensuel (dirham)"
              value={student.canteenFees ? student.canteenFees?.toString() : accademicYear.canteenFees?.toString()}
              type="number"
              lang="fr-FR"
              min="0"
              onChange={(
                event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                newValue?: string
              ) => {
                if (!isNaN(Number(newValue))) {
                  let num: number = Number(newValue);
                  if (num < 0) num = num * -1;
                  setStudent({ ...student, canteenFees: Number(num.toFixed(2)) })
                }
              }}
            />
          </Grid>
        }
    </Grid>
  );
}
