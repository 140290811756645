import React, { useState, useEffect } from "react";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TablePagination,
} from "@material-ui/core";
import {
  ParentApi,
  ParentFilterDto,
  ParentDtoPaginatedDto,
} from "../../api-clients/api";
import { getApiUrl } from "../../api-clients";
import Loading from "../Shared/Loading";
import NoResult from "../Shared/NoResult";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { useHistory } from "react-router-dom";
import { SearchBox } from "@fluentui/react";

initializeIcons();

interface IProps {
  classrooms?: (string | undefined)[] | undefined;
}
export default function CardParents({ classrooms }: IProps) {
  const history = useHistory();
  const [parentApi] = useState<ParentApi>(new ParentApi(getApiUrl()));
  const [filter, setFilter] = useState<ParentFilterDto>({
    pageSize: 7,
  } as ParentFilterDto);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [result, setResult] = useState<ParentDtoPaginatedDto | null>(null);

  useEffect(() => {
    setIsLoading(true);
    parentApi
      .filter(filter)
      .then((res) => {
        setResult(res);
        setIsLoading(false);
      })
      .catch((err) => setIsLoading(false));
  }, [parentApi, filter]);

  return (
    <Card>
      <CardHeader title="Parents" />
      <CardContent>
        <Grid container spacing={1}>
          <Grid item sm={12} xs={12}>
            <SearchBox
              placeholder="Recherche par nom ou prénom..."
              onClear={() =>
                setFilter({
                  ...filter,
                  filter: "",
                } as ParentFilterDto)
              }
              onSearch={(newValue: string) =>
                setFilter({
                  ...filter,
                  filter: newValue,
                } as ParentFilterDto)
              }
            />
          </Grid>
        </Grid>
        {!isLoading ? (
          result != null && result.data != null && result.data.length > 0 ? (
            <>
              <List>
                {result.data.map((s) => (
                  <div key={s.parentId}>
                    <ListItem
                      button
                      alignItems="flex-start"
                      onClick={() => history.push("/parent/" + s.parentId)}
                      style={{ paddingTop: "0px", paddingBottom: "0px" }}
                    >
                      {s.firstName != null && s.lastName != null && (
                        <ListItemAvatar>
                          <Avatar>
                            {s.firstName[0]}
                            {s.lastName[0]}
                          </Avatar>
                        </ListItemAvatar>
                      )}
                      <ListItemText
                        primary={`${s.lastName} ${s.firstName}`}
                        secondary={
                          s.childrenCount === 0
                            ? "Aucun élève"
                            : `${s.childrenCount} éléve${((s.childrenCount ?? 0) > 1) ? "s" : ""
                            }`
                        }
                      />
                    </ListItem>
                    <Divider />
                  </div>
                ))}
              </List>
              <TablePagination
                component="div"
                count={result.totalElements ?? 0}
                page={filter.pageIndex ?? 0}
                rowsPerPage={filter.pageSize ?? 0}
                rowsPerPageOptions={[7]}
                onPageChange={(event, newPage) => {
                  setFilter({
                    ...filter,
                    pageIndex: newPage,
                  } as ParentFilterDto);
                }}
                onRowsPerPageChange={(event) => {
                  setFilter({
                    ...filter,
                    pageIndex: 0,
                    pageSize: +event.target.value,
                  } as ParentFilterDto);
                }}

              />
            </>
          ) : (
            <NoResult />
          )
        ) : (
          <Loading />
        )}
      </CardContent>
    </Card>
  );
}
