import React, { useState } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { useHistory } from "react-router-dom";
import appRoutes from "../../../routes";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { Collapse, IconButton, List, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  unselectedItem: {
    // color: "white",
  },
  selectedItem: {
    // background: "white",
    // color: theme.palette.primary.dark,
  },
  selectedItemContent: {
    // color: theme.palette.primary.dark,
  },
}));

interface IProps {
  onNavigated?: Function;
}
export default function MenuListItems({ onNavigated }: IProps) {
  const classes = useStyles();
  const history = useHistory();
  const [expandedMenus, setExpandedMenus] = useState<string[]>([]);

  const handleClick = (name: string) => {
    if (expandedMenus.filter((x) => x === name).length > 0)
      setExpandedMenus(expandedMenus.filter((x) => x !== name));
    else setExpandedMenus([...expandedMenus, name]);
  };

  const isExpanded = (name: string) => {
    return expandedMenus.filter((x) => x === name).length > 0;
  };

  const isSelected = (path: string, name: string) => {
    const result = history.location.pathname === path;
    if (result && !isExpanded(name)) setExpandedMenus([...expandedMenus, name]);

    return false;
  };

  const navigate = (path: string) => {
    history.push(path);
    if (onNavigated != null) onNavigated();
  };

  return (
    <div>
      {appRoutes
        .filter((r) => r.hideInMenu !== true)
        .map((r) => (
          <React.Fragment key={r.name}>
            <ListItem
              className={
                isSelected(r.path, r.name)
                  ? classes.selectedItem
                  : classes.unselectedItem
              }
              onClick={() =>
                r.component ? navigate(r.path) : handleClick(r.name)
              }
              selected={r.component ? isSelected(r.path, r.name) : false}
              button
            >
              <ListItemIcon
                className={
                  isSelected(r.path, r.name)
                    ? classes.selectedItemContent
                    : classes.unselectedItem
                }
                style={{
                  color: isSelected(r.path, r.name)
                    ? process.env.REACT_APP_BACKGROUND_DARK
                    : "white",
                }}
              >
                {r.icon}
              </ListItemIcon>
              <ListItemText primary={r.name} />
              {r.children &&
                r.children.filter((r) => r.hideInMenu !== true).length > 0 &&
                (isExpanded(r.name) ? (
                  <IconButton
                    className={
                      isSelected(r.path, r.name)
                        ? classes.selectedItemContent
                        : classes.unselectedItem
                    }
                    onClick={() => handleClick(r.name)}
                  >
                    <ExpandLess />
                  </IconButton>
                ) : (
                  <IconButton
                    className={
                      isSelected(r.path, r.name)
                        ? classes.selectedItem
                        : classes.unselectedItem
                    }
                    onClick={() => handleClick(r.name)}
                  >
                    <ExpandMore />
                  </IconButton>
                ))}
            </ListItem>

            {r.children && (
              <Collapse in={isExpanded(r.name)}>
                <List component="div">
                  {r.children
                    .filter((r) => r.hideInMenu !== true)
                    .map((rc) => (
                      <ListItem
                        className={
                          isSelected(r.path + rc.path, rc.name)
                            ? classes.selectedItem
                            : classes.unselectedItem
                        }
                        key={"menuChild_" + r.name + "_" + rc.name}
                        onClick={() => navigate(r.path + rc.path)}
                        selected={
                          rc.component
                            ? isSelected(r.path + rc.path, r.name)
                            : false
                        }
                        dense
                        button
                      >
                        <ListItemIcon
                          className={
                            isSelected(r.path + rc.path, rc.name)
                              ? classes.selectedItemContent
                              : classes.unselectedItem
                          }
                          style={{ paddingLeft: "4px" }}
                        >
                          {rc.initials}
                        </ListItemIcon>
                        <ListItemText primary={rc.name} />
                      </ListItem>
                    ))}
                </List>
              </Collapse>
            )}
          </React.Fragment>
        ))}
    </div>
  );
}
