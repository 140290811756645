import * as React from "react";
import { Calendar, DateRangeType } from "@fluentui/react";
import { dateToString, DayPickerStrings } from "../../helper";

interface IProps {
  onSelectDate: (date: Date, dateRangeArray?: Date[]) => void;
  selectedDate: Date;
}
export default function MonthPicker({ onSelectDate, selectedDate }: IProps) {
  return (
    <div style={{ height: "auto" }}>
      <Calendar
        strings={DayPickerStrings}
        dateRangeType={DateRangeType.Month}
        showGoToToday={false}
        highlightSelectedMonth
        isDayPickerVisible={false}
        onSelectDate={onSelectDate}
        value={selectedDate}
      />
      <div>
        {dateToString(
          new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1)
        )}{" "}
        -{" "}
        {dateToString(
          new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0)
        )}
      </div>
    </div>
  );
}
