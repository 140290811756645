import React from "react";
import { Box, CssBaseline, makeStyles } from "@material-ui/core";
import { Redirect, Route } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import appRoutes from "../../routes";
import TopBar from "./TopBar/TopBar";
import SideMenu from "./SideMenu/SideMenu";
import Copyright from "../../components/Shared/Copyright";
import sideMenuTheme from "../../app-sideMenu-theme";

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    background: process.env.REACT_APP_BACKGROUND_DARK,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    background: process.env.REACT_APP_PRIMARY_COLOR_DARK,
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function Layout() {
  const classes = useStyles();

  const [open, setOpen] = React.useState(
    localStorage.getItem("drawerStatus") === "open"
  );
  const handleDrawerOpen = () => {
    setOpen(true);
    localStorage.setItem("drawerStatus", "open");
  };

  const handleDrawerClose = () => {
    setOpen(false);
    localStorage.setItem("drawerStatus", "close");
  };

  const getRoutes = () => {
    let routes: any[] = [];

    routes.push(
      <Route key="default" exact path="/">
        <Redirect exact from="/" to="/dashboard" />
      </Route>
    );
    appRoutes.map((r) => {
      if (r.component) {
        routes.push(
          <Route
            key={"route_" + r.name}
            exact
            path={r.path}
            render={() => r.component}
          />
        );
      }

      if (r.children) {
        r.children
          .filter((c) => c.component)
          .map((c) => {
            routes.push(
              <Route
                key={"childroute_" + r.name + "_" + c.name}
                exact
                path={r.path + c.path}
                render={() => c.component}
              />
            );
            return c;
          });
      }

      return r;
    });

    return routes;
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <TopBar
        handleDrawerClose={handleDrawerClose}
        handleDrawerOpen={handleDrawerOpen}
        open={open}
      />
      <ThemeProvider theme={sideMenuTheme}>
        <SideMenu open={open} />
      </ThemeProvider>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        {getRoutes()}
        <Box pt={4}>
          <Copyright />
        </Box>
      </main>
    </div>
  );
}
