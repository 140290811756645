import React, { useState, useEffect, useContext, useRef } from "react";
import {
  PieChartDto,
  StatisticApi,
  StatisticFilterDto,
} from "../../api-clients/api";
import { getApiUrl } from "../../api-clients";
import { SchoolContext } from "../../contexts/SchoolContext";
import Loading from "../Shared/Loading";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import AppPieChart from "../Charts/AppPieChart";

export default function ExpensesPieChart() {
  const { accademicYear } = useContext(SchoolContext);
  const accademicYearDate: string = `${accademicYear?.startDate?.getFullYear()}/${accademicYear?.endDate?.getFullYear()}`;

  const [statisticApi] = useState<StatisticApi>(new StatisticApi(getApiUrl()));
  const [pieChartValue, setPieChartValue] = useState<PieChartDto | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const parentRef = useRef<any>(null);
  useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    if (accademicYear != null) {
      setIsLoading(true);
      statisticApi
        .expensesByFurnisher({
          startDate: accademicYear.startDate,
          endDate: accademicYear.endDate,
        } as StatisticFilterDto)
        .then((res) => {
          setPieChartValue(res);
          setIsLoading(false);
        })
        .catch((err) => setIsLoading(false));
    }
  }, [statisticApi, accademicYear]);

  return (
    <Card>
      <CardHeader title="Dépenses" subheader={accademicYearDate} />
      <CardContent ref={parentRef}>
        {!isLoading ? (
          <AppPieChart
            data={pieChartValue}
            showTooltip={true}
            showTooltipValue={true}
            showLegend={false}
            width={parentRef?.current?.offsetWidth - 40 ?? 250}
            height={parentRef?.current?.offsetWidth - 70 ?? 250}
          />
        ) : (
          <Loading />
        )}
      </CardContent>
    </Card>
  );
}
