import React, { useContext, useState, useEffect } from "react";
import {
  Container,
  Grid,
} from "@material-ui/core";
import { SchoolContext } from "../../contexts/SchoolContext";
import CardClassroom from "./CardClassroom";
import { useParams } from "react-router-dom";
import { EducationDto } from "../../api-clients/api";
import Loading from "../Shared/Loading";
import CardHeaderEducation from "./CardHeaderEducation";
import CardStudents from "../Students/CardStudents";

export default function Education() {
  const { educations } = useContext(SchoolContext);
  const [education, setEducation] = useState<EducationDto | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { educationId }: any = useParams();

  useEffect(() => {
    if (
      educations != null &&
      educationId != null &&
      educations.filter((e) => e.educationId === educationId).length > 0
    ) {
      setEducation(educations.filter((e) => e.educationId === educationId)[0]);
      setIsLoading(false);
    }
  }, [educationId, educations]);

  return (
    <Container maxWidth="lg">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {education != null && (
            <div>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <CardHeaderEducation education={education} />
                </Grid>
                {education != null &&
                  education.classrooms != null &&
                  education.classrooms.map((c) => (
                    <Grid
                      item
                      lg={3}
                      md={6}
                      sm={6}
                      xs={12}
                      key={`classrooms_${c.classroomId}`}
                    >
                      <CardClassroom classroom={c} />
                    </Grid>
                  ))}
                <Grid item xs={12}>
                  <CardStudents
                    classrooms={education.classrooms?.map((c) => c.classroomId)}
                  />
                </Grid>
              </Grid>
            </div>
          )}
        </>
      )}
    </Container>
  );
}
