import React, { PropsWithChildren, useEffect, useState } from "react";
import { getApiUrl } from "../api-clients";
import { ApplicationUserApi, LoginDto } from "../api-clients/api";
// import { UserApi, UserDto } from "../api-clients/api";
import { AuthContext } from "../contexts/AuthContext";

export const AuthProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(false);
  const [user, setUser] = useState<any | null>(null);
  const [token, setToken] = useState<string | null>(
    localStorage.getItem("token")
  );
  const [userApi] = useState<ApplicationUserApi>(
    new ApplicationUserApi(getApiUrl())
  );

  const isAuthenticated = token !== null;

  useEffect(() => {
    if (isAuthenticated) {
      userApi
        .applicationUser()
        .then((res) => {
          setUser(res);
        })
        .catch((err) => {
          console.log(err);
          setUser(null);
        });
    }
  }, [userApi, isAuthenticated]);

  const login = (credentials: LoginDto) => {
    setIsAuthenticating(true);
    userApi
      .login(credentials)
      .then((res) => {
        if (res?.token !== undefined) {
          localStorage.setItem("token", res.token);
          setToken(res.token);
        }
        setIsAuthenticating(false);
        window.location.href = "/";
      })
      .catch((err) => {
        console.log(err);
        setIsAuthenticating(false);
      });
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticating,
        isAuthenticated,
        user,
        login,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
