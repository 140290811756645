import React, { useContext, useEffect, useState } from "react";
import {
  Dropdown,
  IDropdownOption,
} from "@fluentui/react";
import { SchoolContext } from "../../contexts/SchoolContext";

interface IProps {
  onChange: (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    index?: number
  ) => void;
  multiSelect: boolean;
  selectedKeys: string[] | number[] | null;
  selectedKey?: string | number | string[] | number[] | null;
  label?: string;
  placeholder?: string;
  required: boolean;
}
export default function ExpenseFurnishersDropdown(props: IProps) {
  const { expenseFurnishers } = useContext(SchoolContext);
  const [expenseFurnishersOptions, setExpenseFurnishers] = useState<any[]>([]);
  useEffect(() => {
    let options: any[] = [];
    expenseFurnishers.map((e) => {
      options.push({
        key: e.furnisherId,
        text: e.name,
      });

      return e;
    });
    setExpenseFurnishers(options);
  }, [expenseFurnishers]);
  return (
    <>
      {expenseFurnishers.length > 0 && (
        <Dropdown
          {...props}
          options={expenseFurnishersOptions}
          style={{ width: "100%" }}
        />
      )}
    </>
  );
}

ExpenseFurnishersDropdown.defaultProps = {
  multiSelect: false,
  selectedKeys: null,
  selectedKey: null,
  label: null,
  placeholder: null,
  required: false,
};
