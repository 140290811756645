import React, { useContext, useState } from "react";
import { Grid } from "@material-ui/core";
import { StudentApi, StudentUpdateDto } from "../../api-clients/api";
import { TextField } from "@fluentui/react";
import FluentUiDatePicker from "../Shared/FluentUiDatePicker";
import { getApiUrl } from "../../api-clients";
import { SchoolContext } from "../../contexts/SchoolContext";
import FormButtons from "../Shared/FormButtons";

interface IProps {
  student: StudentUpdateDto;
  onCancelClick?: () => void;
}
export default function CRUDStudent({ student, onCancelClick }: IProps) {
  const { setStudentUpdated } = useContext(SchoolContext);
  const [studentApi] = useState<StudentApi>(new StudentApi(getApiUrl()));
  const [studentForm, setStudentForm] = useState<StudentUpdateDto>(student);
  const [saving, setSaving] = useState<boolean>(false);

  const canSave: boolean =
    studentForm.firstName != null &&
    studentForm.firstName.length > 0 &&
    studentForm.lastName != null &&
    studentForm.lastName.length > 0;

  const save = () => {
    if (canSave) {
      setSaving(true);
      studentApi
        .update(studentForm)
        .then((res) => {
          setSaving(false);
          setStudentUpdated(true);
        })
        .catch((err) => setSaving(false));
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item sm={6} xs={12}>
        <TextField
          required
          autoComplete="off"
          label="Nom de famille"
          placeholder="Nom de famille"
          value={studentForm.lastName}
          onChange={(
            event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string
          ) =>
            setStudentForm({
              ...studentForm,
              lastName: newValue,
            } as StudentUpdateDto)
          }
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <TextField
          required
          autoComplete="off"
          label="Prénom"
          placeholder="Prénom"
          value={studentForm.firstName}
          onChange={(
            event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string
          ) =>
            setStudentForm({
              ...studentForm,
              firstName: newValue,
            } as StudentUpdateDto)
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FluentUiDatePicker
          label="Date de naissance"
          placeholder="Date de naissance"
          value={studentForm.dateOfBirth}
          onSelectDate={(date: Date | null | undefined): void => {
            setStudentForm({
              ...studentForm,
              dateOfBirth: date,
            } as StudentUpdateDto);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormButtons
          onCancelClick={() => {
            if (onCancelClick) onCancelClick();
          }}
          onConfirmClick={save}
          disabled={saving || !canSave}
        />
      </Grid>
    </Grid>
  );
}
