import {
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { getApiUrl } from "../../api-clients";
import {
  ParentApi,
  StudentApi,
  StudentView,
  ParentDto,
} from "../../api-clients/api";
import Loading from "../Shared/Loading";
import NoResult from "../Shared/NoResult";
import StudentCard from "../Students/StudentCard";
import StudentPaymentResume from "../Students/StudentPaymentResume";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";
import CRUDParent from "../CRUD/CRUDParent";
import { Panel } from "@fluentui/react";
import LabelFieldValue from "../Shared/LabelFieldValue";
import FormButtons from "../Shared/FormButtons";

export default function Parent() {
  const { parentId }: any = useParams();
  const history = useHistory();
  const [parentApi] = useState<ParentApi>(new ParentApi(getApiUrl()));
  const [studentApi] = useState<StudentApi>(new StudentApi(getApiUrl()));
  const [checkDelete, setCheckDelete] = useState<boolean>(false);
  const [students, setStudents] = useState<StudentView[]>([]);
  const [parent, setParent] = useState<ParentDto | null>(null);
  const [parentToEdit, setParentToEdit] = useState<ParentDto | null>(null);
  const [parentLoaded, setParentLoaded] = useState<boolean>(false);
  const [studentsLoaded, setStudentsLoaded] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);

  const deleteParent = () => {
    setDeleting(true);
    parentApi
      .delete(parentId)
      .then(() => {
        setDeleting(false);
        history.push("/parents");
      })
      .catch((err) => {
        setDeleting(false);
        console.log(err);
      });
  };

  useEffect(() => {
    if (parent == null) {
      setStudentsLoaded(false);
      setParentLoaded(false);
      parentApi
        .byId(parentId)
        .then((res) => {
          setParent(res);
          setParentLoaded(true);
        })
        .catch((err) => {
          console.log(err);
          setParentLoaded(true);
        });

      studentApi
        .getByParentId(parentId)
        .then((res) => {
          setStudents(res);
          setStudentsLoaded(true);
        })
        .catch((err) => {
          console.log(err);
          setStudentsLoaded(true);
        });
    }
  }, [studentApi, parentApi, parentId, parent]);

  return (
    <Container maxWidth="lg">
      {parentLoaded ? (
        parent != null ? (
          <>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h5">Parent</Typography>
              </Grid>

              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Typography variant="h6">
                      {`${parent.firstName} ${parent.lastName}`}
                    </Typography>
                    <Typography variant="subtitle2">
                      {parent.childrenCount && parent.childrenCount > 0
                        ? `${parent.childrenCount} élève${
                            parent.childrenCount === 1 ? "" : "s"
                          }`
                        : "Aucun élève associé"}
                    </Typography>
                    <Grid container style={{ marginTop: "10px" }}>
                      <Grid item xs={2}>
                        <LabelFieldValue
                          label="Numéro de gsm"
                          value={parent.mobileContact}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <LabelFieldValue
                          label="Adresse email"
                          value={parent.emailContact}
                        />
                      </Grid>
                      {studentsLoaded ? (
                        students &&
                        students.length > 0 && (
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            style={{ marginTop: "10px" }}
                          >
                            <StudentPaymentResume
                              todayCost={students
                                .map(
                                  (s) =>
                                    s.todayScolarshipCost + s.todayServicesCost
                                )
                                .reduce((a, b) => a + b, 0)}
                              entierServicesCost={students
                                .map(
                                  (s) =>
                                    s.totalScolarshipCost + s.totalServicesCost
                                )
                                .reduce((a, b) => a + b, 0)}
                              totalPayments={
                                students.map((s) => s.totalReceivedPayments)[0]
                              }
                              studentBalance={students
                                .map(
                                  (s) =>
                                    s.totalReceivedPayments -
                                    (s.todayScolarshipCost +
                                      s.todayServicesCost)
                                )
                                .reduce((a, b) => a + b, 0)}
                              showInline={true}
                            />
                          </Grid>
                        )
                      ) : (
                        <Loading />
                      )}
                    </Grid>
                  </CardContent>
                  <CardActions disableSpacing>
                    {checkDelete ? (
                      <>
                        <Grid container>
                          <Grid item xs={2}>
                            <Typography variant="subtitle1">
                              Confirmer la suppression ?
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <IconButton
                              aria-label="Annuler"
                              disabled={deleting}
                              onClick={() => setCheckDelete(false)}
                              color="inherit"
                            >
                              <ClearIcon />
                            </IconButton>
                            <IconButton
                              aria-label="Confirmer"
                              disabled={deleting}
                              onClick={deleteParent}
                              color="secondary"
                            >
                              <CheckIcon />
                            </IconButton>
                            {deleting && (
                              <CircularProgress
                                size={24}
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  marginTop: -12,
                                  marginLeft: -12,
                                }}
                              />
                            )}
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <FormButtons
                        onConfirmClick={() =>
                          setParentToEdit({ ...parent } as ParentDto)
                        }
                        onDeleteClick={() => setCheckDelete(true)}
                        disabled={false}
                        cancelButtonVisible={false}
                        deleteButtonVisible={false}
                        confirmIcon="EditContact"
                        confirmText="Modifier"
                      />
                    )}
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
            {students && students.length > 0 && (
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h5">Élèves associés</Typography>
                </Grid>
                {students.map((s) => (
                  <Grid item lg={3} md={4} sm={6} xs={12} key={s.studentId}>
                    <StudentCard
                      student={s}
                      parents={null}
                      editStudent={() => {}}
                      showNavigation={true}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </>
        ) : (
          <NoResult />
        )
      ) : (
        <Loading />
      )}
      <Panel
        isBlocking={true}
        headerText="Modifier parent"
        isOpen={parentToEdit != null}
        onDismiss={() => {
          setParentToEdit(null);
        }}
        closeButtonAriaLabel="Fermer"
      >
        {parentToEdit != null && (
          <CRUDParent
            parent={parentToEdit}
            studentsDetails={students}
            parentChanged={() => {
              setParent(null);
              setParentToEdit(null);
            }}
          />
        )}
      </Panel>
    </Container>
  );
}
