import React, { useContext } from "react";
import { PaymentDto } from "../../api-clients/api";
import { SchoolContext } from "../../contexts/SchoolContext";
import { InstitutionContext } from "../../contexts/InstitutionContext";
import CRUDPayment from "../CRUD/CRUDPayment";

interface IProps {
  payment: PaymentDto | null;
}
export default function EditPayment(props: IProps) {
    const { accademicYear } = useContext(SchoolContext);
    const { institution } = useContext(InstitutionContext);
    
  return (
    <CRUDPayment
      sm={12}
      md={12}
      {...props}
      accademicYearId={accademicYear?.accademicYearId ?? ""}
      institutionId={institution?.institutionId ?? ""}
    />
  );
}
