import { InstitutionDto } from "../../api-clients/api";
import { dateToString } from "../../helper";

export const secondaryColor = "#5d5d5d";
export const highlitedColor = "#2E3192";
export const space = { text: " \n\n" };
export const detail = { fontSize: 7, color: secondaryColor };
export const tableHeader = { bold: true };
export const header = { fontSize: 15, bold: true, color: highlitedColor };
export const title = { fontSize: 19 };
export const subHeader = { fontSize: 9 };
export const table = { margin: [0, 7, 0, 20] };

export const pdfHeader = (
  logo: string,
  logoDimensions: number[],
  title: string,
  qrCode?: string | null
): any => {
  return {
    columns: [
      {
        image: `data:image/jpeg;base64,${logo}`,
        fit: logoDimensions,
      },

      [
        {
          alignment: "right",
          text: title,
          ...header,
        },
        qrCode != null
          ? { qr: qrCode, fit: 70, alignment: "right", version: 4 }
          : null,
      ],
    ],
  };
};

export const pdfFooter = (institution: InstitutionDto | null): any => {
  return [
    {
      ...subHeader,
      alignment: "left",
      text: `Imprimé à ${institution?.city}, le ${dateToString(new Date())}`,
    },
    { ...space },
    {
      text: `${institution?.name} ${institution?.address} | Tel : ${institution?.telephoneContact} e-mail : ${institution?.emailContact} | Site-Web : ${institution?.website}`,
      ...detail,
      pageBreak: 'after'

    },
  ];
};
