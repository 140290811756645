import React, { useState } from "react";
import { Dropdown, IDropdownOption } from "@fluentui/react";
import { PaymentTermType } from "../../api-clients/api";

interface IProps {
  onChange: (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    index?: number
  ) => void;
  multiSelect: boolean;
  selectedKeys: string[] | number[] | null;
  selectedKey?: string | number | string[] | number[] | null;
  label?: string;
  placeholder?: string;
  required: boolean;
}
export default function PaymentTermDropdown(props: IProps) {
  const [options] = useState<any[]>([
    {
      key: PaymentTermType._1,
      text: "Lettre d'echange",
    },
    {
      key: PaymentTermType._2,
      text: "Espèces",
    },
    {
      key: PaymentTermType._3,
      text: "Virement",
    },
    {
      key: PaymentTermType._4,
      text: "Versement",
    },
    {
      key: PaymentTermType._5,
      text: "Chèque",
    },
    {
      key: PaymentTermType._6,
      text: "Autre",
    },
  ]);

  return <Dropdown {...props} options={options} style={{ width: "100%" }} />;
}

PaymentTermDropdown.defaultProps = {
  multiSelect: false,
  selectedKeys: null,
  selectedKey: null,
  label: null,
  placeholder: null,
  required: false,
};
