import React from "react";
import { Typography, Link } from "@material-ui/core";
import AppLabel from "./AppLabel";
import { useHistory } from "react-router";
import { IFontStyles, Text, TooltipHost } from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";

interface IProps {
  label: string | null;
  value?: string | number;
  href?: string;
  valueColor?: string;
  tooltip?: string;
  icon?: React.ReactNode;
  variant?: keyof IFontStyles;
}
export default function LabelFieldValue({
  label,
  value,
  href,
  tooltip,
  valueColor,
  icon,
  variant,
}: IProps) {
  const history = useHistory();
  const tooltipId = useId("tooltip");
  const valueElement = (
    <Text
      variant={variant}
      style={{ color: valueColor != null ? valueColor : "default" }}
    >
      {href != null ? (
        <Link
          href={href}
          style={{ textAlign: "start" }}
          onClick={(event: any) => {
            event.preventDefault();
            history.push(href);
          }}
        >
          {value}
        </Link>
      ) : (
        value
      )}
    </Text>
  );

  return (
    <>
      {value != null && value.toString().length > 0 && (
        <>
          {icon}
          {label != null && <AppLabel label={label} block />}
          {tooltip != null ? (
            <TooltipHost content={tooltip} id={tooltipId}>
              <Text variant={variant}>{valueElement}</Text>
            </TooltipHost>
          ) : (
            <Text variant={variant}>{valueElement}</Text>
          )}
        </>
      )}
    </>
  );
}
LabelFieldValue.defaultProps = { variant: "large" };
