import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "font-awesome/css/font-awesome.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { AuthProvider } from "./providers/AuthProvider";
import { InstitutionProvider } from "./providers/InstitutionProvider";
import { AppProvider } from "./providers/AppProvider";
import appTheme from "./app-theme";
import { ThemeProvider } from "@material-ui/core";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Unauthorized from "./Unauthorized";
import PublicPayment from "./components/Payments/PublicPayment";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={appTheme}>
      <Router>
        <Switch>
          <Route path="/paymentReceipt/:paymentId" exact>
            <PublicPayment />
          </Route>
          <Route exact path="/unauthorized" component={Unauthorized} />
          <Route path="/">
            <AppProvider>
              <AuthProvider>
                <InstitutionProvider>
                  <App />
                </InstitutionProvider>
              </AuthProvider>
            </AppProvider>
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
