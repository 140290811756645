import React from "react";
import { Card, CardContent, CardHeader, Grid } from "@material-ui/core";
import { numberToMad } from "../../helper";
import LabelFieldValue from "./LabelFieldValue";

export interface ILabelFieldValue {
  label: string | null;
  value: string | number;
  valueColor?: string;
  isCurrency: boolean;
}

interface IProps {
  title: string;
  values: ILabelFieldValue[];
  avatar: any | null;
  subheader: string;
}
export default function CardStatistic({
  title,
  values,
  avatar,
  subheader,
}: IProps) {
  return (
    <Card>
      <CardHeader avatar={avatar} title={title} subheader={subheader} />
      <CardContent>
        <Grid container spacing={1}>
          {values != null &&
            values.map((v, i) => (
              <Grid item key={i} xs={6}>
                <LabelFieldValue
                  valueColor={v.valueColor}
                  label={v.label}
                  value={v.isCurrency ? numberToMad(+v.value) : v.value}
                />
              </Grid>
            ))}
        </Grid>
      </CardContent>
    </Card>
  );
}

CardStatistic.defaultProps = {
  avatar: null,
};
