import React, { useContext, useState } from "react";
import {
  CircularProgress,
  Grid,
  makeStyles,
} from "@material-ui/core";
import {
  ParentApi,
  ParentCreationDto,
  ParentDto,
  StudentView,
} from "../../api-clients/api";
import { IPersonaProps, TextField } from "@fluentui/react";
import { SchoolContext } from "../../contexts/SchoolContext";
import { getApiUrl } from "../../api-clients";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../contexts/AppContext";
import StudentIdPicker from "../Shared/StudentIdPicker";
import FormButtons from "../Shared/FormButtons";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

interface IProps {
  parent: ParentDto;
  studentsDetails?: StudentView[] | undefined;
  parentChanged?: () => void;
}
export default function CRUDParent({
  parent,
  studentsDetails,
  parentChanged,
}: IProps) {
  const classes = useStyles();
  const history = useHistory();
  const { openSnackbar } = useContext(AppContext);
  const [saving, setSaving] = useState<boolean>(false);
  const [students, setStudents] = useState<IPersonaProps[]>(
    studentsDetails
      ? studentsDetails.map((s) => {
          return {
            id: s.studentId,
            text: `${s.firstName} ${s.lastName}`,
            secondaryText: s.classroomName,
          } as IPersonaProps;
        })
      : []
  );
  const [parentApi] = useState<ParentApi>(new ParentApi(getApiUrl()));
  const { setParentUpdated } = useContext(SchoolContext);
  const [newParent, setNewParent] = useState<ParentCreationDto>(
    parent ?? ({} as ParentCreationDto)
  );

  const canSave: boolean =
    (newParent.firstName?.length ?? 0) > 0 &&
    (newParent.lastName?.length ?? 0) > 0;

  const SaveParent = () => {
    setSaving(true);

    if (newParent.parentId)
      parentApi
        .update({
          ...newParent,
          studentIds: students.map((s) => s.id),
        } as ParentCreationDto)
        .then((res) => {
          openSnackbar("Parent bien modifié", "success");
          setParentUpdated(true);
          if (parentChanged) parentChanged();
        })
        .catch((err) => {
          setSaving(false);
          openSnackbar("Une erreur s'est reproduite", "error");
        });
    else
      parentApi
        .create({
          ...newParent,
          studentIds: students.map((s) => s.id),
        } as ParentCreationDto)
        .then((res) => {
          openSnackbar("Parent bien enregistré", "success");
          setParentUpdated(true);
          history.push("/parent/" + res.parentId);
        })
        .catch((err) => {
          setSaving(false);
          openSnackbar("Une erreur s'est reproduite", "error");
        });
  };

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item sm={6} xs={12}>
          <TextField
            required
            autoComplete="off"
            label="Nom de famille"
            placeholder="Nom de famille..."
            value={newParent.lastName}
            onChange={(
              event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
              newValue?: string
            ) =>
              setNewParent({
                ...newParent,
                lastName: newValue,
              } as ParentCreationDto)
            }
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            required
            autoComplete="off"
            label="Prénom"
            placeholder="Prénom..."
            value={newParent.firstName}
            onChange={(
              event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
              newValue?: string
            ) =>
              setNewParent({
                ...newParent,
                firstName: newValue,
              } as ParentCreationDto)
            }
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            autoComplete="off"
            label="Numéro de gsm"
            placeholder="06xxxxxxxx..."
            type="number"
            value={newParent.mobileContact}
            onChange={(
              event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
              newValue?: string
            ) =>
              setNewParent({
                ...newParent,
                mobileContact: newValue,
              } as ParentCreationDto)
            }
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            autoComplete="off"
            label="Adresse email"
            placeholder="Email..."
            value={newParent.emailContact}
            onChange={(
              event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
              newValue?: string
            ) =>
              setNewParent({
                ...newParent,
                emailContact: newValue,
              } as ParentCreationDto)
            }
          />
        </Grid>
        <Grid item xs={12}>
          <StudentIdPicker
            title="Élèves à associer"
            selectedStudents={students}
            onListChanged={(list: IPersonaProps[]) => setStudents(list)}
            onStudentSelected={(selectedStudent: any) => {
              setStudents([...students, selectedStudent]);
            }}
            onRemoveStudent={(studentId: string) => {
              setStudents([...students.filter((s) => s.id !== studentId)]);
            }}
          />
        </Grid>
      </Grid>
      <div className={classes.wrapper}>
        <FormButtons
          onConfirmClick={SaveParent}
          disabled={saving || !canSave || students.length === 0}
          cancelButtonVisible={false}
          confirmIcon={parent.parentId != null ? "Save" : "PeopleAdd"}
          confirmText={parent.parentId != null ? "Enregistrer" : "Créer"}
        />
        {saving && (
          <CircularProgress
            size={24}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: -12,
              marginLeft: -12,
            }}
          />
        )}
      </div>
    </div>
  );
}
