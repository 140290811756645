import React, { useContext, useEffect, useState } from "react";
import {
  Dropdown,
  DropdownMenuItemType,
  IDropdownOption,
} from "@fluentui/react";
import { SchoolContext } from "../../contexts/SchoolContext";

interface IProps {
  onChange: (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    index?: number
  ) => void;
  multiSelect: boolean;
  selectedKeys: string[] | number[] | null;
  selectedKey?: string | number | string[] | number[] | null;
  label?: string;
  placeholder?: string;
  required: boolean;
  disabled: boolean;
}
export default function ClassroomsDropDown(props: IProps) {
  const { educations } = useContext(SchoolContext);
  const [classroomOptions, setClassroomOptions] = useState<any[]>([]);
  useEffect(() => {
    let options: any[] = [];
    educations.map((e) => {
      options.push({
        key: e.educationId,
        text: e.name,
        itemType: DropdownMenuItemType.Header,
      });

      e.classrooms?.map((c) => {
        options.push({
          key: c.classroomId,
          text: props.multiSelect ? c.acronym : c.name,
        });
        return c;
      });

      return e;
    });
    setClassroomOptions(options);
  }, [educations, props.multiSelect]);

  return (
    <>
      {classroomOptions.length > 0 && (
        <Dropdown
          {...props}
          options={classroomOptions}
          style={{ width: "100%" }}
        />
      )}
    </>
  );
}

ClassroomsDropDown.defaultProps = {
  multiSelect: false,
  selectedKeys: null,
  selectedKey: null,
  label: null,
  placeholder: null,
  required: false,
  disabled: false
};
