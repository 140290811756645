import React from "react";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import CRUDParent from "../CRUD/CRUDParent";
import { ParentDto } from "../../api-clients/api";

export default function NewStudent() {
  return (
    <Card>
      <CardHeader title="Création parent" />
      <CardContent>
        <CRUDParent parent={{} as ParentDto} />
      </CardContent>
    </Card>
  );
}