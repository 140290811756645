import { Card, CardContent, CardHeader } from "@material-ui/core";
import React, { useContext } from "react";
import { InstitutionContext } from "../../contexts/InstitutionContext";
import { SchoolContext } from "../../contexts/SchoolContext";
import CRUDPayment from "../CRUD/CRUDPayment";

export default function NewExpense() {
  const { accademicYear } = useContext(SchoolContext);
  const { institution } = useContext(InstitutionContext);

  return (
    <Card>
      <CardHeader title="Nouveau paiement" />
      <CardContent>
        <CRUDPayment
          sm={12}
          md={6}
          accademicYearId={accademicYear?.accademicYearId ?? ""}
          institutionId={institution?.institutionId ?? ""}
        />
      </CardContent>
    </Card>
  );
}
