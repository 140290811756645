import {
  IBasePickerSuggestionsProps,
  initializeIcons,
  IPersonaProps,
  NormalPeoplePicker,
} from "@fluentui/react";
import React, { useContext, useState } from "react";
import { getApiUrl } from "../../api-clients";
import { StudentApi, StudentFilterDto } from "../../api-clients/api";
import { SchoolContext } from "../../contexts/SchoolContext";
import DeleteButtonCallout from "./DeleteButtonCallout";

const { v4: uuidv4 } = require("uuid");

initializeIcons();

interface IProps {
  onStudentSelected: Function;
  onListChanged: (students: IPersonaProps[]) => void;
  selectedStudents: IPersonaProps[];
  onRemoveStudent: (studentId: string) => void;
  title: string;
  allAccademicYears: boolean;
  maxSelect?: number;
}
export default function StudentIdPicker({
  onStudentSelected,
  onListChanged,
  selectedStudents,
  onRemoveStudent,
  title,
  allAccademicYears,
  maxSelect,
}: IProps) {
  const [uniqueId] = useState<string>(uuidv4());
  const { accademicYear } = useContext(SchoolContext);
  const [studentToRemove, setStudentToRemove] =
    useState<IPersonaProps | null>(null);
  const [studentApi] = useState<StudentApi>(new StudentApi(getApiUrl()));
  const [filter] = useState<StudentFilterDto>({
    accademicYearId: allAccademicYears ? null : accademicYear?.accademicYearId,
    // ignoreAccademicYearIds: !allAccademicYears ? null : [accademicYear?.accademicYearId],
    pageSize: 20,
  } as StudentFilterDto);

  const suggestionProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: "Élèves suggérés",
    mostRecentlyUsedHeaderText: "Élèves suggérés",
    noResultsFoundText: "Aucun résultat",
    loadingText: "Chargement",
    showRemoveButtons: false,
    suggestionsAvailableAlertText:
      "Suggestions de sélection d'étudiants disponibles",
    suggestionsContainerAriaLabel: "Élèves suggérés",
  };

  const onFilterChanged = (
    input: string,
    selectedItems?: IPersonaProps[] | undefined
  ): IPersonaProps[] | PromiseLike<IPersonaProps[]> => {
    return studentApi
      .filter({
        ...filter,
        filter: input,
        ignoreIds: selectedItems?.map((s) => s.id),
      } as StudentFilterDto)
      .then((res) => {
        if (res && res.data && res.data.length > 0) {
          return res.data.map((s) => {
            return {
              id: s.studentId,
              text: `${s.firstName} ${s.lastName}`,
              secondaryText: `${s.classroomAcronym} - ${s.accademicYearStartDate?.getFullYear()}/${s.accademicYearEndDate?.getFullYear()}`,
              tertiaryText: s.accademicYearId
            } as IPersonaProps;
          });
        } else return [];
      });
  };

  const onItemSelected = (
    selectedItem?: IPersonaProps
  ): IPersonaProps | PromiseLike<IPersonaProps> | null => {
    if (selectedItem) onStudentSelected(selectedItem);
    return null;
  };

  return (
    <div>
      <div>
        {title}
        <NormalPeoplePicker
          className={uniqueId}
          onResolveSuggestions={(
            input: string,
            selectedItems?: IPersonaProps[] | undefined
          ): IPersonaProps[] | PromiseLike<IPersonaProps[]> => {
            if (maxSelect == null || selectedStudents.length < maxSelect)
              return onFilterChanged(input, selectedItems);

            return [];
          }}
          resolveDelay={300}
          pickerSuggestionsProps={suggestionProps}
          selectedItems={selectedStudents}
          onItemSelected={onItemSelected}
          disabled={studentToRemove != null}
          onChange={(items) => {
            onListChanged(items ?? []);
          }}
        />
      </div>
      <DeleteButtonCallout
        uniqueId={uniqueId}
        isCalloutVisible={studentToRemove != null}
        setIsCalloutVisible={(isVisible) => {
          if (!isVisible) setStudentToRemove(null);
        }}
        confirmAction={() => {
          onRemoveStudent(studentToRemove?.id ?? "");
          setStudentToRemove(null);
        }}
        title="Retirer de la liste"
        content="Si vous retirez cet element de la liste vous supprimez les montants de paiement liés à cet élève."
        titleRemoveButton="Retirer"
      />
    </div>
  );
}

StudentIdPicker.defaultProps = {
  title: "Élèves",
  allAccademicYears: false,
};
