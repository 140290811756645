import React, { useContext, useState } from "react";
import { CircularProgress, Grid } from "@material-ui/core";
import { TextField } from "office-ui-fabric-react";
import { InstitutionContext } from "../../contexts/InstitutionContext";
import {
  Institution,
  InstitutionApi,
  InstitutionDto,
} from "../../api-clients/api";
import { getApiUrl } from "../../api-clients";
import FormButtons from "../Shared/FormButtons";

export default function SchoolSettings() {
  const { institution, institutionUpdated } = useContext(InstitutionContext);
  const [saving, setSaving] = useState<boolean>(false);
  const [institutionApi] = useState<InstitutionApi>(
    new InstitutionApi(getApiUrl())
  );
  const [institutionForm, setInstitutionForm] =
    useState<Institution | null>(institution);
  const canSave: boolean =
    institutionForm != null &&
    institutionForm.name != null &&
    institutionForm.city != null &&
    institutionForm.name.length > 0 &&
    institutionForm.city.length > 0;

  const save = (values: InstitutionDto) => {
    if (institution && institution.institutionId) {
      setSaving(true);
      institutionApi
        .update(institution.institutionId, values)
        .then(() => {
          setSaving(false);
          institutionUpdated();
        })
        .catch((er) => {
          console.log(er);
          setSaving(false);
        });
    }
  };

  return (
    <Grid>
      {institutionForm && (
        <>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Nom de l'établissement"
              placeholder="Nom de l'établissement"
              required
              maxLength={200}
              value={institutionForm.name}
              onChange={(
                event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                newValue?: string
              ) =>
                setInstitutionForm({
                  ...institutionForm,
                  name: newValue,
                } as Institution)
              }
            />
            <TextField
              label="Ville"
              placeholder="Ville"
              required
              maxLength={200}
              value={institutionForm.city}
              onChange={(
                event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                newValue?: string
              ) =>
                setInstitutionForm({
                  ...institutionForm,
                  city: newValue,
                } as Institution)
              }
            />
            <TextField
              label="Adresse"
              placeholder="Adresse"
              maxLength={500}
              value={institutionForm.address}
              onChange={(
                event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                newValue?: string
              ) =>
                setInstitutionForm({
                  ...institutionForm,
                  address: newValue,
                } as Institution)
              }
            />
            <TextField
              label="Téléphone"
              placeholder="Téléphone"
              maxLength={500}
              value={institutionForm.telephoneContact}
              onChange={(
                event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                newValue?: string
              ) =>
                setInstitutionForm({
                  ...institutionForm,
                  telephoneContact: newValue,
                } as Institution)
              }
            />
            <TextField
              label="E-Mail"
              placeholder="E-Mail"
              maxLength={500}
              value={institutionForm.emailContact}
              onChange={(
                event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                newValue?: string
              ) =>
                setInstitutionForm({
                  ...institutionForm,
                  emailContact: newValue,
                } as Institution)
              }
            />
            <TextField
              label="Site web"
              placeholder="Site web"
              maxLength={500}
              value={institutionForm.website}
              onChange={(
                event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                newValue?: string
              ) =>
                setInstitutionForm({
                  ...institutionForm,
                  website: newValue,
                } as Institution)
              }
            />
          </Grid>
          <Grid xs={12}>
            <FormButtons
              disabled={saving || !canSave}
              cancelButtonVisible={false}
              onConfirmClick={() => {
                save(institutionForm);
              }}
            />
            {saving && (
              <CircularProgress
                size={24}
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: -12,
                  marginLeft: -12,
                }}
              />
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
}
