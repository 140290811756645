import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  CardActions,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { PaymentApi, PaymentDto } from "../../api-clients/api";
import { getApiUrl } from "../../api-clients";
import Loading from "../Shared/Loading";
import NoResult from "../Shared/NoResult";
import {
  numberToMad,
  dateToString,
  paymentTermTypeToString,
} from "../../helper/index";
import { InstitutionContext } from "../../contexts/InstitutionContext";
import StudentPaymentDetail from "./StudentPaymentDetail";
import DeleteButtonCallout from "../Shared/DeleteButtonCallout";
import { Panel } from "@fluentui/react";
import EditPayment from "./EditPayment";
import FormButtons from "../Shared/FormButtons";
import { SchoolContext } from "../../contexts/SchoolContext";
import PaymentPdf from "./PaymentPdf";
import { isMobile } from "react-device-detect";

export default function CardPayment() {
  const { institution } = useContext(InstitutionContext);
  const { setPaymentUpdated, paymentUpdated, accademicYear } =
    useContext(SchoolContext);
  const history = useHistory();
  const { paymentId }: any = useParams();
  const [paymentApi] = useState<PaymentApi>(new PaymentApi(getApiUrl()));
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [isCalloutVisible, setIsCalloutVisible] = useState<boolean>(false);
  const [result, setResult] = useState<PaymentDto | null>(null);
  const [paymentToEdit, setPaymentToEdit] = useState<PaymentDto | null>(null);

  useEffect(() => {
    if (paymentUpdated) {
      setPaymentUpdated(false);
      setPaymentToEdit(null);
    }

    paymentApi
      .byId(paymentId)
      .then((res) => {
        setResult(res);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [
    paymentApi,
    paymentId,
    setPaymentUpdated,
    paymentUpdated,
    setPaymentToEdit,
  ]);

  const deletePayment = () => {
    if (result != null && result.paymentId != null) {
      setDeleting(true);
      paymentApi
        .paymentDelete(result.paymentId)
        .then((res) => history.push("/payments"));
    }
  };

  return (
    <Container maxWidth="md">
      {!isLoading ? (
        result ? (
          <>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item md={4} sm={6} xs={12}>
                    <img
                      src={`data:image/jpeg;base64,${institution?.logo}`}
                      alt="logo"
                      style={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item md={8} sm={6} xs={12} style={{ textAlign: "end" }}>
                    <Typography
                      variant="subtitle1"
                      color="primary"
                      gutterBottom
                    >
                      {`Reçu ${result.paymentNumber}`}
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom>
                      {`Encodé le ${dateToString(result.creationDate)}`}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
              <CardContent>
                <Grid container spacing={1}>
                  {result.studentPayments &&
                    result.studentPayments.map((sp) => (
                      <Grid
                        item
                        md={4}
                        sm={6}
                        xs={12}
                        key={sp.studentPaymentId}
                      >
                        <StudentPaymentDetail studentPayment={sp} />
                      </Grid>
                    ))}
                </Grid>
                <Grid container spacing={1} style={{ marginTop: "20px" }}>
                  {result.comment != null && result.comment.trim().length > 0 && (
                    <Grid item sm={12} style={{ textAlign: "end" }}>
                      <Typography variant="caption">
                        Commentaire: {result.comment}
                      </Typography>
                    </Grid>
                  )}
                  <Grid item sm={6} xs={12}>
                    <Typography variant="h5" color="primary">
                      {numberToMad(result.totalAmount)}
                    </Typography>
                  </Grid>
                  <Grid item sm={6} xs={12} style={{ textAlign: "end" }}>
                    <Typography variant="body1" color="textSecondary">
                      {`Payé le ${dateToString(
                        result.date
                      )}, en ${paymentTermTypeToString(
                        result.paymentTerm
                      )} par ${result.paidBy}`}
                    </Typography>
                  </Grid>
                </Grid>
                {result != null && isMobile && accademicYear && institution && (
                  <Grid xs={12}>
                    <PaymentPdf
                      paymentId={paymentId}
                      payment={result}
                      accademicYear={accademicYear}
                      institution={institution}
                    />
                  </Grid>
                )}
              </CardContent>
              <CardActions disableSpacing>
                <FormButtons
                  disabled={deleting}
                  confirmText="Modifier"
                  onConfirmClick={() => setPaymentToEdit(result)}
                  onDeleteClick={() => deletePayment()}
                  deleteButtonId={result.paymentId}
                  confirmIcon="PageEdit"
                  deleteButtonVisible={true}
                  cancelButtonVisible={false}
                />
                {result != null && !isMobile && accademicYear && institution && (
                  <div style={{ marginLeft: "16px" }}>
                    <PaymentPdf
                      paymentId={paymentId}
                      payment={result}
                      accademicYear={accademicYear}
                      institution={institution}
                    />
                  </div>
                )}
              </CardActions>
            </Card>
            <DeleteButtonCallout
              uniqueId={`delete-${result.paymentId}`}
              setIsCalloutVisible={setIsCalloutVisible}
              isCalloutVisible={isCalloutVisible}
              confirmAction={() => deletePayment()}
              title="Suppression paiement"
              content="Confirmez vous la suppression de ce paiement?"
              titleRemoveButton="Supprimer"
            />
          </>
        ) : (
          <NoResult />
        )
      ) : (
        <Loading />
      )}
      <Panel
        isBlocking={false}
        headerText="Modifier paiement"
        isOpen={paymentToEdit != null}
        onDismiss={() => setPaymentToEdit(null)}
        closeButtonAriaLabel="Fermer"
      >
        <EditPayment payment={paymentToEdit} />
      </Panel>
    </Container>
  );
}
