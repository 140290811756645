import { IDatePickerStrings } from "@fluentui/react";
import { PaymentTermType } from "../api-clients/api";

var numeral = require("numeral");

export const getCulture = () => {
  return "fr-FR";
};

export const numberToCurrency = (value: number, currency: string) => {
  return new Intl.NumberFormat(getCulture(), {
    style: "currency",
    currency: currency,
  }).format(value);
};

export const numberToMad = (value?: number) => {
  return new Intl.NumberFormat(getCulture(), {
    style: "currency",
    currency: "MAD",
  })
    .format(value ?? 0)
    .replace("MAD", "dh");
};

export const numberToMadPdf = (value?: number) => {
  return value === 0
    ? "-"
    : new Intl.NumberFormat(getCulture(), {
        style: "currency",
        currency: "MAD",
      })
        .format(value ?? 0)
        .replace("MAD", "dh")
        .replace("\u202f", " ");
};

export const numberCompacted = (value: number) => {
  return numeral(value).format("(0.0a)");
};

export const numberToShortMad = (value: number) => {
  return "MAD " + numeral(value).format("(0.0a)");
};

export const dateToString = (date?: Date): string => {
  if (date) return date.toLocaleDateString("fr-FR");
  else return "";
};

export const paymentTermTypeToString = (
  paymentTermType?: PaymentTermType
): string => {
  switch (paymentTermType) {
    case +PaymentTermType._1:
      return "lettre de change";
    case +PaymentTermType._2:
      return "espèces";
    case +PaymentTermType._3:
      return "virement";
    case +PaymentTermType._4:
      return "versement";
    case +PaymentTermType._5:
      return "chèque";
    case +PaymentTermType._6:
      return "autre";

    default:
      return "";
  }
};

export interface HeaderDetail {
  name: string;
  label: string;
  formatToMoney: boolean;
  currency: string;
}

export const monthName = (month: number) => {
  switch (month) {
    case 1:
      return "Janvier";
    case 2:
      return "Fevrier";
    case 3:
      return "Mars";
    case 4:
      return "Avril";
    case 5:
      return "Mai";
    case 6:
      return "Juin";
    case 7:
      return "Juillet";
    case 8:
      return "Aôut";
    case 9:
      return "Septembre";
    case 10:
      return "Octobre";
    case 11:
      return "Novembre";
    case 12:
      return "Decembre";
    default:
      return month.toString();
  }
};

export const DayPickerStrings: IDatePickerStrings = {
  months: [
    "Janvier",
    "Fevrier",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Aôut",
    "Septembre",
    "Octobre",
    "Novembre",
    "Decembre",
  ],

  shortMonths: [
    "Jan",
    "Fev",
    "Mar",
    "Avr",
    "Mai",
    "Jun",
    "Jul",
    "Aou",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],

  days: [
    "Dimanche",
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
  ],

  shortDays: ["D", "L", "M", "M", "J", "V", "S"],

  goToToday: "Aujourd'hui",
  prevMonthAriaLabel: "Aller au mois précédent",
  nextMonthAriaLabel: "Aller au mois prochain",
  prevYearAriaLabel: "Aller à l'année précédente",
  nextYearAriaLabel: "Aller à l'année prochaine",
  closeButtonAriaLabel: "Fermer",
  monthPickerHeaderAriaLabel: "{0}, sélectionner pour changer l'année",
  yearPickerHeaderAriaLabel: "{0}, sélectionner pour changer le mois",

  isRequiredErrorMessage: "La date est requise.",

  invalidInputErrorMessage: "Format de date non valide.",
};
