import React from "react";
import { Grid } from "@material-ui/core";
import { green, red, yellow, blueGrey } from "@material-ui/core/colors";
import { DashboardEducationDto } from "../../api-clients/api";
import LabelFieldValue from "../Shared/LabelFieldValue";
import Loading from "../Shared/Loading";
import { numberToMad } from "../../helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserGraduate,
  faBus,
  faChild,
} from "@fortawesome/free-solid-svg-icons";

interface IProps {
  dashboard: DashboardEducationDto | null;
}
export default function CardContentEducation({ dashboard }: IProps) {
  return (
    <div>
      {dashboard != null ? (
        <>
          <Grid container>
            <Grid item xs={2}>
              <LabelFieldValue
                label=""
                tooltip="Élèves"
                icon={
                  <FontAwesomeIcon
                    icon={faUserGraduate}
                    style={{ color: blueGrey[700] }}
                  />
                }
                value={dashboard.numberStudent}
              />
            </Grid>
            <Grid item xs={2}>
              <LabelFieldValue
                label=""
                tooltip="Transport"
                icon={
                  <FontAwesomeIcon
                    icon={faBus}
                    style={{ color: yellow[900] }}
                  />
                }
                value={dashboard.numberStudentTransport}
              />
            </Grid>
            <Grid item xs={2}>
              <LabelFieldValue
                label=""
                icon={<FontAwesomeIcon icon={faChild} />}
                tooltip="Garderie"
                value={dashboard.numberStudentNursery}
              />
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={6}>
              <LabelFieldValue
                label="Paiements attendus"
                value={numberToMad(dashboard.totalStudentCost ?? 0)}
              />
            </Grid>
            <Grid item xs={6} style={{ textAlign: "right" }}>
              <LabelFieldValue
                label={
                  (dashboard.totalPayment ?? 0) -
                    (dashboard.totalStudentCost ?? 0) <
                  0
                    ? "Impayé"
                    : "Solde paiements"
                }
                valueColor={
                  (dashboard.totalPayment ?? 0) -
                    (dashboard.totalStudentCost ?? 0) <
                  0
                    ? red[500]
                    : green[500]
                }
                value={numberToMad(
                  (dashboard.totalPayment ?? 0) -
                    (dashboard.totalStudentCost ?? 0)
                )}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
}
