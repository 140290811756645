import React, { useContext, useState } from "react";
import {
  CircularProgress,
  Divider,
  Grid,
  GridSize,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { StudentApi, StudentCreationDto } from "../../api-clients/api";
import { Checkbox, IDropdownOption, Slider, TextField } from "@fluentui/react";
import FluentUiDatePicker from "../Shared/FluentUiDatePicker";
import ClassroomsDropDown from "../Shared/ClassroomsDropdown";
import { SchoolContext } from "../../contexts/SchoolContext";
import { getApiUrl } from "../../api-clients";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../contexts/AppContext";
import FormButtons from "../Shared/FormButtons";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

interface IProps {
  md: GridSize;
  xs: GridSize;
  sm: GridSize;
}
export default function CRUDStudentPath({ md, sm, xs }: IProps) {
  const classes = useStyles();
  const history = useHistory();
  const { openSnackbar } = useContext(AppContext);
  const [saving, setSaving] = useState<boolean>(false);
  const [studentApi] = useState<StudentApi>(new StudentApi(getApiUrl()));
  const { accademicYear, setStudentUpdated } =
    useContext(SchoolContext);
  const [newStudent, setNewStudent] = useState<StudentCreationDto>({
    startDate: accademicYear != null && accademicYear.startDate != null && accademicYear.startDate > new Date() ? accademicYear.startDate : new Date(),
    accademicYearId: accademicYear?.accademicYearId,
    inscriptionFees: accademicYear?.inscriptionFees,
    monthlyFees: accademicYear?.monthlyFees,
    endDate: accademicYear?.endDate,
  } as StudentCreationDto);

  const canSave: boolean =
    (newStudent.firstName?.length ?? 0) > 0 &&
    (newStudent.lastName?.length ?? 0) > 0 &&
    (newStudent.classroomId?.length ?? 0) > 0;

  const SaveStudent = () => {
    setSaving(true);
    studentApi
      .create(newStudent)
      .then((res) => {
        openSnackbar("Élève bien enregistré", "success");
        setStudentUpdated(true);
        history.push("/student/" + res.studentId);
      })
      .catch((err) => {
        setSaving(false);
        openSnackbar("Une erreur s'est reproduite", "error");
      });
  };

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item sm={6} xs={12}>
          <TextField
            autoComplete="off"
            required
            label="Nom de famille"
            placeholder="Nom de famille"
            value={newStudent.lastName}
            onChange={(
              event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
              newValue?: string
            ) =>
              setNewStudent({
                ...newStudent,
                lastName: newValue,
              } as StudentCreationDto)
            }
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            required
            autoComplete="off"
            label="Prénom"
            placeholder="Prénom"
            value={newStudent.firstName}
            onChange={(
              event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
              newValue?: string
            ) =>
              setNewStudent({
                ...newStudent,
                firstName: newValue,
              } as StudentCreationDto)
            }
          />
        </Grid>
        <Grid item xs={12}>
          <FluentUiDatePicker
            label="Date de naissance"
            placeholder="Date de naissance"
            value={newStudent.dateOfBirth}
            onSelectDate={(date: Date | null | undefined): void => {
              setNewStudent({
                ...newStudent,
                dateOfBirth: date,
              } as StudentCreationDto);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            Inscription année {accademicYear?.startDate?.getFullYear()}/
            {accademicYear?.endDate?.getFullYear()}
          </Typography>
        </Grid>
        <Grid item sm={sm} xs={xs} md={md}>
          <FluentUiDatePicker
            required
            label="Date de début"
            placeholder="Date de début"
            value={newStudent.startDate}
            minDate={accademicYear?.startDate}
            maxDate={accademicYear?.endDate}
            onSelectDate={(date: Date | null | undefined): void => {
              setNewStudent({
                ...newStudent,
                startDate: date,
              } as StudentCreationDto);
            }}
          />
        </Grid>
        <Grid item sm={sm} xs={xs} md={md}>
          <ClassroomsDropDown
            required
            label="Classe"
            placeholder="Selectionner la classe"
            selectedKey={newStudent.classroomId}
            onChange={(
              event: React.FormEvent<HTMLDivElement>,
              item?: IDropdownOption
            ): void => {
              if (item)
                setNewStudent({
                  ...newStudent,
                  classroomId: item.key,
                } as StudentCreationDto);
            }}
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          <Slider
            label="Frais d'inscription (dirham)"
            max={accademicYear?.inscriptionFees ?? 2000}
            value={newStudent.inscriptionFees ?? 0}
            defaultValue={newStudent.inscriptionFees ?? 0}
            step={10}
            showValue
            onChange={(value: number) =>
              setNewStudent({
                ...newStudent,
                inscriptionFees: value,
              } as StudentCreationDto)
            }
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          <Slider
            label="Frais de scolarité (dirham)"
            max={accademicYear?.monthlyFees ?? 2000}
            value={newStudent.monthlyFees ?? 0}
            defaultValue={newStudent.monthlyFees ?? 0}
            step={10}
            showValue
            onChange={(value: number) =>
              setNewStudent({
                ...newStudent,
                monthlyFees: value,
              } as StudentCreationDto)
            }
          />
        </Grid>
        {accademicYear?.hasTransport &&
          <Grid item sm={6} md={4} xs={12}>
            <Checkbox
              label="Transport"
              checked={newStudent.hasTransport} onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) =>
                setNewStudent({ ...newStudent, hasTransport: checked } as StudentCreationDto)}
            />
            <TextField
              required
              disabled={!newStudent.hasTransport}
              label="Frais transport (dirham)"
              placeholder="Frais transport mensuel (dirham)"
              value={newStudent.transportFees ? newStudent.transportFees?.toString() : accademicYear.transportFees?.toString()}
              type="number"
              lang="fr-FR"
              min="0"
              onChange={(
                event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                newValue?: string
              ) => {
                if (!isNaN(Number(newValue))) {
                  let num: number = Number(newValue);
                  if (num < 0) num = num * -1;
                  setNewStudent({ ...newStudent, transportFees: Number(num.toFixed(2)) } as StudentCreationDto)
                }
              }}
            />
          </Grid>
        }

        {accademicYear?.hasChildCare &&
          <Grid item sm={6} md={4} xs={12}>
            <Checkbox
              label="Garderie"
              checked={newStudent.hasChildCare} onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) =>
                setNewStudent({ ...newStudent, hasChildCare: checked } as StudentCreationDto)}
            />
            <TextField
              required
              disabled={!newStudent.hasChildCare}
              label="Frais garderie (dirham)"
              placeholder="Frais garderie mensuel (dirham)"
              value={newStudent.childCareFees ? newStudent.childCareFees?.toString() : accademicYear.childCareFees?.toString()}
              type="number"
              lang="fr-FR"
              min="0"
              onChange={(
                event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                newValue?: string
              ) => {
                if (!isNaN(Number(newValue))) {
                  let num: number = Number(newValue);
                  if (num < 0) num = num * -1;
                  setNewStudent({ ...newStudent, childCareFees: Number(num.toFixed(2)) } as StudentCreationDto)
                }
              }}
            />
          </Grid>
        }

        {accademicYear?.hasCanteen &&
          <Grid item sm={6} md={4} xs={12}>
            <Checkbox
              label="Cantine"
              checked={newStudent.hasCanteen} onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) =>
                setNewStudent({ ...newStudent, hasCanteen: checked } as StudentCreationDto)}
            />
            <TextField
              required
              disabled={!newStudent.hasCanteen}
              label="Frais garderie (dirham)"
              placeholder="Frais garderie mensuel (dirham)"
              value={newStudent.canteenFees ? newStudent.canteenFees?.toString() : accademicYear.canteenFees?.toString()}
              type="number"
              lang="fr-FR"
              min="0"
              onChange={(
                event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                newValue?: string
              ) => {
                if (!isNaN(Number(newValue))) {
                  let num: number = Number(newValue);
                  if (num < 0) num = num * -1;
                  setNewStudent({ ...newStudent, canteenFees: Number(num.toFixed(2)) } as StudentCreationDto)
                }
              }}
            />
          </Grid>
        }
      </Grid>
      <div className={classes.wrapper}>
        <FormButtons
          onConfirmClick={SaveStudent}
          disabled={saving || !canSave}
          cancelButtonVisible={false}
          confirmIcon="AddFriend"
          confirmText="Créer"
        />
        {saving && (
          <CircularProgress
            size={24}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: -12,
              marginLeft: -12,
            }}
          />
        )}
      </div>
    </div>
  );
}
