import React from "react";
import { Grid, Typography } from "@material-ui/core";
import AppLabel from "../Shared/AppLabel";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import { numberToMad } from "../../helper";
import { green, red, blue, purple } from "@material-ui/core/colors";
import PaymentIcon from "@material-ui/icons/Payment";
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";
import { useId } from "@fluentui/react-hooks";
import { Stack, TooltipHost } from "@fluentui/react";
import { isMobile } from "react-device-detect";

interface IProps {
  todayCost: number;
  totalPayments: number;
  studentBalance: number;
  entierServicesCost: number;
  showInline: boolean;
}
export default function StudentPaymentResume({
  todayCost,
  totalPayments,
  studentBalance,
  entierServicesCost,
  showInline,
}: IProps) {
  const labelCost: string = "Montant à payer";
  const labelPaid: string = "Montant payé";
  const labelBalance: string = "Solde";
  const labelProspectus: string = "Prospectus année scolaire";
  const tooltip1 = useId("tooltip");
  const tooltip2 = useId("tooltip");
  const tooltip3 = useId("tooltip");
  const tooltip4 = useId("tooltip");
  const tooltip5 = useId("tooltip");
  const tooltip6 = useId("tooltip");
  const tooltip7 = useId("tooltip");
  const tooltip8 = useId("tooltip");
  const width: 3 | 6 = showInline && !isMobile ? 3 : 6;

  return (
    <div>
      <AppLabel label="Résumé paiements" />
      <Grid container>
        <Grid item xs={width}>
          <Stack horizontal tokens={{ childrenGap: 16 }}>
            <TooltipHost content={labelCost} id={tooltip1}>
              <AttachMoneyIcon style={{ color: purple[500] }} />
            </TooltipHost>
            <TooltipHost content={labelCost} id={tooltip2}>
              <Typography variant="body1">{numberToMad(todayCost)}</Typography>
            </TooltipHost>
          </Stack>
        </Grid>

        <Grid item xs={width}>
          <Stack horizontal tokens={{ childrenGap: 16 }}>
            <TooltipHost content={labelPaid} id={tooltip3}>
              <PaymentIcon style={{ color: green[500] }} />
            </TooltipHost>
            <TooltipHost content={labelPaid} id={tooltip4}>
              <Typography variant="body1">
                {numberToMad(totalPayments)}
              </Typography>
            </TooltipHost>
          </Stack>
        </Grid>

        <Grid item xs={width}>
          <Stack horizontal tokens={{ childrenGap: 16 }}>
            <TooltipHost content={labelBalance} id={tooltip5}>
              <PaymentIcon
                style={{
                  color: studentBalance < 0 ? red[500] : blue[500],
                }}
              />
            </TooltipHost>
            <TooltipHost content={labelBalance} id={tooltip6}>
              <Typography variant="body1">
                {numberToMad(studentBalance)}
              </Typography>
            </TooltipHost>
          </Stack>
        </Grid>

        <Grid item xs={width}>
          <Stack horizontal tokens={{ childrenGap: 16 }}>
            <TooltipHost content={labelProspectus} id={tooltip7}>
              <EmojiObjectsIcon style={{ color: blue[500] }} />
            </TooltipHost>
            <TooltipHost content={labelProspectus} id={tooltip8}>
              <Typography variant="body1">
                {numberToMad(entierServicesCost)}
              </Typography>
            </TooltipHost>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
}

StudentPaymentResume.defaultProps = {
  showInline: false,
};
