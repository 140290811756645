import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import BusinessIcon from "@material-ui/icons/Business";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Container } from "@material-ui/core";
import SchoolSettings from "./SchoolSettings";
import PrintSettings from "./PrintSettings";
import SecuritySettings from "./SecuritySettings";
import PrintSharpIcon from "@material-ui/icons/PrintSharp";
import SecurityIcon from "@material-ui/icons/Security";

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function Settings() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  return (
    <Container maxWidth="lg">
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="on"
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable force tabs example"
          >
            <Tab
              label="Établissement"
              icon={<BusinessIcon />}
              {...a11yProps(0)}
            />
            <Tab
              label="Impression"
              icon={<PrintSharpIcon />}
              {...a11yProps(1)}
            />
            {/* <Tab label="Securité" icon={<SecurityIcon />} {...a11yProps(2)} /> */}
            {/* <Tab
              label="Comptabilité"
              icon={<BarChartIcon />}
              {...a11yProps(1)}
            /> */}
            {/* <Tab
              label="Années scolaires"
              icon={<SchoolIcon />}
              {...a11yProps(2)}
            /> */}
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <SchoolSettings />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <PrintSettings />
        </TabPanel>
        {/* <TabPanel value={value} index={2}>
          <SecuritySettings />
        </TabPanel> */}
        {/* <TabPanel value={value} index={1}>
          <AccountingSettings />
        </TabPanel> */}
        {/* <TabPanel value={value} index={2}>
          <AccademicYearSettings />
        </TabPanel> */}
      </div>
    </Container>
  );
}
