import React, { useContext } from "react";
import { Grid } from "@material-ui/core";
import { SchoolContext } from "../../contexts/SchoolContext";
import CardEducation from "./CardEducation";

export default function Educations() {
  const { educations } = useContext(SchoolContext);

  return (
    <Grid container spacing={1}>
      {educations.map((e) => (
        <Grid item lg={3} md={6} sm={6} xs={12} key={`education_${e.educationId}`}>
          <CardEducation education={e} />
        </Grid>
      ))}
    </Grid>
  );
}
