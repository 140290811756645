import React, { useContext, useState, useEffect } from "react";
import { Container, Grid } from "@material-ui/core";
import { SchoolContext } from "../../contexts/SchoolContext";
import { useParams } from "react-router-dom";
import { ClassroomDto, EducationDto } from "../../api-clients/api";
import Loading from "../Shared/Loading";
import CardHeaderClassroom from "./CardHeaderClassroom";
import CardStudents from "../Students/CardStudents";

export default function Classroom() {
  const { educations } = useContext(SchoolContext);
  const [classroom, setClassroom] = useState<ClassroomDto | null>(null);
  const [education, setEducation] = useState<EducationDto | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { classroomId }: any = useParams();

  useEffect(() => {
    if (classroomId != null && educations != null && educations.length > 0) {
      const cr: ClassroomDto = educations
        .filter(
          (e: EducationDto) => e.classrooms != null && e.classrooms.length > 0
        )
        .map((e) => e.classrooms as ClassroomDto[])
        .reduce(function (a: ClassroomDto[], b: ClassroomDto[]) {
          return a.concat(b);
        })
        .filter((c) => c.classroomId === classroomId)[0];
      setClassroom(cr);
      setEducation(
        educations.filter((e) => e.educationId === cr.educationId)[0]
      );
      setIsLoading(false);
    }
  }, [classroomId, educations]);

  return (
    <Container maxWidth="lg">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {education != null && classroom != null && (
            <div>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <CardHeaderClassroom
                    educationName={education.name ?? ""}
                    classroom={classroom}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CardStudents classrooms={[classroom.classroomId]} />
                </Grid>
              </Grid>
            </div>
          )}
        </>
      )}
    </Container>
  );
}
