import React from "react";
import { Grid, CircularProgress } from "@material-ui/core";

export default function Loading() {
  return (
    <Grid container>
      <Grid item xs={12}>
        <CircularProgress />
      </Grid>
    </Grid>
  );
}
