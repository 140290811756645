import React, { useState, useEffect, useContext } from "react";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TablePagination,
  Typography,
} from "@material-ui/core";
import {
  ExpenseApi,
  ExpenseDto,
  ExpenseDtoAmountPaginatedDto,
  ExpenseFilterDto,
  SortOrderType,
} from "../../api-clients/api";
import { getApiUrl } from "../../api-clients";
import Loading from "../Shared/Loading";
import NoResult from "../Shared/NoResult";
import { dateToString, numberToMad } from "../../helper/index";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { SchoolContext } from "../../contexts/SchoolContext";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import { IDropdownOption, Panel, SearchBox } from "@fluentui/react";
import EditExpense from "./EditExpense";
import ExpenseFurnishersDropdown from "../Shared/ExpenseFurnishersDropdown";
import YearsDropdown from "../Shared/YearsDropdown";
import GetAppIcon from "@material-ui/icons/GetApp";
import OrderByDropdown from "../Shared/OrderByDropdown";
var FileSaver = require("file-saver");

initializeIcons();

export default function CardExpenses() {
  const { expenseUpdated, setExpenseUpdated } = useContext(SchoolContext);

  const [expenseApi] = useState<ExpenseApi>(new ExpenseApi(getApiUrl()));
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [expenseToEdit, setExpenseToEdit] = useState<ExpenseDto | null>(null);
  const [result, setResult] =
    useState<ExpenseDtoAmountPaginatedDto | null>(null);
  const [filter, setFilter] = useState<ExpenseFilterDto>({
    startDate: new Date(new Date().getFullYear(), 0, 1),
    endDate: new Date(new Date().getFullYear(), 11, 31),
    pageSize: 7,
    sortField: "Date",
    sortOrder: SortOrderType._1,
  } as ExpenseFilterDto);

  useEffect(() => {
    setIsLoading(true);
    setExpenseToEdit(null);
    setExpenseUpdated(false);
    expenseApi
      .filter(filter)
      .then((res) => {
        setResult(res);
        setIsLoading(false);
      })
      .catch((err) => setIsLoading(false));
  }, [expenseApi, filter, expenseUpdated, setExpenseUpdated]);

  return (
    <div>
      <Card>
        <CardHeader title="Dépenses" />
        <CardContent>
          <Grid container spacing={1}>
            <Grid item sm={4} xs={12}>
              <SearchBox
                placeholder="Recherche"
                iconProps={{ iconName: "Search" }}
                onClear={() =>
                  setFilter({
                    ...filter,
                    filter: "",
                  } as ExpenseFilterDto)
                }
                onSearch={(newValue: string) =>
                  setFilter({
                    ...filter,
                    filter: newValue,
                  } as ExpenseFilterDto)
                }
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <ExpenseFurnishersDropdown
                placeholder="Origine dépense"
                selectedKeys={filter.furnishers}
                multiSelect
                onChange={(
                  event: React.FormEvent<HTMLDivElement>,
                  item?: IDropdownOption
                ): void => {
                  if (item)
                    setFilter({
                      ...filter,
                      furnishers: item.selected
                        ? [...(filter.furnishers ?? []), item.key as string]
                        : (filter.furnishers ?? []).filter(
                            (key) => key !== item.key
                          ),
                    } as ExpenseFilterDto);
                }}
              />
            </Grid>
            <Grid item md={4} sm={12}>
              <YearsDropdown
                placeholder="Année"
                selectedKey={filter.startDate?.getFullYear()}
                onChange={(
                  event: React.FormEvent<HTMLDivElement>,
                  item?: IDropdownOption
                ): void => {
                  if (item && item.key)
                    setFilter({
                      ...filter,
                      startDate: new Date(+item.key, 0, 1),
                      endDate: new Date(+item.key, 11, 31),
                    } as ExpenseFilterDto);
                }}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <OrderByDropdown
                onChange={(
                  event: React.FormEvent<HTMLDivElement>,
                  option?: IDropdownOption,
                  index?: number
                ) => {
                  if (option) {
                    if (option.key === 1) {
                      setFilter({
                        ...filter,
                        sortField: "Date",
                        sortOrder: SortOrderType._1,
                      } as ExpenseFilterDto);
                    } else if (option.key === 2) {
                      setFilter({
                        ...filter,
                        sortField: "Date",
                        sortOrder: SortOrderType._0,
                      } as ExpenseFilterDto);
                    } else if (option.key === 3) {
                      setFilter({
                        ...filter,
                        sortField: "CreationDate",
                        sortOrder: SortOrderType._1,
                      } as ExpenseFilterDto);
                    }
                  }
                }}
                selectedKey={
                  filter.sortField === "CreationDate"
                    ? 3
                    : filter.sortField === "Date" &&
                      filter.sortOrder === SortOrderType._0
                    ? 2
                    : 1
                }
              />
            </Grid>

            <Grid item xs={12} sm={6} style={{ textAlign: "right" }}>
              {!isLoading && result != null && (
                <Typography variant="subtitle1">
                  Total {numberToMad(result.totalAmount)}
                </Typography>
              )}
            </Grid>
          </Grid>
          {!isLoading ? (
            result != null && result.data != null && result.data.length > 0 ? (
              <>
                <List>
                  {result.data.map((s) => (
                    <div key={s.expenseId}>
                      <ListItem
                        button
                        alignItems="flex-start"
                        onClick={() => setExpenseToEdit(s)}
                        style={{ paddingTop: "0px", paddingBottom: "0px" }}
                      >
                        <ListItemAvatar>
                          <Avatar>
                            <MoneyOffIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={numberToMad(s.amount ?? 0)}
                          secondary={
                            <React.Fragment>
                              <Typography
                                component="span"
                                variant="body2"
                                //   className={classes.inline}
                                color="textPrimary"
                              >
                                {s.furnisher?.name}
                              </Typography>
                              {" — "}
                              {dateToString(s.date)}
                              {s.comment ? ", " + s.comment : ""}
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      <Divider />
                    </div>
                  ))}
                </List>
                <TablePagination
                  component="div"
                  count={result.totalElements ?? 0}
                  page={filter.pageIndex ?? 0}
                  rowsPerPage={filter.pageSize ?? 0}
                  rowsPerPageOptions={[7]}
                  onPageChange={(event, newPage) => {
                    setFilter({
                      ...filter,
                      pageIndex: newPage,
                    } as ExpenseFilterDto);
                  }}
                  onRowsPerPageChange={(event) => {
                    setFilter({
                      ...filter,
                      pageIndex: 0,
                      pageSize: +event.target.value,
                    } as ExpenseFilterDto);
                  }}
                />
              </>
            ) : (
              <NoResult />
            )
          ) : (
            <Loading />
          )}
        </CardContent>
        {result != null && result.data != null && result.data.length > 0 && (
          <CardActions>
            <Button
              color="primary"
              size="small"
              startIcon={<GetAppIcon />}
              onClick={() => {
                expenseApi
                  .csv({
                    ...filter,
                    pageIndex: 0,
                    pageSize: 2147483640,
                  } as ExpenseFilterDto)
                  .then((res) => {
                    FileSaver.saveAs(res.data, "Dépenses.csv");
                  });
              }}
            >
              Télécharger
            </Button>
          </CardActions>
        )}
      </Card>
      <Panel
        isBlocking={true}
        headerText="Modifier dépense"
        isOpen={expenseToEdit != null}
        onDismiss={() => setExpenseToEdit(null)}
        closeButtonAriaLabel="Fermer"
      >
        <EditExpense expense={expenseToEdit} />
      </Panel>
    </div>
  );
}
