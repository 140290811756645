import React, { useContext, useState } from "react";
import { CircularProgress, Grid } from "@material-ui/core";
import { InstitutionContext } from "../../contexts/InstitutionContext";
import { Toggle } from "@fluentui/react/lib/Toggle";
import {
  Institution,
  InstitutionApi,
  InstitutionDto,
} from "../../api-clients/api";
import { getApiUrl } from "../../api-clients";
import FormButtons from "../Shared/FormButtons";

export default function PrintSettings() {
  const { institution, institutionUpdated } = useContext(InstitutionContext);
  const [saving, setSaving] = useState<boolean>(false);
  const [institutionApi] = useState<InstitutionApi>(
    new InstitutionApi(getApiUrl())
  );
  const [institutionForm, setInstitutionForm] = useState<Institution | null>(
    institution
  );
  const canSave: boolean =
    institutionForm != null &&
    institutionForm.name != null &&
    institutionForm.city != null &&
    institutionForm.name.length > 0 &&
    institutionForm.city.length > 0;

  const save = (values: InstitutionDto) => {
    if (institution && institution.institutionId) {
      setSaving(true);
      institutionApi
        .update(institution.institutionId, values)
        .then(() => {
          setSaving(false);
          institutionUpdated();
        })
        .catch((er) => {
          console.log(er);
          setSaving(false);
        });
    }
  };

  return (
    <Grid>
      {institutionForm && (
        <>
          <Grid item xs={12} sm={6}>
          <Toggle
              label="Imprimer le Qr Code"
              checked={institutionForm.printReceiptQrCode}
              onText="Oui"
              offText="Non"
              onChange={(
                ev: React.MouseEvent<HTMLElement>,
                checked?: boolean
              ) => {
                setInstitutionForm({
                  ...institutionForm,
                  printReceiptQrCode: checked ?? false,
                } as Institution);
              }}
            />
            <Toggle
              label="Imprimer le numéro de reçu"
              checked={institutionForm.printReceiptNumber}
              onText="Oui"
              offText="Non"
              onChange={(
                ev: React.MouseEvent<HTMLElement>,
                checked?: boolean
              ) => {
                setInstitutionForm({
                  ...institutionForm,
                  printReceiptNumber: checked ?? false,
                } as Institution);
              }}
            />

            <Toggle
              label="Format impression reçu"
              checked={institutionForm.receiptFormat === "A5" ? true : false}
              onText="A5"
              offText="A4"
              onChange={(
                ev: React.MouseEvent<HTMLElement>,
                checked?: boolean
              ) => {
                setInstitutionForm({
                  ...institutionForm,
                  receiptFormat: checked ? "A5" : "A4",
                } as Institution);
              }}
            />

            <Toggle
              label="Orientation reçu"
              checked={
                institutionForm.receiptOrientation === "landscape"
                  ? true
                  : false
              }
              onText="Portrait"
              offText="Paysage"
              onChange={(
                ev: React.MouseEvent<HTMLElement>,
                checked?: boolean
              ) => {
                setInstitutionForm({
                  ...institutionForm,
                  receiptOrientation: checked ? "landscape" : "portrait",
                } as Institution);
              }}
            />
          </Grid>
          <Grid xs={12}>
            <FormButtons
              disabled={saving || !canSave}
              cancelButtonVisible={false}
              onConfirmClick={() => {
                save(institutionForm);
              }}
            />
            {saving && (
              <CircularProgress
                size={24}
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: -12,
                  marginLeft: -12,
                }}
              />
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
}
