import { createMuiTheme } from "@material-ui/core/styles";

const sideMenuTheme = createMuiTheme({
  palette: {
    primary: {
      main: process.env.REACT_APP_PRIMARY_COLOR_MAIN as string,
      light: process.env.REACT_APP_PRIMARY_COLOR_LIGHT as string,
      dark: process.env.REACT_APP_PRIMARY_COLOR_DARK as string,
    },
    secondary: {
      main: "#FFFFFF",
      light: "#FFFFFF",
      dark: "#FFFFFF",
    },
  },
  overrides: {
    MuiContainer: {
      root: {
        paddingTop: "32px",
        paddingBottom: "32px",
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: "rgba(255,255,255,0.9)",
      },
    },
    MuiListItem: {
      root: {
        color: "white",
        "&$selected": {
          color: process.env.REACT_APP_BACKGROUND_DARK,
          backgroundColor: "white",
        },
      },

      button: {
        "&:hover": {
          backgroundColor: "#D44C42",
        },
      },
    },
  },
});

export default sideMenuTheme;
