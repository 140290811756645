import React from "react";
import { Typography } from "@material-ui/core";

export default function NoResult() {
  return (
    <Typography variant="caption" display="block">
      Aucun resultat
    </Typography>
  );
}
