import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
} from "@material-ui/core";
import {
  PaymentApi,
  StudentMonthlyPaymentSituationDto,
} from "../../api-clients/api";
import Loading from "../Shared/Loading";
import NoResult from "../Shared/NoResult";
import { Text } from "@fluentui/react";
import { green, red } from "@material-ui/core/colors";
import { getApiUrl } from "../../api-clients";

interface IProps {
  studentPathId: string;
}
export default function PaymentsMonthlySituation({ studentPathId }: IProps) {
  const [paymentApi] = useState<PaymentApi>(new PaymentApi(getApiUrl()));
  const [results, setResults] = useState<
    StudentMonthlyPaymentSituationDto[] | null
  >(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);
    paymentApi
      .monthPaymentsByStudentPathId(studentPathId)
      .then((res) => {
        setResults(res);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [paymentApi, studentPathId]);

  return (
    <>
      <Card>
        <CardHeader title="Situation annuel" />

        <CardContent>
          {!isLoading ? (
            results != null && results.length > 0 ? (
              <Grid container spacing={1}>
                {results.map((r) => (
                  <Grid item key={`${r.month}/${r.year}`}>
                    <Grid container alignItems="center">
                      <div>
                        <Text variant="mediumPlus" block>{`${
                          (r.month ?? "").toString().length > 1
                            ? r.month
                            : "0" + r.month
                        }/${r.year}`}</Text>
                        <Text
                          style={{
                            color: (r.isPaid
                              ? green[600]
                              : red[600]
                            ).toString(),
                          }}
                          variant="xSmall"
                          block
                        >
                          {r.isPaid ? "Payé" : "Non payé"}
                        </Text>
                      </div>
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <NoResult />
            )
          ) : (
            <Loading />
          )}
        </CardContent>
      </Card>
    </>
  );
}
