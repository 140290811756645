import React from "react";
import { Container, Grid } from "@material-ui/core";
import CardPayments from "./CardPayments";
import NewPayment from "./NewPayment";

export default function Payments() {
  return (
    <Container maxWidth="lg">
      <Grid container spacing={1}>
        <Grid item sm={6} xs={12}>
          <CardPayments />
        </Grid>
        <Grid item sm={6} xs={12}>
          <NewPayment />
        </Grid>
      </Grid>
    </Container>
  );
}
