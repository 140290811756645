import React, { useContext, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  Typography,
  IconButton,
  Link,
  CardActionArea,
} from "@material-ui/core";
import { ParentDto, StudentView } from "../../api-clients/api";
import LabelFieldValue from "../Shared/LabelFieldValue";
import AppLabel from "../Shared/AppLabel";
import { dateToString } from "../../helper";
import { useHistory } from "react-router";
import StudentPaymentResume from "./StudentPaymentResume";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { ContextualMenu } from "@fluentui/react";
import { AuthContext } from "../../contexts/AuthContext";
import { canDeleteStudent } from "../../api-clients/roles";

interface IProps {
  student: StudentView;
  parents: ParentDto[] | null;
  editStudent?: Function;
  deleteStudent?: Function;
  showNavigation: boolean;
  showContextMenu: boolean;
  showInline: boolean;
}
export default function StudentCard({
  student,
  parents,
  editStudent,
  deleteStudent,
  showNavigation,
  showContextMenu,
  showInline,
}: IProps) {
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const [showMenuContext, setShowMenuContext] = useState<boolean>(false);
  const menuItemRef = React.useRef(null);
  const title = `${student.firstName} ${student.lastName}`;
  const subtitle = `${student.accademicYearStartDate?.getFullYear()}/${student.accademicYearEndDate?.getFullYear()}, ${
    student.classroomName
  }`;
  const header = (
    <CardHeader
      title={title}
      subheader={subtitle}
      action={
        showContextMenu && (
          <IconButton
            aria-label="Menu"
            ref={menuItemRef}
            onClick={() => setShowMenuContext(true)}
          >
            <MoreVertIcon />
          </IconButton>
        )
      }
    />
  );
  const studentBalance: number =
    (student.totalReceivedPayments ?? 0) -
    (student.todayScolarshipCost + student.todayServicesCost);

  const contestualMenuItems = () => {
    var items = [
      {
        key: "edit",
        text: "Modifier",
        iconProps: { iconName: "EditContact" },
        onClick: () => {
          if (editStudent) editStudent();
        },
      },
    ];

    if (
      user != null &&
      user.roles != null &&
      user.roles.filter((r: string) => r === canDeleteStudent)
    ) {
      items.push({
        key: "delete",
        text: "Supprimer",
        iconProps: { iconName: "Delete" },
        onClick: () => {
          if (deleteStudent) deleteStudent();
        },
      });
    }
    return items;
  };

  return (
    <Card>
      {showNavigation ? (
        <CardActionArea
          onClick={() => history.push("/student/" + student.studentId)}
        >
          {header}
        </CardActionArea>
      ) : (
        header
      )}
      <ContextualMenu
        items={contestualMenuItems()}
        hidden={!showMenuContext}
        target={menuItemRef}
        onItemClick={() => setShowMenuContext(false)}
        onDismiss={() => setShowMenuContext(false)}
      />
      <CardContent>
        <Grid container>
          {student.dateOfBirth != null && (
            <Grid item xs={6}>
              <LabelFieldValue
                label="Age"
                value={
                  Math.abs(
                    new Date(
                      Date.now() - student.dateOfBirth.getTime()
                    ).getUTCFullYear() - 1970
                  ).toString() + " ans"
                }
              />
            </Grid>
          )}

          <Grid item xs={showInline ? 6 : 12}>
            <LabelFieldValue
              label="Date d'inscription"
              value={dateToString(student.studentPathStartDate)}
            />
          </Grid>
          <Grid item xs={showInline ? 6 : 12}>
            {parents != null && parents.length > 0 && (
              <div>
                <AppLabel label={`Parent${parents.length > 1 ? "s" : ""}`} />
                {parents.map((p) => (
                  <Typography key={p.parentId} variant="body1">
                    <Link
                      href={"/parent/" + p.parentId}
                      style={{ textAlign: "start" }}
                      onClick={(event: any) => {
                        event.preventDefault();
                        history.push("/parent/" + p.parentId);
                      }}
                    >
                      {p.firstName} {p.lastName}
                    </Link>
                  </Typography>
                ))}
              </div>
            )}
          </Grid>
        </Grid>

        {student != null && (
          <div style={{ marginTop: "10px" }}>
            <StudentPaymentResume
              todayCost={
                student.todayScolarshipCost + student.todayServicesCost
              }
              totalPayments={student.totalReceivedPayments}
              studentBalance={studentBalance ?? 0}
              entierServicesCost={
                student.totalScolarshipCost + student.totalServicesCost
              }
              showInline={showInline}
            />
          </div>
        )}
      </CardContent>
      {/* {showEditButton && (
        <CardActions disableSpacing>
          <IconButton
            aria-label="Modifier"
            color="primary"
            onClick={() => editStudent()}
          >
            <EditIcon />
          </IconButton>
        </CardActions>
      )} */}
    </Card>
  );
}

StudentCard.defaultProps = {
  showNavigation: false,
  showContextMenu: false,
  showInline: false,
};
