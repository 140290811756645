import React, { useContext, useEffect } from "react";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import {
  AppBar,
  IconButton,
  makeStyles,
  Toolbar,
  Button,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { SchoolContext } from "../../../contexts/SchoolContext";
import Logo from "../../../assets/images/light-logo.png";
import MenuActions from "./MenuActions";
import MenuInstitutions from "./MenuInstitutions";
import MenuAccademicYears from "./MenuAccademicYears";
import { Panel } from "@fluentui/react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CRUDStudentPath from "../../../components/CRUD/CRUDStudentPath";
import CRUDPayment from "../../../components/CRUD/CRUDPayment";
import { InstitutionContext } from "../../../contexts/InstitutionContext";
import CRUDExpense from "../../../components/CRUD/CRUDExpense";
import UsernameActions from "./UsernameActions";
import { isMobile } from "react-device-detect";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  appBar: {
    // background: "transparent", // process.env.REACT_APP_BACKGROUND_DARK,
    zIndex: theme.zIndex.drawer + 1,
    color: "white",
    backgroundColor: grey[800],
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  title: {
    flexGrow: 1,
    display: "flex",
  },
}));

interface IProps {
  handleDrawerOpen: Function;
  handleDrawerClose: Function;
  open: boolean;
}
export default function TopBar({
  handleDrawerOpen,
  open,
  handleDrawerClose,
}: IProps) {
  const classes = useStyles();
  const {
    accademicYear,
    studentUpdated,
    setStudentUpdated,
    paymentUpdated,
    setPaymentUpdated,
    expenseUpdated,
    setExpenseUpdated,
  } = useContext(SchoolContext);
  const { institution } = useContext(InstitutionContext);
  const [anchorMenuActionsEl, setAnchorMenuActionsEl] = React.useState(null);
  const [anchorMenuInstitutionsEl, setAnchorMenuInstitutionsEl] =
    React.useState(null);
  const [anchorMenuAccademicYearsEl, setAnchorMenuAccademicYearsEl] =
    React.useState(null);
  const [openNewStudent, setOpenNewStudent] = React.useState<boolean>(false);
  const [openNewPayment, setOpenNewPayment] = React.useState<boolean>(false);
  const [openNewExpense, setOpenNewExpense] = React.useState<boolean>(false);

  useEffect(() => {
    if (expenseUpdated) {
      setExpenseUpdated(false);
      setOpenNewExpense(false);
    }
  }, [expenseUpdated, setExpenseUpdated, setOpenNewExpense]);

  useEffect(() => {
    if (paymentUpdated) {
      setPaymentUpdated(false);
      setOpenNewPayment(false);
    }
  }, [paymentUpdated, setPaymentUpdated, setOpenNewPayment]);

  useEffect(() => {
    if (studentUpdated) {
      setStudentUpdated(false);
      setOpenNewStudent(false);
    }
  }, [studentUpdated, setStudentUpdated, setOpenNewStudent]);
  // const handleOnSearchChange = (v: string) => {
  //   if (v && v.length > 0) {
  //     // searchApi
  //     //   .byValue(v)
  //     //   .then((res) => setSearchResultList(res))
  //     //   .catch((err) => console.log(err));
  //   }
  // };
  // const useSearchStarwarsHero = () =>
  //   useDebouncedSearch((text: string) => handleOnSearchChange(text));
  // const { inputText, setInputText, searchResults } = useSearchStarwarsHero();

  return (
    <>
      <AppBar position="absolute" className={clsx(classes.appBar)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={() => (open ? handleDrawerClose() : handleDrawerOpen())}
            className={clsx(classes.menuButton)}
          >
            <MenuIcon />
          </IconButton>
          {!isMobile && (
            <div className={classes.title}>
              <img
                src={Logo}
                title="Princibo.ma"
                style={{ height: "17px", marginRight: "15px", fill: "red" }}
                alt="Princibo.ma"
              />
            </div>
          )}
          <Button
            style={{ color: "white" }}
            size={isMobile ? "small" : "large"}
            onClick={(event: any) =>
              setAnchorMenuInstitutionsEl(event.currentTarget)
            }
          >
            {institution?.name}
          </Button>
          <MenuInstitutions
            anchorEl={anchorMenuInstitutionsEl}
            handleClose={() => setAnchorMenuInstitutionsEl(null)}
          />

          <Button
            style={{ color: "white" }}
            size={isMobile ? "small" : "large"}
            onClick={(event: any) =>
              setAnchorMenuAccademicYearsEl(event.currentTarget)
            }
          >
            {accademicYear?.startDate?.getFullYear()}/
            {accademicYear?.endDate?.getFullYear()}
          </Button>
          <MenuAccademicYears
            anchorEl={anchorMenuAccademicYearsEl}
            handleClose={() => setAnchorMenuAccademicYearsEl(null)}
          />

          {!isMobile && <UsernameActions />}
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            style={{ color: "white" }}
            size="small"
            onClick={(event: any) =>
              setAnchorMenuActionsEl(event.currentTarget)
            }
          >
            <AddCircleIcon />
          </IconButton>
          <MenuActions
            anchorEl={anchorMenuActionsEl}
            handleClose={() => setAnchorMenuActionsEl(null)}
            newStudentClick={() => {
              setAnchorMenuActionsEl(null);
              setOpenNewStudent(true);
            }}
            newPaymentClick={() => {
              setAnchorMenuActionsEl(null);
              setOpenNewPayment(true);
            }}
            newExpenseClick={() => {
              setAnchorMenuActionsEl(null);
              setOpenNewExpense(true);
            }}
          />
        </Toolbar>
      </AppBar>
      <Panel
        isBlocking={true}
        headerText="Nouveau élève"
        isOpen={openNewStudent}
        onDismiss={() => setOpenNewStudent(false)}
        closeButtonAriaLabel="Fermer"
      >
        <CRUDStudentPath sm={12} xs={12} md={12} />
      </Panel>
      <Panel
        isBlocking={true}
        headerText="Nouveau paiement"
        isOpen={openNewPayment}
        onDismiss={() => setOpenNewPayment(false)}
        closeButtonAriaLabel="Fermer"
      >
        <CRUDPayment
          sm={12}
          md={12}
          accademicYearId={accademicYear?.accademicYearId ?? ""}
          institutionId={institution?.institutionId ?? ""}
        />
      </Panel>
      <Panel
        isBlocking={true}
        headerText="Nouvelle dépense"
        isOpen={openNewExpense}
        onDismiss={() => setOpenNewExpense(false)}
        closeButtonAriaLabel="Fermer"
      >
        <CRUDExpense sm={12} md={12} />
      </Panel>
    </>
  );
}
