import React, { useContext, useEffect, useState } from "react";
import { green } from "@material-ui/core/colors";
import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
} from "@material-ui/core";
import {
  ClassroomDto,
  SchoolApi,
  DashboardEducationDto,
} from "../../api-clients/api";
import { getApiUrl } from "../../api-clients";
import { SchoolContext } from "../../contexts/SchoolContext";
import CardContentEducation from "./CardContentEducation";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGraduationCap } from "@fortawesome/free-solid-svg-icons";

interface IProps {
  classroom: ClassroomDto;
}
export default function CardClassroom({ classroom }: IProps) {
  const [schoolApi] = useState<SchoolApi>(new SchoolApi(getApiUrl()));
  const history = useHistory();
  const [dashboard, setDashboard] = useState<DashboardEducationDto | null>(
    null
  );
  const { accademicYear } = useContext(SchoolContext);

  useEffect(() => {
    if (accademicYear != null && accademicYear.accademicYearId != null && classroom.classroomId != null)
      schoolApi
        .dashboardClassroom(accademicYear.accademicYearId, classroom.classroomId)
        .then((res) => {
          setDashboard(res);
        });
  }, [schoolApi, accademicYear, classroom]);

  return (
    <Card>
      <CardActionArea
        onClick={() => history.push("/classroom/" + classroom.classroomId)}
      >
        <CardHeader
          title={classroom.acronym}
          subheader={classroom.name}
          avatar={
            <Avatar style={{ backgroundColor: green[200] }}>
              <FontAwesomeIcon icon={faGraduationCap} />
            </Avatar>
          }
        />
      </CardActionArea>
      <CardContent>
        <CardContentEducation dashboard={dashboard} />
      </CardContent>
    </Card>
  );
}
