import React, { useContext, useState } from "react";
import { Grid } from "@material-ui/core";
import { InscriptionApi, InscriptionDto } from "../../api-clients/api";
import { TextField } from "@fluentui/react";
import FluentUiDatePicker from "../Shared/FluentUiDatePicker";
import { getApiUrl } from "../../api-clients";
import { SchoolContext } from "../../contexts/SchoolContext";
import FormButtons from "../Shared/FormButtons";

interface IProps {
  inscription: InscriptionDto;
  onCancelClick?: () => void;
}
export default function CRUDService({ inscription, onCancelClick }: IProps) {
  const { setStudentUpdated, accademicYear } = useContext(SchoolContext);
  const [inscriptionApi] = useState<InscriptionApi>(
    new InscriptionApi(getApiUrl())
  );
  const [form, setForm] = useState<InscriptionDto>(inscription);
  const [saving, setSaving] = useState<boolean>(false);

  const canSave: boolean =
    form.monthlyFees != null &&
    form.serviceId != null &&
    form.startDate != null &&
    form.endDate != null;

  const save = () => {
    if (canSave) {
      setSaving(true);
      inscriptionApi
        .createOrUpdate(form)
        .then((res) => {
          setStudentUpdated(true);
        })
        .catch((err) => setSaving(false));
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <FluentUiDatePicker
          required
          label="Date de début"
          placeholder="Date de début"
          minDate={accademicYear?.startDate}
          maxDate={accademicYear?.endDate}
          value={form.startDate}
          onSelectDate={(date: Date | null | undefined): void => {
            setForm({
              ...form,
              startDate: date,
            } as InscriptionDto);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FluentUiDatePicker
          required
          label="Date de fin"
          placeholder="Date de fin"
          minDate={accademicYear?.startDate}
          maxDate={accademicYear?.endDate}
          value={form.endDate}
          onSelectDate={(date: Date | null | undefined): void => {
            setForm({
              ...form,
              endDate: date,
            } as InscriptionDto);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          label="Frais mensuel (dirham)"
          placeholder="Frais mensuel (dirham)"
          value={form.monthlyFees?.toString()}
          type="number"
          lang="fr-FR"
          min="0"
          onChange={(
            event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string
          ) => {
            if (!isNaN(Number(newValue))) {
              let num: number = Number(newValue);
              if (num < 0) num = num * -1;
              setForm({
                ...form,
                monthlyFees: Number(num.toFixed(2)),
              } as InscriptionDto);
            }
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormButtons
          disabled={saving || !canSave}
          onConfirmClick={save}
          onCancelClick={() => {
            if (onCancelClick) onCancelClick();
          }}
        />
      </Grid>
      {/* {form.inscriptionId != null && (
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <PrimaryButton
            text=""
            onClick={onCancelClick}
            iconProps={{ iconName: "Delete" }}
            style={{ backgroundColor: red[500], borderColor: red[500] }}
          />
        </Grid>
      )} */}
    </Grid>
  );
}
