import React from "react";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import { IStyleSet, ILabelStyles, Pivot, PivotItem } from "@fluentui/react";
import CRUDStudentPath from "../CRUD/CRUDStudentPath";
import StudentNewPath from "./StudentNewPath";

export default function NewStudent() {
  return (
    <Card>
      <CardContent>
        <Pivot aria-label="Pivot nouveau élève">
          <PivotItem
            headerText="Nouveau élève"
            headerButtonProps={{
              "data-order": 1,
              "data-title": "Nouveau élève",
            }}
          >
            <CardHeader title="Création et inscription nouveau élève" />
            <CardContent>
              <CRUDStudentPath sm={6} xs={12} md={6} />
            </CardContent>
          </PivotItem>
          <PivotItem headerText="Élève existant">
            <CardHeader title="Inscription élève existant" />
            <CardContent>
              <StudentNewPath sm={6} xs={12} md={6} />
            </CardContent>
          </PivotItem>
        </Pivot>
      </CardContent>
    </Card>
  );
}
