import { Card, CardContent, CardHeader } from "@material-ui/core";
import React from "react";
import CRUDExpense from "../CRUD/CRUDExpense";

export default function NewExpense() {
  return (
    <Card>
      <CardHeader title="Nouvelle dépense" />
      <CardContent>
        <CRUDExpense sm={12} md={6} />
      </CardContent>
    </Card>
  );
}
