import React from "react";
import { Text } from "@fluentui/react";

interface IProps {
  label: string;
  block: boolean;
}
export default function AppLabel({ label, block }: IProps) {
  return (
    <Text variant="smallPlus" block={block}>
      {label}
    </Text>
  );
}

AppLabel.defaultProps = {
  block: false,
};
