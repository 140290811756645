import React from "react";
import { Grid, Link, Typography } from "@material-ui/core";
import { StudentPaymentDto } from "../../api-clients/api";
import LabelFieldValue from "../Shared/LabelFieldValue";
import { numberToMad } from "../../helper";
import { useHistory } from "react-router-dom";

interface IProps {
  studentPayment: StudentPaymentDto;
}
export default function StudentPaymentDetail({ studentPayment }: IProps) {
  const history = useHistory();

  return (
    <div>
      <Link
        href={`/student/${studentPayment.studentId}`}
        onClick={(event: any) => {
          history.push(`/student/${studentPayment.studentId}`);
          event.preventDefault();
        }}
      >
        <Typography
          variant="h6"
          color="primary"
        >{`${studentPayment.studentFirstName} ${studentPayment.studentLastName}`}</Typography>
      </Link>
      <Link
        href={`/classroom/${studentPayment.classroomId}`}
        onClick={(event: any) => {
          history.push(`/classroom/${studentPayment.classroomId}`);
          event.preventDefault();
        }}
      >
        <Typography variant="body1" gutterBottom>
          {studentPayment.classroomName}
        </Typography>
      </Link>
      <Grid container>
        {studentPayment.inscriptionAmount != null &&
          studentPayment.inscriptionAmount > 0 && (
            <Grid item sm={4} xs={6}>
              <LabelFieldValue
                label="Inscription"
                value={numberToMad(studentPayment.inscriptionAmount)}
              />
            </Grid>
          )}
        {studentPayment.schoolAmount != null &&
          studentPayment.schoolAmount > 0 && (
            <Grid item sm={4} xs={6}>
              <LabelFieldValue
                label="Scolarité"
                value={numberToMad(studentPayment.schoolAmount)}
              />
            </Grid>
          )}
        {studentPayment.transportAmount != null &&
          studentPayment.transportAmount > 0 && (
            <Grid item sm={4} xs={6}>
              <LabelFieldValue
                label="Transport"
                value={numberToMad(studentPayment.transportAmount)}
              />
            </Grid>
          )}
        {studentPayment.childCareAmount != null &&
          studentPayment.childCareAmount > 0 && (
            <Grid item sm={4} xs={6}>
              <LabelFieldValue
                label="Garderie"
                value={numberToMad(studentPayment.childCareAmount)}
              />
            </Grid>
          )}
          {studentPayment.canteenAmount != null &&
          studentPayment.canteenAmount > 0 && (
            <Grid item sm={4} xs={6}>
              <LabelFieldValue
                label="Cantine"
                value={numberToMad(studentPayment.canteenAmount)}
              />
            </Grid>
          )}
      </Grid>
    </div>
  );
}
