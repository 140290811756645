import React from "react";
import { IconButton } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import UsernameGreeting from "../../../components/Shared/UsernameGreeting";
import MenuUser from "./MenuUser";

export default function UsernameActions() {
  const [anchorMenuUserEl, setAnchorMenuUserEl] = React.useState(null);

  return (
    <>
      <UsernameGreeting />

      {/* <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        style={{ color: "white" }}
        size="small"
        onClick={(event: any) => setAnchorMenuUserEl(event.currentTarget)}
      >
        <AccountCircleIcon />
      </IconButton>
      <MenuUser
        anchorEl={anchorMenuUserEl}
        handleClose={() => setAnchorMenuUserEl(null)}
      />
    </>
  );
}
