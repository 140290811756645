export const getApiUrl = () => {
  return process.env.REACT_APP_API_URL as string;
};

export const getSSRSUrl = () => {
  return process.env.REACT_APP_SSRS_URL as string;
};

export const logout = () => {
  localStorage.removeItem("token");
  window.location.href = "/";
};

export interface IStudentProps {
  id: string;
  studentPath: string;
  firstName: string;
  lastName: string;
}

export interface BarchartProps {
  id?: string;
  name: string | undefined;
  date: Date;
  value: number;
  value2?: number;
  color?: string;
}
