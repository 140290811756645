import React, { useContext, useEffect, useState } from "react";
import { green, red, purple, blue } from "@material-ui/core/colors";
import { Avatar, Breadcrumbs, Grid, Link, Typography } from "@material-ui/core";
import {
  SchoolApi,
  DashboardEducationDto,
  ClassroomDto,
} from "../../api-clients/api";
import { getApiUrl } from "../../api-clients";
import { SchoolContext } from "../../contexts/SchoolContext";
import Loading from "../Shared/Loading";
import { useHistory } from "react-router-dom";
import CardStatistic, { ILabelFieldValue } from "../Shared/CardStatistic";
import AccessibilityIcon from "@material-ui/icons/Accessibility";
import PaymentIcon from "@material-ui/icons/Payment";

interface IProps {
  classroom: ClassroomDto;
  educationName: string;
}
export default function CardHeaderClassroom({
  classroom,
  educationName,
}: IProps) {
  const [schoolApi] = useState<SchoolApi>(new SchoolApi(getApiUrl()));
  const history = useHistory();
  const [dashboard, setDashboard] = useState<DashboardEducationDto | null>(
    null
  );
  const { accademicYear } = useContext(SchoolContext);

  const accademicYearDate: string = `${accademicYear?.startDate?.getFullYear()}/${accademicYear?.endDate?.getFullYear()}`;

  useEffect(() => {
    if (accademicYear != null && accademicYear.accademicYearId != null && classroom.classroomId != null)
      schoolApi
        .dashboardClassroom(accademicYear.accademicYearId, classroom.classroomId)
        .then((res) => {
          setDashboard(res);
        });
  }, [schoolApi, accademicYear, classroom]);

  return (
    <div>
      {dashboard != null ? (
        <>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  color="inherit"
                  href="/"
                  onClick={(event: any) => {
                    event.preventDefault();
                    history.push("/education/" + classroom.educationId);
                  }}
                >
                  {educationName}
                </Link>
                <Typography color="textPrimary">{classroom.name}</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item sm={6} xs={12}>
              <CardStatistic
                title="Inscriptions"
                values={[
                  {
                    label: "Total",
                    value: dashboard?.numberStudent ?? 0,
                  } as ILabelFieldValue,
                  {
                    label: "Actives",
                    value: dashboard?.numberActiveStudent ?? 0,
                  } as ILabelFieldValue,
                  {
                    label: "Transport",
                    value: dashboard?.numberStudentTransport ?? 0,
                  } as ILabelFieldValue,

                  {
                    label: "Garderie",
                    value: dashboard?.numberStudentNursery ?? 0,
                  } as ILabelFieldValue,
                ]}
                avatar={
                  <Avatar style={{ backgroundColor: blue[200] }}>
                    <AccessibilityIcon />
                  </Avatar>
                }
                subheader={`${classroom.name} - ${accademicYearDate}`}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <CardStatistic
                title="Flux des paiements"
                values={[
                  {
                    label: "Attendus",
                    value: dashboard?.totalStudentCost ?? 0,
                    isCurrency: true,
                  } as ILabelFieldValue,
                  {
                    label: "Total payés",
                    value: dashboard?.totalPayment ?? 0,
                    isCurrency: true,
                  } as ILabelFieldValue,
                  {
                    label:
                      (dashboard?.totalPayment ?? 0) -
                        (dashboard?.totalStudentCost ?? 0) <
                      0
                        ? "Impayés"
                        : "Solde paiements",
                    value:
                      (dashboard?.totalPayment ?? 0) -
                      (dashboard?.totalStudentCost ?? 0),
                    isCurrency: true,
                    valueColor:
                      (dashboard?.totalPayment ?? 0) -
                        (dashboard?.totalStudentCost ?? 0) <
                      0
                        ? red[500]
                        : green[500],
                  } as ILabelFieldValue,
                ]}
                avatar={
                  <Avatar style={{ backgroundColor: purple[200] }}>
                    <PaymentIcon />
                  </Avatar>
                }
                subheader={`${classroom.name} - ${accademicYearDate}`}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
}
