import React from "react";
import { DatePicker, DayOfWeek } from "@fluentui/react";
import { dateToString, DayPickerStrings } from "../../helper";

interface IProps {
  label: string;
  placeholder: string;
  isRequired: boolean;
  allowTextInput: boolean;
  required: boolean;
  value?: Date;
  minDate?: Date;
  maxDate?: Date;
  onSelectDate: (date: Date | null | undefined) => void;
}

export default function FluentUiDatePicker(props: IProps) {
  
  const onParseDateFromString = (val: string): Date => {
    const date = props.value || new Date();
    const values = (val || "").trim().split("/");
    const day =
      val.length > 0
        ? Math.max(1, Math.min(31, parseInt(values[0], 10)))
        : date.getDate();
    const month =
      val.length > 1
        ? Math.max(1, Math.min(12, parseInt(values[1], 10))) - 1
        : date.getMonth();
    let year = val.length > 2 ? parseInt(values[2], 10) : date.getFullYear();
    if (year < 100) {
      year += date.getFullYear() - (date.getFullYear() % 100);
    }
    return new Date(year, month, day);
  };
  return (
    <DatePicker
      {...props}
      firstDayOfWeek={DayOfWeek.Monday}
      strings={DayPickerStrings}
      formatDate={(date?: Date) => dateToString(date)}
      parseDateFromString={onParseDateFromString}
      showGoToToday={false}
    />
  );
}

FluentUiDatePicker.defaultProps = {
  isRequired: false,
  label: "",
  placeholder: "",
  allowTextInput: true,
  value: null,
  maxDate: null,
  minDate: null,
  required: false,
};
