import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { Snackbar } from "@material-ui/core";
import { AppContext } from "../contexts/AppContext";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export const AppProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [snackbar, setSnackbar] = useState<any | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (
      snackbar != null &&
      snackbar.content != null &&
      snackbar.content.length > 0 &&
      snackbar.severity != null &&
      snackbar.severity.length > 0
    ) {
      setOpen(true);
    }
  }, [snackbar]);

  const openSnackbar: (content: string, severity: string) => void = (
    content: string,
    severity: string
  ) => {
    if (
      content != null &&
      content.length > 0 &&
      severity != null &&
      severity.length > 0
    ) {
      setSnackbar({ content: content, severity: severity });
    }
  };

  const handleClose = (event: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    setSnackbar(null);
  };

  const value = useMemo(() => ({ openSnackbar }), [openSnackbar]);

  return (
    <AppContext.Provider value={value}>
      {children}
      {snackbar != null && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={snackbar?.severity}>
            {snackbar?.content}
          </Alert>
        </Snackbar>
      )}
    </AppContext.Provider>
  );
};
