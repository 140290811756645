import React, { useContext, useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { StudentPaymentDto } from "../../api-clients/api";
import { TextField } from "@fluentui/react";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import DeleteButtonCallout from "../Shared/DeleteButtonCallout";
import { SchoolContext } from "../../contexts/SchoolContext";

interface IProps {
  title: string;
  studentPayment: StudentPaymentDto;
  setStudentPayment: (studentPayment: StudentPaymentDto) => void;
  uniqueId: string;
  onRemoveClick: Function;
}
export default function StudentPaymentForm({
  title,
  studentPayment,
  uniqueId,
  onRemoveClick,
  setStudentPayment,
}: IProps) {
  const [isCalloutVisible, setIsCalloutVisible] = useState<boolean>(false);
  const { accademicYear } = useContext(SchoolContext);
  const [width] = useState<4 | 6>(
    accademicYear &&
      accademicYear.hasCanteen &&
      accademicYear.hasTransport &&
      accademicYear.hasChildCare
      ? 4
      : 6
  );

  return (
    <Card>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={11}>
            <Typography variant="body1">{title}</Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              className={uniqueId}
              size="small"
              aria-label="Supprimer selection"
              onClick={() => setIsCalloutVisible(true)}
            >
              <HighlightOffIcon />
            </IconButton>
          </Grid>
          <Grid item xs={6}>
            <Tooltip title="Inscription">
              <TextField
                iconProps={{ iconName: "Handwriting" }}
                placeholder="Inscription"
                type="number"
                lang="fr-FR"
                min="0"
                value={studentPayment.inscriptionAmount?.toString()}
                onChange={(
                  event: React.FormEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >,
                  newValue?: string
                ) => {
                  if (!isNaN(Number(newValue))) {
                    let num: number = Number(newValue);
                    if (num < 0) num = num * -1;
                    setStudentPayment({
                      ...studentPayment,
                      inscriptionAmount: Number(num.toFixed(2)),
                    } as StudentPaymentDto);
                  }
                }}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={6}>
            <Tooltip title="Scolarité">
              <TextField
                iconProps={{ iconName: "Education" }}
                placeholder="Scolarité"
                type="number"
                lang="fr-FR"
                min="0"
                value={studentPayment.schoolAmount?.toString()}
                onChange={(
                  event: React.FormEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >,
                  newValue?: string
                ) => {
                  if (!isNaN(Number(newValue))) {
                    let num: number = Number(newValue);
                    if (num < 0) num = num * -1;
                    setStudentPayment({
                      ...studentPayment,
                      schoolAmount: Number(num.toFixed(2)),
                    } as StudentPaymentDto);
                  }
                }}
              />
            </Tooltip>
          </Grid>
          {accademicYear != null && accademicYear.hasTransport && (
            <Grid item xs={width}>
              <Tooltip title="Transport">
                <TextField
                  iconProps={{ iconName: "Bus" }}
                  placeholder="Transport"
                  type="number"
                  lang="fr-FR"
                  min="0"
                  value={studentPayment.transportAmount?.toString()}
                  onChange={(
                    event: React.FormEvent<
                      HTMLInputElement | HTMLTextAreaElement
                    >,
                    newValue?: string
                  ) => {
                    if (!isNaN(Number(newValue))) {
                      let num: number = Number(newValue);
                      if (num < 0) num = num * -1;
                      setStudentPayment({
                        ...studentPayment,
                        transportAmount: Number(num.toFixed(2)),
                      } as StudentPaymentDto);
                    }
                  }}
                />
              </Tooltip>
            </Grid>
          )}
          {accademicYear != null && accademicYear.hasChildCare && (
            <Grid item xs={width}>
              <Tooltip title="Garderie">
                <TextField
                  iconProps={{ iconName: "TeamFavorite" }}
                  placeholder="Garderie"
                  type="number"
                  lang="fr-FR"
                  min="0"
                  value={studentPayment.childCareAmount?.toString()}
                  onChange={(
                    event: React.FormEvent<
                      HTMLInputElement | HTMLTextAreaElement
                    >,
                    newValue?: string
                  ) => {
                    if (!isNaN(Number(newValue))) {
                      let num: number = Number(newValue);
                      if (num < 0) num = num * -1;
                      setStudentPayment({
                        ...studentPayment,
                        childCareAmount: Number(num.toFixed(2)),
                      } as StudentPaymentDto);
                    }
                  }}
                />
              </Tooltip>
            </Grid>
          )}
          {accademicYear != null && accademicYear.hasCanteen && (
            <Grid item xs={width}>
              <Tooltip title="Cantine">
                <TextField
                  iconProps={{ iconName: "EatDrink" }}
                  placeholder="Cantine"
                  type="number"
                  lang="fr-FR"
                  min="0"
                  value={studentPayment.canteenAmount?.toString()}
                  onChange={(
                    event: React.FormEvent<
                      HTMLInputElement | HTMLTextAreaElement
                    >,
                    newValue?: string
                  ) => {
                    if (!isNaN(Number(newValue))) {
                      let num: number = Number(newValue);
                      if (num < 0) num = num * -1;
                      setStudentPayment({
                        ...studentPayment,
                        canteenAmount: Number(num.toFixed(2)),
                      } as StudentPaymentDto);
                    }
                  }}
                />
              </Tooltip>
            </Grid>
          )}
        </Grid>
        <DeleteButtonCallout
          uniqueId={uniqueId}
          setIsCalloutVisible={setIsCalloutVisible}
          isCalloutVisible={isCalloutVisible}
          confirmAction={() => onRemoveClick(studentPayment.studentPathId)}
          title="Retirer de la liste"
          content="Si vous retirez cet element de la liste vous supprimez les montants de paiement liés à cet élève."
          titleRemoveButton="Retirer"
        />
      </CardContent>
    </Card>
  );
}
