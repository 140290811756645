import React from "react";
import { Paper, Typography } from "@material-ui/core";
import { PieChartDto } from "../../api-clients/api";
import { dateToString, numberToMad } from "../../helper";
import { RechartsFunction } from "recharts";
import { green, red, blue, purple, grey } from "@material-ui/core/colors";

export const CustomTooltip = (props: any) => {
  const { payload, active, showValue } = props;
  return (
    <Paper style={{ padding: "10px" }}>
      {active && payload && payload[0] != null && (
        <Typography variant="caption" display="block">
          {payload[0].payload?.name != null &&
            (payload[0].payload.name instanceof Date
              ? dateToString(payload[0].payload.name)
              : payload[0].payload?.name)}
          <br></br>
          {showValue &&
            `${payload[0].name}: ${numberToMad(payload[0].value)}`}
          <br></br>
          {showValue &&
            payload[1] != null &&
            `${payload[1].name}: ${numberToMad(payload[1].value)}`}
        </Typography>
      )}
    </Paper>
  );
};

export interface IAppPieChartProps {
  data?: PieChartDto | null;
  showLegend: boolean;
  width: number;
  height: number;
  showTooltip: boolean;
  showTooltipValue: boolean;
  onSliceClick?: RechartsFunction;
}

export const AppPieChartColors: string[] = [
  "#8884d8",
  purple[600],
  red[600],
  blue[600],
  green[600],
  grey[400]
];
