import React, { useContext, useEffect, useState } from "react";
import { Avatar, Container, Grid, Typography } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import {
  DashboardStudentDto,
  StudentApi,
  StudentPathDto,
  StudentUpdateDto,
  StudentView,
  ParentApi,
  ParentDto,
} from "../../api-clients/api";
import { getApiUrl } from "../../api-clients";
import Loading from "../Shared/Loading";
import NoResult from "../Shared/NoResult";
import CardStudentPayments from "../Payments/CardStudentPayments";
import StudentCard from "./StudentCard";
import { Panel, Text } from "@fluentui/react";
import CRUDStudent from "../CRUD/CRUDStudent";
import { SchoolContext } from "../../contexts/SchoolContext";
import CardService from "./CardService";
import { TextField } from "@fluentui/react";
import CardStudentPath from "./CardStudentPath";
import FormButtons from "../Shared/FormButtons";
import { canDeleteStudent } from "../../api-clients/roles";
import { AppContext } from "../../contexts/AppContext";
import PaymentsMonthlySituation from "./PaymentsMonthlySituation"

export default function Student() {
  const history = useHistory();
  const { studentId }: any = useParams();
  const { openSnackbar } = useContext(AppContext);
  const {
    accademicYear,
    studentUpdated,
    setStudentUpdated,
    transportService,
    childCareService,
    canteenService,
  } = useContext(SchoolContext);
  const [studentApi] = useState<StudentApi>(new StudentApi(getApiUrl()));
  const [parentApi] = useState<ParentApi>(new ParentApi(getApiUrl()));
  const [student, setStudent] = useState<StudentView | null>(null);
  const [dashboard, setDashboard] = useState<DashboardStudentDto | null>(null);
  const [loadingDashboard, setLoadingDashboard] = useState<boolean>(true);
  const [studentLoading, setStudentLoading] = useState<boolean>(true);
  const [studentToEdit, setStudentToEdit] = useState<StudentUpdateDto | null>(
    null
  );
  const [historyPath, setHistoryPath] = useState<StudentPathDto[] | null>(null);
  const [parents, setParents] = useState<ParentDto[] | null>(null);
  const [historyPathLoading, setHistoryPathLoading] = useState<boolean>(true);
  const [showDeleteStudentDialog, setShowDeleteStudentDialog] =
    useState<boolean>(false);
  const [passwordCheck, setPasswordCheck] = useState<string | undefined>(
    undefined
  );
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [parentsLoading, setParentsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (studentUpdated) {
      setStudentUpdated(false);
      setStudentToEdit(null);
    }

    parentApi
      .byStudentId(studentId)
      .then((res) => {
        setParentsLoading(false);
        setParents(res);
      })
      .catch((err) => setParentsLoading(false));

    studentApi
      .getByStudentIdAccademicYearId(
        studentId,
        accademicYear?.accademicYearId ?? ""
      )
      .then((res) => {
        setStudent(res);
        setStudentLoading(false);
      })
      .catch((err) => setStudentLoading(false));

    studentApi
      .studentPathHistory(studentId)
      .then((res) => {
        setHistoryPath(res);
        setHistoryPathLoading(false);
      })
      .catch((err) => setHistoryPathLoading(false));
  }, [
    studentApi,
    parentApi,
    studentId,
    studentUpdated,
    setStudentUpdated,
    accademicYear,
  ]);

  useEffect(() => {
    if (student != null && student.studentPathId)
      studentApi
        .dashboard(student.studentPathId, accademicYear?.accademicYearId ?? "")
        .then((res) => {
          setDashboard(res);
          setLoadingDashboard(false);
        })
        .catch((err) => setLoadingDashboard(false));
  }, [studentApi, student, accademicYear]);

  const canDeleteStudent = () => {
    if (student != null && student.studentId && passwordCheck != null) {
      studentApi
        .delete(student.studentId, passwordCheck)
        .then(() => history.push("/students"))
        .catch((err) => {
          console.log(err);
          openSnackbar("Une erreur s'est reproduite", "error");
        });
    }
  };

  return (
    <Container maxWidth="lg">
      {!studentLoading ? (
        student != null ? (
          <div>
            <Grid container spacing={1}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div style={{ display: "flex" }}>
                  <Avatar>
                    {(student.firstName ?? " ")[0]}
                    {(student.lastName ?? " ")[0]}
                  </Avatar>
                  <Typography
                    variant="h6"
                    style={{ marginLeft: "12px" }}
                    color="primary"
                  >
                    {student.firstName} {student.lastName}
                  </Typography>
                </div>
              </Grid>
              <Grid item sm={9} xs={12}>
                <StudentCard
                  parents={parents}
                  student={student}
                  editStudent={() =>
                    setStudentToEdit({
                      studentId: student.studentId,
                      firstName: student.firstName,
                      lastName: student.lastName,
                      dateOfBirth: student.dateOfBirth,
                    } as StudentUpdateDto)
                  }
                  deleteStudent={() => setShowDeleteStudentDialog(true)}
                  showContextMenu={true}
                  showInline={true}
                />
              </Grid>
              <Grid item sm={3} xs={12}>
                <CardStudentPath
                  historyPath={
                    historyPath?.filter(
                      (p) =>
                        p.accademicYearId === accademicYear?.accademicYearId
                    ) ?? []
                  }
                  historyPathLoading={historyPathLoading}
                />
              </Grid>
              {accademicYear &&
                accademicYear.hasTransport &&
                transportService != null &&
                student.studentPathId != null && (
                  <Grid item lg={3} md={4} sm={6} xs={12}>
                    <CardService
                      service={transportService}
                      inscription={dashboard?.transportInscription}
                      isLoading={loadingDashboard}
                      studentPathId={student.studentPathId}
                    />
                  </Grid>
                )}
              {accademicYear &&
                accademicYear.hasChildCare &&
                childCareService != null &&
                student.studentPathId != null && (
                  <Grid item lg={3} md={4} sm={6} xs={12}>
                    <CardService
                      service={childCareService}
                      inscription={dashboard?.nurseryInscription}
                      isLoading={loadingDashboard}
                      studentPathId={student.studentPathId}
                    />
                  </Grid>
                )}
              {accademicYear &&
                accademicYear.hasCanteen &&
                canteenService != null &&
                student.studentPathId != null && (
                  <Grid item lg={3} md={4} sm={6} xs={12}>
                    <CardService
                      service={canteenService}
                      inscription={dashboard?.canteenInscription}
                      isLoading={loadingDashboard}
                      studentPathId={student.studentPathId}
                    />
                  </Grid>
                )}
              {student.studentPathId != null && (
                <>
                  <Grid item xs={12} md={8}>
                    <PaymentsMonthlySituation
                      studentPathId={student.studentPathId}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CardStudentPayments
                      studentPathId={student.studentPathId}
                    />
                  </Grid>
                </>
              )}
            </Grid>

            <Panel
              isBlocking={true}
              headerText="Modifier élève"
              isOpen={studentToEdit != null}
              onDismiss={() => setStudentToEdit(null)}
              closeButtonAriaLabel="Fermer"
            >
              {studentToEdit != null && (
                <CRUDStudent
                  student={studentToEdit}
                  onCancelClick={() => setStudentToEdit(null)}
                />
              )}
            </Panel>
            {canDeleteStudent && (
              <Panel
                isBlocking={true}
                headerText="Suppression élève"
                isOpen={showDeleteStudentDialog}
                onDismiss={() => {
                  setShowDeleteStudentDialog(false);
                  setPasswordCheck(undefined);
                }}
                closeButtonAriaLabel="Fermer"
              >
                <Grid container style={{ marginTop: "16px" }}>
                  {student.totalReceivedPayments > 0 ? (
                    <Grid item xs={12}>
                      <Text variant={"large"} block>
                        Risque de perte de donnée !
                      </Text>
                      <Text>
                        Avant de supprimer cet étudiant, veuillez supprimer tous
                        les paiements liés.
                      </Text>
                    </Grid>
                  ) : (
                    <>
                      <Grid item xs={12}>
                        <Text variant={"large"} block>
                          Êtes-vous sûr ?
                        </Text>
                        <Text>
                          Êtes-vous sûr de vouloir supprimer cet élève ?
                        </Text>
                        <Text>Cette opération est irréversible.</Text>

                        <Text>
                          Veuillez fournir votre mot de passe pour confirmer la
                          suppression
                        </Text>
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: "16px" }}>
                        <TextField
                          required
                          autoComplete="off"
                          label="Mot de passe"
                          value={passwordCheck}
                          type="password"
                          onChange={(
                            event: React.FormEvent<
                              HTMLInputElement | HTMLTextAreaElement
                            >,
                            newValue?: string
                          ) => setPasswordCheck(newValue)}
                        />
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: "16px" }}>
                        <FormButtons
                          confirmButtonVisible={false}
                          cancelButtonVisible={true}
                          deleteButtonVisible={true}
                          onCancelClick={() => {
                            setShowDeleteStudentDialog(false);
                            setPasswordCheck(undefined);
                          }}
                          onDeleteClick={() => canDeleteStudent()}
                          disabled={
                            isDeleting ||
                            passwordCheck === undefined ||
                            passwordCheck.length < 3
                          }
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Panel>
            )}
          </div>
        ) : (
          <NoResult />
        )
      ) : (
        <Loading />
      )}
    </Container>
  );
}
