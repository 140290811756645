import React from "react";
import "./App.css";

function Unauthorized() {

  return (
    <div>
      <h1>Not Authorized !</h1>
    </div>
  );
}

export default Unauthorized;
