import React, { useContext, useEffect, useState } from "react";
import { Container, Grid, Card, CardContent } from "@material-ui/core";
import {
  PeriodReportingDto,
  StatisticApi,
  StatisticFilterDto,
} from "../../api-clients/api";
import { BarchartProps, getApiUrl } from "../../api-clients";
import Loading from "../Shared/Loading";
import NoResult from "../Shared/NoResult";
import AppBarChart from "../Charts/AppBarChart";
import { InstitutionContext } from "../../contexts/InstitutionContext";
import { Text, Stack } from "@fluentui/react";
import MonthPicker from "../Shared/MonthPicker";
import { green, red } from "@material-ui/core/colors";
import LabelFieldValue from "../Shared/LabelFieldValue";
import PeriodReportingPdf from "./PeriodReportingPdf";
import { numberToMad } from "../../helper";

export default function Statistics() {
  const [statisticApi] = useState<StatisticApi>(new StatisticApi(getApiUrl()));
  const [selectedDate, setSelectedDate] = useState<Date>(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [result, setResult] = useState<PeriodReportingDto | null>(null);
  const [loaded, setLoaded] = useState<boolean>(false);
  const { institution } = useContext(InstitutionContext);

  const onSelectDate = React.useCallback(
    (date: Date, dateRangeArray?: Date[]): void => {
      setSelectedDate(date);
    },
    []
  );

  useEffect(() => {
    setLoaded(false);
    statisticApi
      .periodReporting({
        startDate: new Date(
          selectedDate.getFullYear(),
          selectedDate.getMonth(),
          1
        ),
        endDate: new Date(
          selectedDate.getFullYear(),
          selectedDate.getMonth() + 1,
          0
        ),
      } as StatisticFilterDto)
      .then((res) => {
        setLoaded(true);
        setResult(res);
      })
      .catch((err) => {
        setLoaded(true);
        console.log(err);
      });
  }, [statisticApi, selectedDate]);

  return (
    <Container maxWidth="lg">
      <Text variant="xxLarge">
        {institution?.name} <Text variant="large">Statistiques</Text>
      </Text>
      <Card>
        <CardContent>
          <Grid container>
            <Grid item xs={12} sm={6} md={4}>
              <MonthPicker
                onSelectDate={onSelectDate}
                selectedDate={selectedDate}
              />
            </Grid>
            {loaded ? (
              result != null ? (
                <>
                  <Grid item xs={12} sm={6} md={4}>
                    <LabelFieldValue
                      label="Produits"
                      value={numberToMad(
                        result.payments.reduce((a, b) => a + b.totalAmount, 0)
                      )}
                      variant="xxLarge"
                    />
                    <LabelFieldValue
                      label="Charges"
                      value={numberToMad(
                        result.expenses.reduce((a, b) => a + b.amount, 0)
                      )}
                      variant="xxLarge"
                    />
                    <Grid container>
                      {result.activeStudents > 0 && (
                        <Grid item xs={3}>
                          <LabelFieldValue
                            label="Élèves"
                            value={result.activeStudents}
                            variant="xLarge"
                          />
                        </Grid>
                      )}
                      {result.activeTransport > 0 && (
                        <Grid item xs={3}>
                          <LabelFieldValue
                            label="Transport"
                            value={result.activeTransport}
                            variant="xLarge"
                          />
                        </Grid>
                      )}
                      {result.activeChildcare > 0 && (
                        <Grid item xs={3}>
                          <LabelFieldValue
                            label="Garderie"
                            value={result.activeChildcare}
                            variant="xLarge"
                          />
                        </Grid>
                      )}
                      {result.activeCanteen > 0 && (
                        <Grid item xs={3}>
                          <LabelFieldValue
                            label="Cantine"
                            value={result.activeCanteen}
                            variant="xLarge"
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <AppBarChart
                      width={250}
                      data={
                        [
                          {
                            name: "Produits",
                            value: result.payments.reduce(
                              (a, b) => a + b.totalAmount,
                              0
                            ),
                            color: green[500],
                          },
                          {
                            name: "Charges",
                            value: result.expenses.reduce(
                              (a, b) => a + b.amount,
                              0
                            ),
                            color: red[500],
                          },
                        ] as BarchartProps[]
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <PeriodReportingPdf periodReporting={result} />
                  </Grid>
                </>
              ) : (
                <NoResult />
              )
            ) : (
              <Loading />
            )}
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
}
