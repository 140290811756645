import React from "react";
import {
  IconButton,
  Divider,
  Drawer,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core";
import clsx from "clsx";
import MenuListItems from "../MenuListItems/MenuListItems";
import { isMobile } from "react-device-detect";
import appTheme from "../../../app-theme";
import UsernameGreeting from "../../../components/Shared/UsernameGreeting";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { logout } from "../../../api-clients";

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    height: "100vh",
    backgroundColor: process.env.REACT_APP_SIDE_MENU_COLOR,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

interface IProps {
  open: boolean;
  onNavigated?: Function;
}

export default function SideMenu({ open, onNavigated }: IProps) {
  const classes = useStyles();

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(
          classes.drawerPaper,
          !(open) && classes.drawerPaperClose
        ),
      }}
      open={open}
    >
      <div className={classes.toolbarIcon}>
        {isMobile && (
          <ThemeProvider theme={appTheme}>
            <UsernameGreeting />
            <IconButton onClick={() => logout()} style={{ color: "white" }}>
              <ExitToAppIcon />
            </IconButton>
          </ThemeProvider>
        )}
      </div>
      <Divider />
      <MenuListItems onNavigated={onNavigated} />
    </Drawer>
  );
}
