import React, { useState, useEffect, useContext, useRef } from "react";
import {
  DoubleChartDto,
  StatisticApi,
  StatisticFilterDto,
  StatisticPeriod,
} from "../../api-clients/api";
import { getApiUrl } from "../../api-clients";
import { SchoolContext } from "../../contexts/SchoolContext";
import Loading from "../Shared/Loading";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import AppLineChart from "../Charts/AppLineChart";
import { green, red } from "@material-ui/core/colors";

interface IProps {
  startDate?: Date;
  endDate?: Date;
}
export default function IncomeOutcomeLineChart({ startDate, endDate }: IProps) {
  const { accademicYear } = useContext(SchoolContext);
  const accademicYearDate: string = `${accademicYear?.startDate?.getFullYear()}/${accademicYear?.endDate?.getFullYear()}`;

  const [statisticApi] = useState<StatisticApi>(new StatisticApi(getApiUrl()));
  const [values, setValues] = useState<DoubleChartDto[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  const parentRef = useRef<any>(null);

  useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    if (accademicYear != null) {
      setIsLoading(true);
      statisticApi
        .incomeOutcome({
          startDate: startDate ?? accademicYear.startDate,
          endDate: endDate ?? accademicYear.endDate,
          statisticPeriod: StatisticPeriod._2,
          accademicYearId: accademicYear?.accademicYearId,
        } as StatisticFilterDto)
        .then((res) => {
          setValues(res);
          setIsLoading(false);
        })
        .catch((err) => setIsLoading(false));
    }
  }, [statisticApi, accademicYear, startDate, endDate]);

  return (
    <Card>
      <CardHeader
        title="Flux de trésorerie"
        subheader={"Année " + accademicYearDate}
      />
      <CardContent ref={parentRef}>
        {!isLoading ? (
          <AppLineChart
            data={values}
            width={parentRef?.current?.offsetWidth - 40 ?? 400}
            height={230}
            showSecondValue={true}
            valueColor={red[800]}
            value2Color={green[800]}
            name="Dépenses"
            name2="Paiements reçus"
            showBrush={values != null && values.length > 10}
          />
        ) : (
          <Loading />
        )}
      </CardContent>
    </Card>
  );
}
