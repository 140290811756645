import { createContext } from "react";
import { Institution } from "../api-clients/api";

export interface InstitutionState {
    institution: Institution | null;
    institutions: Institution[] | null;
    institutionUpdated: () => void;
    setInstitution: (institution: Institution) => void;
}

export const InstitutionContext = createContext<InstitutionState>({
    institution: null,
    institutions: null,
    institutionUpdated: () => {},
    setInstitution: (institution: Institution) => {}
});