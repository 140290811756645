import React, { useContext, useEffect, useState } from "react";
import {
  PaymentDto,
  StudentPaymentDto,
  Institution,
  AccademicYearDto,
  PublicPaymentDto,
  PublicInstitutionDto,
  PublicAccademicYearDto,
  PublicStudentPaymentDto,
  PaymentApi,
} from "../../api-clients/api";
import { SchoolContext } from "../../contexts/SchoolContext";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { isMobile, isTablet } from "react-device-detect";
import {
  dateToString,
  monthName,
  numberToMadPdf,
  paymentTermTypeToString,
} from "../../helper";
import {
  detail,
  header,
  highlitedColor,
  pdfFooter,
  pdfHeader,
  secondaryColor,
  space,
  subHeader,
  table,
  tableHeader,
  title,
} from "../PDF/PdfLayout";
import { InstitutionContext } from "../../contexts/InstitutionContext";
import { getApiUrl } from "../../api-clients";

var pdfMake = require("pdfmake/build/pdfmake.js");
var pdfFonts = require("pdfmake/build/vfs_fonts.js");
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function AllPaymentPdf() {
  const { institution } = useContext(InstitutionContext);
  const { accademicYear } = useContext(SchoolContext);
  const [payments, setPayments] = useState<PaymentDto[]>([]);
  const [paymentApi] = useState<PaymentApi>(new PaymentApi(getApiUrl()));

  useEffect(() => {
    paymentApi.all()
      .then(res => setPayments(res))
  }, [paymentApi, institution])

  const getMonth = (
    studentPayment: StudentPaymentDto | PublicStudentPaymentDto
  ): string => {
    let result = "";
    let totalAmount = studentPayment.totalAmount;
    if (studentPayment.situation != null) {
      const paidMonths = studentPayment.situation.filter(
        (f) => f.totalPaid > 0
      );
      for (let i = paidMonths.length - 1; i >= 0; i--) {
        if (totalAmount > 0) {
          result = `${monthName(paidMonths[i].month)} ${result.length > 0 ? "| " + result : ""}`;
          totalAmount -= paidMonths[i].totalPaid;
        }
      }
    }
    return result;
  };

  const getPaymentHeadersWidths = (): any[] => {
    var headers: any[] = ["auto", "*", "*"];
    if (accademicYear != null) {
      if (accademicYear.hasTransport) headers.push("*");
      if (accademicYear.hasChildCare) headers.push("*");
      if (accademicYear.hasCanteen) headers.push("*");
    }

    return headers;
  };

  const getPaymentHeaders = (): any[] => {
    var headers: any[] = [
      { text: "Élève", ...tableHeader },
      { text: "Inscription", ...tableHeader },
      { text: "Scolarité", ...tableHeader },
    ];
    if (accademicYear != null) {
      if (accademicYear.hasTransport)
        headers.push({ text: "Transport", ...tableHeader });
      if (accademicYear.hasChildCare)
        headers.push({ text: "Garderie", ...tableHeader });
      if (accademicYear.hasCanteen)
        headers.push({ text: "Cantine", ...tableHeader });
    }

    return headers;
  };

  const getPaymentContent = (
    sp: StudentPaymentDto | PublicStudentPaymentDto
  ): any[] => {
    var content: any[] = [
      [
        {
          text: `${sp.studentFirstName} ${sp.studentLastName}`,
          color: highlitedColor,
        },
        { text: sp.classroomName, ...detail },
      ],
      {
        text:
          sp.inscriptionAmount != null && sp.inscriptionAmount > 0
            ? numberToMadPdf(sp.inscriptionAmount)
            : "-",
        color:
          sp.inscriptionAmount != null && sp.inscriptionAmount > 0
            ? highlitedColor
            : secondaryColor,
      },
      {
        text:
          sp.schoolAmount != null && sp.schoolAmount > 0
            ? numberToMadPdf(sp.schoolAmount)
            : "-",
        color:
          sp.schoolAmount != null && sp.schoolAmount > 0
            ? highlitedColor
            : secondaryColor,
      },
    ];
    if (accademicYear != null) {
      if (accademicYear.hasTransport)
        content.push({
          text:
            sp.transportAmount != null && sp.transportAmount > 0
              ? numberToMadPdf(sp.transportAmount)
              : "-",
          color:
            sp.transportAmount != null && sp.transportAmount > 0
              ? highlitedColor
              : secondaryColor,
        });
      if (accademicYear.hasChildCare)
        content.push({
          text:
            sp.childCareAmount != null && sp.childCareAmount > 0
              ? numberToMadPdf(sp.childCareAmount)
              : "-",
          color:
            sp.childCareAmount != null && sp.childCareAmount > 0
              ? highlitedColor
              : secondaryColor,
        });
      if (accademicYear.hasCanteen)
        content.push({
          text:
            sp.canteenAmount != null && sp.canteenAmount > 0
              ? numberToMadPdf(sp.canteenAmount)
              : "-",
          color:
            sp.canteenAmount != null && sp.canteenAmount > 0
              ? highlitedColor
              : secondaryColor,
        });
    }

    return content;
  };

  const content = (payment: PaymentDto) => [
    pdfHeader(
      institution?.logo ?? "",
      [200, institution?.receiptFormat === "A4" ? 140 : 70],
      institution?.printReceiptNumber ? `Reçu ${payment.paymentNumber}` : "",
      institution?.printReceiptQrCode
        ? `https://${window.location.hostname}/paymentReceipt/${payment.paymentId}`
        : null
    ),
    { ...space },
    { text: "Detail paiement", ...title },
    {
      ...table,
      table: {
        headerRows: 1,
        widths: [...getPaymentHeadersWidths()],
        body: [
          [...getPaymentHeaders()],
          ...((payment.studentPayments as StudentPaymentDto[])?.map((sp) => {
            return [...getPaymentContent(sp)];
          }) as any[]),
        ],
      },
      layout: "lightHorizontalLines",
    },
    [
      (payment.studentPayments ?? []).map((m) => {
        return { ...detail, text: `${m.studentFirstName} ${m.studentLastName} - ${getMonth(m)}` };
      }),
    ],
    payment.comment != null && payment.comment.length > 0 ?
      { ...detail, text: 'Commentaire : ' + payment.comment } : {},
    [
      { text: "Total", ...detail, alignment: "right" },
      {
        text: `${numberToMadPdf(payment.totalAmount)}`,
        ...header,
        alignment: "right",
      },
      {
        text: `Payé le ${dateToString(
          payment.date
        )}, en ${paymentTermTypeToString(payment.paymentTerm)} par ${payment.paidBy
          }`,
        ...subHeader,
        alignment: "right",
      },
    ],
    pdfFooter(institution),
  ];

  var docDefinition = {
    info: {
      title: 'awesome Document',
      author: 'john doe',
      subject: 'subject of document',
      keywords: 'keywords for document',
      creationDate: '2021-08-20 10:35:06.7700000 +00:00'
    },
    pageSize: institution?.receiptFormat,
    pageOrientation: institution?.receiptOrientation,
    defaultStyle: {
      fontSize: 9,
    },
    content: payments.map(p => content(p)),
  };

  return (
    <div>
      {isMobile || isTablet ? (
        <DefaultButton
          text="PDF"
          onClick={() => pdfMake.createPdf(docDefinition).open()}
          iconProps={{ iconName: "PDF" }}
          disabled={payments.length === 0}
        />
      ) : (
        <DefaultButton
          split
          text="PDF"
          disabled={payments.length === 0}
          menuProps={{
            items: [
              {
                key: "download",
                text: "Télécharger",
                iconProps: { iconName: "DownloadDocument" },
                onClick: () =>
                  pdfMake
                    .createPdf(docDefinition)
                    .download(`Paiements.pdf`),
              }
            ],
          }}
          onClick={() => pdfMake.createPdf(docDefinition).open()}
          iconProps={{ iconName: "PDF" }}
        />
      )}
    </div>
  );
}
